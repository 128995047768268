import React, { Component, Fragment } from 'react';
import Fetching from '../utilities/fetching.js';
import { Link } from 'react-router-dom';

import moment from 'moment';
import ReportContent from './report-content';
import Loader from './../common/loader';
import Header from './../core/header';
import FlashMsg from './../common/flash-msg';

import { withCookies } from 'react-cookie';

class Report extends Component {

	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			reportContent: false,
			auth: false,
			hideApproval: false,
			error: false
		}
		this.approveReport = this.approveReport.bind(this);
	}

    componentWillMount () {
        // Get the auth cookie data 
        let auth = this.props.cookies.get('ldt-auth');
		this.setState({ auth: auth });
    }

	//On component mount load up the report contents
	componentDidMount() {
		var auth;
		if (typeof this.props.match.params.hideLayout != "undefined" && this.props.match.params.hideLayout !== "print") {
			var qs = this.props.location.search;
			if (typeof qs != "undefined" && qs !== "") {
				// Process the query string to get the relevant elements
				qs = qs.substring(1);
				qs = qs.split('&');
				// Set up a testCookie to assess whether we have what we need
				var testAuth = {};
				for (var i in qs) {
					if (qs[i].indexOf('apikey') > -1) {
						testAuth.apiKey = qs[i].replace('apikey=', "");
					}
					if (qs[i].indexOf('apitoken') > -1) {
						testAuth.apiToken = qs[i].replace('apitoken=', "");
					}
				}
				// Check wehter we have a token and key from the string
				if (typeof testAuth.apiKey != "undefined" && typeof testAuth.apiToken != "undefined") {
					auth = testAuth;
				}
			}
		} else {
			auth = this.props.cookies.get('ldt-auth');
		}

		this.setState({ auth: auth });

		var thus = this;
		fetch(process.env.REACT_APP_API_BASE + '/reports/' + this.props.match.params.reportId, {
			method: 'get',
			headers: { 
				"Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
				"apiToken": auth.apiToken,
				"apiKey": auth.apiKey
			},
		})
		.then(Fetching.statusCheck)
		.then(Fetching.jsonExtract)
		.then(function (data) {
			thus.setState({ loading: false, reportContent: data});
		})
		.catch(function (error) {
			console.log('Request failed', error);
		});
	}

	// Handle report approvals for local users
	approveReport () {

		let { auth } = this.state;
		var thus = this;
		fetch(process.env.REACT_APP_API_BASE + '/reports/' + this.props.match.params.reportId + "/approve", {
			method: 'get',
			headers: {
				"Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
				"apiToken": auth.apiToken,
				"apiKey": auth.apiKey
			},
		})
		.then(Fetching.statusCheck)
		.then(Fetching.jsonExtract)
		.then(function (data) {
			if (data === true) {
				thus.setState({ hideApproval: true, error: { msg: "The report has been approved successfully", 'style': 'green' } });
			} else {
				thus.setState({ hideApproval: false, error: { msg: "There was an error approving this report. Please try again.", 'style': 'red' } });
			}
		})
		.catch(function (error) {
			console.log('Request failed', error);
		});

	}

	sliderNext() {
		this.slider.slickNext();
	}

	sliderPrev() {
		this.slider.slickPrev();
	}

	render() {

		let { reportContent, loading, auth, error, hideApproval } = this.state;
		let hideLayout = (typeof this.props.match.params.hideLayout != "undefined" && this.props.match.params.hideLayout !== "print") ? true : false;
		let isPrint = (typeof this.props.match.params.hideLayout != "undefined" && this.props.match.params.hideLayout === "print") ? true : false;

		return (
			<Fragment>
				{ hideLayout ? "" : (
					<Header isPrint={isPrint} />
				)}

				<section className={ isPrint ? "print" : "content"}>
					<div className='width-9 centred report-content'>
						{!loading ? (
							<Fragment>
								{hideLayout ? "" : (
									<div className='width-9 centred'>
										{!isPrint && typeof auth.role != "undefined" && auth.role === "admin" && !hideApproval && reportContent.approval_date === null ? (
											<button
												className="approve-btn right"
												onClick={this.approveReport}
											>
												Approve
											</button>
										) : ""}
										{!isPrint && (reportContent.report_type.ref === "safe-start" || reportContent.report_type.ref === "weekly-diary") ? (
											<Fragment>
												<a 
													href={process.env.REACT_APP_API_BASE + '/pdf_report/' + reportContent.id + "?api_token=" + auth.apiToken + "&api_key=" + auth.apiKey } 
													target="_blank" 
													className="right btn" 
													rel="noopener noreferrer"
												>Download PDF</a>
											</Fragment>
										) : (
											<Fragment></Fragment>
										)}
										{!isPrint ? (
											<Fragment>
												<Link to={"/admin/report/" + reportContent.id + '/print'} target="_blank" className="right btn" rel="noopener noreferrer">Print</Link>
											</Fragment>
										) : (
											<Fragment></Fragment>
										)}
										
										<h1 className="report-title">{moment(reportContent.report_date).format('DD/MM/YYYY')}: {reportContent.report_type.title}</h1>
										<h2 className="report-title">{reportContent.creator.first_name} {reportContent.creator.last_name}</h2>
										<FlashMsg error={ error } />
									</div>
								)}
								<ReportContent
									report={reportContent}
									isPrint={ isPrint }
								/>
							</Fragment>
						) : (
							<div style={{ marginTop: 72 }}>
								<Loader />
							</div>
						)}
					</div>
				</section>
			</Fragment>
		)
	}


}

export default withCookies(Report);