import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import Loader from '../common/loader.js';
import PaginatorLinks from '../common/paginator/paginator-links';
import Fetching from '../utilities/fetching.js';
import moment from 'moment';

class ReportTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            reportType: false,
            reports: [],
            loading: false,
            pages: false,
            current: 1,
            total: false
        }

        this.switchPage = this.switchPage.bind(this);
    }

    // Load up the reports when the component mounts
    componentDidMount () {
        if (!this.state.loading) {
            if (isNaN(this.props.repTypeId)) {
                this.loadAdhocReports(this.props.projectId, 1);
            } else {
                this.loadReports(this.props.projectId, this.props.repTypeId, 1);
            }
        }
    }

    // Update the component when the props are updated
    componentDidUpdate ( prevProps ) {
        if ( prevProps.projectId !== this.props.projectId || prevProps.repTypeId !== this.props.repTypeId ) {
            if (!this.state.loading) {
                this.setState({ current: 1, loading: true, reports: [] });
                if (isNaN(this.props.repTypeId)) {
                    this.loadAdhocReports(this.props.projectId, 1);
                } else {
                    this.loadReports(this.props.projectId, this.props.repTypeId, 1);
                }
            }
        }
    }

    // Load up the reports that meet the selection criteria
    loadReports ( projectId, repTypeId, pageNo ) {
        let auth = this.props.cookies.get('ldt-auth');
        this.setState({ loading: true });

        var thus = this;
        fetch(process.env.REACT_APP_API_BASE + '/reports?project_id=' + projectId + "&report_type_id=" + repTypeId + "&page=" + pageNo, {
            method: 'get',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                "apiToken": auth.apiToken,
                "apiKey": auth.apiKey
            },
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (reports) {
            if ( typeof reports.rslts != "undefined" ) {
                thus.setState({ reports: reports.rslts, pages: reports.pages, total: reports.total, current: reports.current, loading: false, reportType: repTypeId });
            } else if ( typeof reports.error != "undefined" && reports.error === "no-rslts" ) {
                thus.setState({ reports: [], pages: 1, total: 0, current: 1, loading: false, reportType: repTypeId });
            } else {
                thus.setState({ reports: [], loading: false, pages: false, current: 1, total: false, reportType: repTypeId });
            }
        })
        .catch(function (error) {
            console.log('Request failed', error);
        });
    }

    // Load up the reports that meet the selection criteria
    loadAdhocReports ( projectId, pageNo ) {
        let auth = this.props.cookies.get('ldt-auth');
        this.setState({ loading: true });

        var thus = this;
        fetch(process.env.REACT_APP_API_BASE + '/document_deliveries/attendees-report?project_id=' + projectId + "&page=" + pageNo, {
            method: 'get',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                "apiToken": auth.apiToken,
                "apiKey": auth.apiKey
            },
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (reports) {
            if ( typeof reports.rslts != "undefined" ) {
                thus.setState({ reports: reports.rslts, pages: reports.pages, total: reports.total, current: reports.current, loading: false, reportType: "tt-attendees" });
            } else if ( typeof reports.error != "undefined" && reports.error === "no-rslts" ) {
                thus.setState({ reports: [], pages: 1, total: 0, current: 1, loading: false, reportType: "tt-attendees" });
            } else {
                thus.setState({ reports: [], loading: false, pages: false, current: 1, total: false, reportType: "tt-attendees" });
            }
        })
        .catch(function (error) {
            console.log('Request failed', error);
        });
    }
    
    // Handle the switches triggered by the paginator
    switchPage ( e, newPageNo ) {
        // Update the state to show the current page
        this.setState({ current: newPageNo });
        // Reload the reports
        if (isNaN(this.props.repTypeId)) {
            this.loadAdhocReports(this.props.projectId, newPageNo);
        } else {
            this.loadReports(this.props.projectId, this.props.repTypeId, newPageNo);
        }
    }

  render() {

    let { reports, loading, pages, current, reportType } = this.state;
      console.log(this.props.repTypeId);
      console.log(reports);

    if (!reportType) {
        return (
            <div className="width-6 centred">
                <Loader />
            </div>
        );
    }


    if (isNaN(reportType)) {

        // Separate table style for the adhoc reports
        return (
            <Fragment>
                <div className='width-6'>
                    <table className='reports-table'>
                        <thead>
                            <tr>
                                <th>Document</th>
                                <th>Created By</th>
                                <th>Created Date</th>
                                <th>Attendees</th>
                                <th >&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                <tr>
                                    <td style={{ marginTop: 100 }} colSpan='5'>
                                        <Loader />
                                    </td>
                                </tr>
                            ) : (
                                <Fragment>
                                    {reports.length > 0 ? (
                                        <Fragment>
                                            {reports.map((report, i) =>
                                                <tr key={i}>
                                                    <td>{report.document.title}</td>
                                                    <td>{report.leader.first_name} {report.leader.last_name}</td>
                                                    <td>{moment(report.created_at).format('DD/MM/YYYY')}</td>
                                                    <td>
                                                        {report.attendees.length > 0 ? (
                                                            <ul>
                                                                {report.attendees.map((attendee, i) =>
                                                                    <li key={i}>{attendee.name}</li>
                                                                )}
                                                            </ul>
                                                        ) : (
                                                            <p className="empty-p">No attendees</p>
                                                        )}
                                                    </td>
                                                    <td>
                                                        {report.attendees.length > 0 &&
                                                            <Fragment>
                                                                <Link to={"#"} onClick={(e) => this.props.generatePublicReportLink(e, report.id, true)} className="btn report-btn">Generate Link</Link>
                                                            </Fragment>
                                                        }
                                                    </td>
                                                </tr>
                                            )}
                                        </Fragment>
                                    ) : (
                                        <tr>
                                            <td style={{ marginTop: 100 }} colSpan='5' className="empty-row">
                                                There are no reports matching your selected criteria
                                            </td>
                                        </tr>
                                    )}
                                </Fragment>
                            )}

                        </tbody>
                    </table>

                    {!loading && pages > 1 ? (
                        <PaginatorLinks
                            pages={pages}
                            current={current}
                            switchFunc={this.switchPage}
                        />
                    ) : (
                        <Fragment></Fragment>
                    )}
                </div>


            </Fragment>

        );

    }

    return (
        <Fragment>
            <div className='width-6'>
                <table className='reports-table'>
                    <thead>
                        <tr>
                            <th>Created By</th>
                            <th>Created Date</th>
                            <th>Approved By</th>
                            <th>Approval Date</th>
                            <th >&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        { loading ? (
                            <tr>
                                <td style={{ marginTop: 100 }} colSpan='5'>
                                    <Loader />
                                </td>
                            </tr>
                        ) : (
                            <Fragment>
                                { reports.length > 0 ? (
                                    <Fragment>
                                        { reports.map ( (report, i) => 
                                            <tr key={i}>
                                                <td>{report.creator.first_name} {report.creator.last_name}</td>
                                                <td>{ moment(report.report_date).format('DD/MM/YYYY') }</td>
                                                <td>{ report.approver_id !== null ? (
                                                    <Fragment>{report.approver.first_name} {report.approver.last_name}</Fragment>
                                                ) : (
                                                    <Fragment>-</Fragment>
                                                )} </td>
                                                <td>{ report.approval_date !== null ? moment(report.approval_date).format('DD/MM/YYYY') : "-" }</td>
                                                <td>
                                                    { report.is_empty === 0 ? (
                                                        <Fragment>
                                                            <Link to={"/admin/report/" + report.id} className="btn report-btn">View</Link>
                                                            <Link to={"#"} onClick={(e) => this.props.generatePublicReportLink(e, report.id)} className="btn report-btn">Generate Link</Link>
                                                        </Fragment>
                                                    ) : (
                                                        <p className="empty-p">Nothing to report</p>
                                                    )}
                                                    
                                                </td>
                                            </tr>
                                        )}
                                    </Fragment>
                                ) : (
                                    <tr>
                                        <td style={{ marginTop: 100 }} colSpan='5' className="empty-row">
                                            There are no reports matching your selected criteria
                                        </td>
                                    </tr>
                                )}
                            </Fragment>
                        )}
                        
                    </tbody>
                </table>

                { !loading && pages > 1 ? (
                    <PaginatorLinks
                        pages={ pages }
                        current={ current }
                        switchFunc={ this.switchPage }
                    />
                ) : (
                    <Fragment></Fragment>
                )}
            </div>
            
                    
        </Fragment>
    
    );
  }
}

export default withCookies(ReportTable);