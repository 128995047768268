import React, { Component, Fragment } from 'react';

import Header from '../core/header.js'
import Footer from '../core/footer.js'




class Error404Component extends Component {



  render() {


    return (
        <Fragment>
            <Header/>

            <div className='text-center'>
               <div className='error-box'>
                  <h1>Oops, there&rsquo;s been an error!</h1>
                  <p>The page you&rsquo;re looking for couldn&rsquo;t be found. We&rsquo;d suggest you go back to the previous page and try again.</p>
                </div> 
            </div>
            <Footer/>
        </Fragment>
    )
  }
}

export default Error404Component;