

import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Loader from '../common/loader';
import PaginatorLinks from '../common/paginator/paginator-links';

class ToolboxTable extends Component {

  constructor(props) {
    super(props);

    this.state = {

    }

  }

  


  render() {

    let {loading,pages,current,total} = this.props;

    let toolboxData = (<tr className="holding">
                            <td colSpan="6">
                                <Loader />
                            </td>
                        </tr>);

    if(!loading){
        toolboxData = this.props.toolboxTalks.map((toolboxTalk,i) => {

    
            return (
                <tr key={i}>
                    <td>{toolboxTalk.title}</td>
                    <td className='rx-800-hidden'>{toolboxTalk.description}</td>
                    <td className='rx-1100-hidden'>{moment(toolboxTalk.updated_at).format('DD/MM/YYYY')}</td>
                    <td className='rx-800-hidden'>{toolboxTalk.published === 1 ? 'Published' : 'Unpublished'}</td>
                    <td className='project-edit-td align-right'>
                        <Link to={'/admin/toolbox-talks/attendees/' + toolboxTalk.id } className='btn'>Attendees</Link>
                        <Link to='#' className='btn ' onClick={(e) => this.props.toggleEditToolboxLightBoxOpen(e,toolboxTalk.id)}>Edit</Link>
                        <Link to='#' className='btn project-edit-publish-toggle ' onClick={(e) => this.props.togglePublishStatus(e,toolboxTalk.id,toolboxTalk.published)}>{toolboxTalk.published === 1 ? 'Unpublish' : 'Publish'}</Link>
                        <Link to='#' className='btn btn-dark' onClick={(e) => this.props.deleteToolbox(e,toolboxTalk.id)}>Delete</Link>
                    </td>
                </tr>
            );
        } );
    }

   

    return (
        <Fragment>
            <div className='width-9 centred'>
                <table className='toolboxTalks-table'>
                    <thead>
                        <tr>
                            <th>Title</th>
                            <th className='rx-800-hidden'>Description</th>
                            <th className='rx-1100-hidden'>Last modified</th>
                            <th className='rx-800-hidden'>Status</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {toolboxData}
                    </tbody>
                </table>

                { !loading && pages > 1 ? (
                <PaginatorLinks 
                    actionFunc={ this.props.loadToolboxes }
                    total={ total }
                    current={ current }
                    pages={pages }
                    baseUrl={ "/admin/toolbox-talks" }
                    delay={ 10 }
                />
                ) :"" }
            </div>
            
                    
        </Fragment>
    
    );
  }
}

export default ToolboxTable;