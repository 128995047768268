import React, { Component, Fragment } from 'react';

import Fetching from './../../utilities/fetching';
import Password from '../../common/fields/password.js'
import SubmitButton from '../../common/fields/submit-button.js'

class  ChangePasswordForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: false,
            password: "",
            confirm: "",
            current: "",
            saving: "Update password",
            msg: false
       }

        this.updateField = this.updateField.bind(this);
        this.updatePw = this.updatePw.bind(this);
    }

    updateField(fieldName, value) {
        this.setState({ [fieldName]: value });
    }

    updatePw(e) {

        e.preventDefault();
        this.setState({ saving: "Updating password..." });

        // Set up the save data 
        let { password, confirm, current } = this.state;

        var thus = this;
        fetch(process.env.REACT_APP_API_BASE + '/users/pw_update', {
            method: 'put',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                "apiToken": this.props.auth.apiToken,
                "apiKey": this.props.auth.apiKey
            },
            body: 'password=' + encodeURIComponent(password) + '&confirm=' + encodeURIComponent(confirm) + '&current=' + encodeURIComponent(current)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (rslt) {
            if (rslt === true) {
                thus.setState({ saving: "Update password", msg: { content: "Your password has been successfully updated.", className: "message success" }});
            } else {
                thus.setState({ saving: "Update password", errors: rslt, msg: { content: "Sorry but there was an issue changing your password. Please check the errors below and try again.", className: "message" } });
            }
        })
        .catch(function (error) {
            console.log('Request failed', error);
        });

    }

    render() {

        let { errors, saving, msg } = this.state;

        return (
            <Fragment>
                <div className='form-header'>
                    <h4>Change My Password</h4>                    
                </div>

                {msg ? (
                    <div className={msg.className}>
                        {msg.content}
                    </div>
                ) : ""}

                <form>
                    <div className='form-section'>
                        <Password label='New Password' id='password' errors={ errors } onChange={ this.updateField } />
                    </div>
                    <div className='form-section'>
                        <Password label='Re-enter Password' id='confirm' errors={errors} onChange={this.updateField} />
                    </div>

                    <div className='form-section confirm'>
                        <Password label='Enter Current Password' id='current' errors={errors} onChange={this.updateField} />   
                    </div>
                    <div className='form-section'>
                        <SubmitButton text={saving} handleSubmit={this.updatePw} />
                    </div>
                </form>
                        
            </Fragment>
        
        );
    }
}


export default ChangePasswordForm;
