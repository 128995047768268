import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Password from '../common/fields/password.js'
import SubmitButton from '../common/fields/submit-button.js'
import Footer from '../core/footer.js'
import LoginHeader from './login-header.js'

import Fetching from './../utilities/fetching';
import RedirectWithFlashMessage from '../common/flash-messages/redirect-with-flash-message.js'

import './../../assets/css/login.css';


class ResetPassword extends Component {

    constructor(props){
        super(props);
        this.state = {
            password:'',
            confirm:'',
            doRedirect:false,
            redirectUrl:'',
            redirectState :false,
            redirectMessage:'',
            verifiedEndpoint:false,
            btnTxt: "Reset Password"
        };

        this.handleDataEntry = this.handleDataEntry.bind(this);
        this.handlePasswordReset =this.handlePasswordReset.bind(this);
    }

    componentDidMount(){
        let thus = this;
        //verfiy password reset link is ok
        fetch(process.env.REACT_APP_API_BASE+'/pw/set/'+this.props.match.params.token+'/'+this.props.match.params.date_key, {
            method: 'get',
            headers: { "Content-type": "application/x-www-form-urlencoded; charset=UTF-8" },
          })
          .then(Fetching.statusCheck)
          .then(Fetching.jsonExtract)
          .then(function (data) {
                if(data.rslt === 'fail'){

                    let redirectMessage = data.error;

                    if(data.error === 'Invalid link'){
                        redirectMessage = 'That link has expired.  Please request a new password using this form';
                    }
                    thus.setState({doRedirect:true,redirectUrl:'/forgotten-password',redirectState:'error',redirectMessage:redirectMessage});
                } else {
                    //link verified
                    thus.setState({verifiedEndpoint:true});
                }
          })
          .catch(function (error) {
            console.log('Request failed', error);
            thus.setState({doRedirect:true,redirectUrl:'/forgotten-password',redirectState:'error',redirectMessage:'Problem with the server'});
          });
        
    }

    handlePasswordReset(e){
        e.preventDefault();

        this.setState({ btnTxt: "Resetting password..." });

		let { password,confirm } = this.state;
        let thus = this;


		// Encode the email and password for submission
        password = encodeURIComponent(password);
        confirm =encodeURIComponent(confirm);

        //do password reset
        fetch(process.env.REACT_APP_API_BASE+'/pw/set/'+this.props.match.params.token+'/'+this.props.match.params.date_key, {
            method: 'post',
            headers: { "Content-type": "application/x-www-form-urlencoded; charset=UTF-8" },
            body: 'password='+ password +'&confirm='+confirm
            })
            .then(Fetching.statusCheck)
            .then(Fetching.jsonExtract)
            .then(function (data) {
                if(data === true){
                    thus.setState({doRedirect:true,redirectUrl:'/',redirectState:'success',redirectMessage:'You have successfully changed your password.  Please login with the new password.'});
                }
                thus.setState({ btnTxt: "Reset password" });
            })
            .catch(function (error) {
                console.log('Request failed', error);
                thus.setState({ btnTxt: "Reset password" });
            });

    }

    // Store entered data to state, ready for login
	handleDataEntry ( fieldName, value ) {
		this.setState({ [ fieldName ]: value });
	}

  render() {

    let { btnTxt } = this.state;

    if(this.state.doRedirect){
        return  <RedirectWithFlashMessage to={this.state.redirectUrl} redirectState ={this.state.redirectState} message={this.state.redirectMessage}  />
    }

    if(!this.state.verifiedEndpoint){
        return <div></div>;
    }

    return (
        <Fragment>
            <div className='content'>
                <div className='width-4 centred white-bg login-form'>
                    <LoginHeader/>

                    {typeof this.props.match.params.create != "undefined" && this.props.match.params.create === "create" ? (
                        <p>Thanks for creating an account. Please enter a password below to complete the account creation process.</p>
                    ) : (
                        <p>To reset your password, please enter your new password below.</p>
                    )}

                    <form>
                        <Password id='password' label='Password' value={this.state.password} onChange={this.handleDataEntry } />
                        <Password id='confirm' label='Confirm Password' value={this.state.confirm_password} onChange={this.handleDataEntry }/>
                        <Link to="/" className="right lnk sml">Return to login</Link>
                        <SubmitButton handleSubmit={(e) => this.handlePasswordReset(e)} text={ btnTxt } />
                    </form>

                </div>
            </div>

            <Footer/>
        </Fragment>
      
    );
  }
}

// var styles = {
  
// };


export default ResetPassword;
