import React, { Component, Fragment } from 'react';
import moment from 'moment';

import Loader from './loader';

class ItemOfConcernLightBox extends Component {

    constructor(props) {
        super(props);
        this.state = {
            details: false,
            loading: false
        }

    }


  render() {

    let { loading } = this.state;

    let title = 'Items of Concern';

    if(this.props.issue.issue_type === 'commercial'){
        title = 'Commercial Actions';
    }


    return (
        <Fragment>
            <div className='lightbox item-of-concern-lightbox'>
                <div className='lightbox-content'>
                <div className='close' onClick={(e) => this.props.closeFunc(e) }>&times;</div>
                    <div className='lightbox-header'>
                        <h4>{title}</h4>
                    </div>

                    { loading ? (
                        <Loader bg="white" mrgn="mrgn-lrg" />
                    ) : (
                        <Fragment>
                            <div className='lightbox-section lightbox-section-third no-bottom'>
                                <p className='lightbox-header'>Report Date</p>
                                <p className='lightbox-info'>{ moment(this.props.issue.created_at).format('DD/MM/YYYY') }</p>
                            </div>
                            <div className='lightbox-section lightbox-section-third no-bottom'>
                                <p className='lightbox-header'>Reported By</p>
                                <p className='lightbox-info'>{ this.props.issue.creator.first_name + ' ' + this.props.issue.creator.last_name }</p>
                            </div>
                            {this.props.issue.issue_type !== 'commercial' ? (
                                <div className=' lightbox-section lightbox-section-third no-bottom'>
                                    <p className='lightbox-header'>Action assigned to</p>
                                    { this.props.issue.assignee_type === 'Users' ? (
                                        <p className='lightbox-info'>{ (this.props.issue.assignee_user !== null) ? this.props.issue.assignee_user.first_name + ' ' + this.props.issue.assignee_user.last_name : "" }</p>
                                    ) : (
                                        <p className='lightbox-info'>{ (this.props.issue.assignee_worker !== null) ? this.props.issue.assignee_worker.name : "" }</p>
                                    )}
                                </div>
                            ):null}
                            <div className='lightbox-section'>
                                <p className='lightbox-header'>Issue raised</p>
                                <p className='lightbox-info'>{ this.props.issue.issue_content }</p>
                            </div>

                        </Fragment>
                    )}
                </div>
            </div>
        </Fragment>
      
    );
  }
}


export default ItemOfConcernLightBox;

