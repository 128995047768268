

import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Loader from '../common/loader';


class ManufacturerTable extends Component {


  render() {

    let {loading} = this.props;

    let manufacturerData = (<tr className="holding">
                            <td colSpan="6">
                                <Loader />
                            </td>
                        </tr>);

    if(!loading){
        manufacturerData = this.props.manufacturers.map((manufacturer,i) => {

    
            return (
                <tr key={i}>
                    <td>{manufacturer.name}</td>
                    <td className='project-edit-td align-right'>
                       <Link to='#' className='btn ' onClick={(e) => this.props.toggleCreateNewManufacturerLightBoxOpen(e,manufacturer.id)}>Edit</Link>
                        <Link to='#' className='btn btn-dark' onClick={(e) => this.props.deleteManufacturer(e,manufacturer.id)}>Delete</Link>
                    </td>
                </tr>
            );
        } );
    }

   

    return (
        <Fragment>
            <div className='width-9 centred'>
                <table className='datasheets-table'>
                    <thead>
                        <tr>
                            <th>Manufacturer</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {manufacturerData}
                    </tbody>
                </table>

                {/* { !loading && pages > 1 ? (
                <PaginatorLinks 
                    actionFunc={ this.props.loadManufacturers }
                    total={ total }
                    current={ current }
                    pages={pages }
                    baseUrl={ "/admin/manufacturers" }
                    delay={ 10 }
                />
                ) :"" } */}
            </div>
            
                    
        </Fragment>
    
    );
  }
}

export default ManufacturerTable;