import React, { Component, Fragment } from 'react';

import Row from './../groups/row';
import Standard from './../groups/standard';
import Repeater from './../groups/repeater';

// import Txt from './../../../common/txt';
// import Colours from '../../../../assets/styles/colours';

class Plain extends Component {    

    render() {
        return (
			<div>
				<h4>{ this.props.sxn.title }</h4>
				<hr className='sxn-divider' />

				{typeof this.props.sxn.report_question_groups != "undefined" ? (
					<Fragment>
						{this.props.sxn.report_question_groups.map((grp, i) =>
							<Fragment key={"grp-" + i}>
								<div className={ 'report-question-group ' + grp.style }>
									{
										{
											standard: <Standard grp={ grp } { ...this.props } />,
											repeater: <Repeater grp={ grp } { ...this.props } />,
											row: <Row grp={ grp } itemNo={ i } { ...this.props } />,
										}[grp.group_type]
									}
								</div>
							</Fragment>
						)}
					</Fragment>
				) : (
					<Fragment></Fragment>
				)}
			</div>
        );

    }

}

export default Plain;

