import React, { Component } from 'react';
import moment from 'moment';
class ItemsOfConcernItem extends Component {

  render() {

    return (

        <div className='panel-list-item'>
          <div className='item-of-concern-btn-container'>
            <a className='btn btn-dark' onClick={(e) => this.props.toggleIssueDetails(e, this.props.issue)} href="#detail">Details</a>
            <a className='btn' onClick={ (e) => this.props.dismissIssue(e, this.props.issue.id) } href="#dismiss">Dismiss</a>
          </div>
            <h3>{this.props.issue.report.project.title}</h3>
            <p>{(this.props.issue.issue_content !== null) ? this.props.issue.issue_content.substring(0, 75) + '...' : ""}</p>
            <p className="detail">{moment(this.props.issue.created_at).format('DD/MM/YYYY')}</p>
        </div>


    );
  }
}

export default ItemsOfConcernItem;