import React, { Component, Fragment } from 'react';
import { withCookies } from 'react-cookie';

import Fetching from './../utilities/fetching';

import Text from './fields/text.js';
import Email from './fields/email.js';
import Select from './fields/select.js';
// import Address from './fields/address.js';
import SubmitButton from './fields/submit-button.js';
import Loader from './../common/loader';

class LightboxUpdateUser extends Component {


    constructor(props) {
        super(props);
        this.state = {
            email_address: '',
            first_name: '',
            last_name: '',
            role: '',
            saving: "Save user",
            msg: false,
            errors: false,
            loading: false
        }

        this.updateUsr = this.updateUsr.bind(this);
        this.updateField = this.updateField.bind(this);
    }

    componentDidMount () {
        this.loadDetail( this.props.uid );
    }

    // Get the user details 
    loadDetail(uid) {

        // Get the user details
        var thus = this;
        fetch(process.env.REACT_APP_API_BASE + '/users/' + uid, {
            method: 'get',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                "apiToken": this.props.auth.apiToken,
                "apiKey": this.props.auth.apiKey
            },
        })
            .then(Fetching.statusCheck)
            .then(Fetching.jsonExtract)
            .then(function (data) {
                thus.setState({ 
                    loading: false,
                    email_address: data.email_address,
                    first_name: data.first_name,
                    last_name: data.last_name,
                    role: data.role
                });
            })
            .catch(function (error) {
                console.log('Request failed', error);
            });

    }

    updateField(fieldName, value) {
        this.setState({ [fieldName]: value });
    }

    updateUsr(e) {

        e.preventDefault();
        this.setState({ saving: "Saving user..." });

        // Set up the save data 
        let { email_address, first_name, last_name, role } = this.state;

        let auth = this.props.cookies.get('ldt-auth');

        var thus = this;
        fetch(process.env.REACT_APP_API_BASE + '/users/' + this.props.uid, {
            method: 'put',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                "apiToken": auth.apiToken,
                "apiKey": auth.apiKey
            },
            body: 'email_address=' + encodeURIComponent(email_address) + '&first_name=' + first_name + '&last_name=' + last_name  + '&role=' + role
        })
            .then(Fetching.statusCheck)
            .then(Fetching.jsonExtract)
            .then(function (rslt) {
                if (typeof rslt.rslt != 'undefined' && rslt.rslt === "success") {
                    thus.props.closeFunc(null, { content: "The user account has been successfuly updated.", className: "message success" },undefined,true);
                } else {
                    thus.setState({ saving: "Update user", errors: rslt, msg: { content: "Sorry but there was an issue updating this user. Please check the errors below and try again.", className: "message" } });
                }
            })
            .catch(function (error) {
                console.log('Request failed', error);
            });

    }

    render() {

        let { saving, errors, msg, loading, email_address, first_name, last_name,  role,} = this.state;

         const options = [
            { label: 'Admin', value: 'admin' },
            { label: 'Manager', value: 'manager' },
            {label:'Foreman',value : 'foreman'}
        ];




        return (
            <Fragment>
                <div className='lightbox lightbox-create-user'>
                    <div className='lightbox-content'>
                        <div className='close' onClick={(e) => this.props.closeFunc(e)}>&times;</div>
                        <div className='lightbox-header'>
                            <h4>Update User</h4>
                        </div>

                        {msg ? (
                            <div className={msg.className}>
                                {msg.content}
                            </div>
                        ) : ""}

                        {loading ? (
                            <Loader bg="white" mrgn="mrgn-lrg" />
                        ) : (
                            <Fragment>
                                <div className='lightbox-section clear'>
                                    <Text label='First name' id='first_name' value={ first_name } onChange={this.updateField} errors={errors} />
                                </div>
                                <div className='lightbox-section'>
                                    <Text label='Last name' id='last_name' value={ last_name } onChange={this.updateField} errors={errors} />
                                </div>
                                <div className='lightbox-section clear'>
                                    <Email label='Email' id='email_address' value={ email_address } onChange={this.updateField} errors={errors} />
                                </div>
                                <h4 className='subtitle'>Access Permissions</h4>
                                <div className='lightbox-section'>
                                    <Select label='User role' id='role' value={ role } emptyMsg="Select a role..." options={options} handleSelect={this.updateField} errors={errors} />
                                </div>
                                <div className='lightbox-submit'>
                                    <SubmitButton text={saving} handleSubmit={this.updateUsr} />
                                </div>
                            </Fragment>
                        )}
                    </div>
                </div>
            </Fragment>

        );
    }
}


export default withCookies(LightboxUpdateUser);

