import React, { Component, Fragment } from 'react';
import { withCookies } from 'react-cookie';

import Header from '../core/header.js'
import Footer from '../core/footer.js'

import MainTitle from '../common/titles/main-title.js';

import UpdateDetailsForm from './page-components/update-details-form.js';
import ChangePasswordForm from './page-components/change-password-form.js'

class Settings extends Component {
    
  render() {

    let auth = this.props.cookies.get('ldt-auth');

    return (
        <Fragment>

            <Header/>
            
            
            <section className="content form-page">
                <div className="width-9 centred">
                    <MainTitle text='Manage your account'/>
                
                    <div className="spaced-half">
                        <UpdateDetailsForm 
                            auth={ auth }
                        />
                    </div>

                    <div className="spaced-half">
                        <ChangePasswordForm
                            auth={ auth }
                        />
                    </div>
                </div>

            </section>
           
            <Footer/>
        </Fragment>
    );
  }
}

export default withCookies(Settings);
