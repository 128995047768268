import React, { Component, Fragment } from 'react';
import ReportContent from './report-content';
import { Link } from 'react-router-dom';

import Loader from '../common/loader';
import Footer from '../core/footer';
import HeaderExternal from '../core/header-external';
import Fetching from '../utilities/fetching';

import moment from 'moment';

class ReportExternalLink extends Component {
  
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            error: false,
            content: false
        }
        this.getContent = this.getContent.bind(this);
    }

    componentDidMount(){
      this.getContent();
    }

    getContent(){

      let url_slug = this.props.match.params.url_segment;
      let url_token = this.props.match.params.url_token;

      var thus = this;
      fetch(process.env.REACT_APP_API_BASE + '/reports/check_valid_link', {
          method: 'post',
          headers: { 
              "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          body:"url_slug="+url_slug+"&url_token="+url_token
      })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(function (data) {
        if ( typeof data.error != "undefined" ) {
          thus.setState({ error: "Sorry but the report you are looking for could not be found. Please check the link and try again.", content: false, loading: false });
        } else {
          thus.setState({ error: false, content: data, loading: false });
        }
      })
      .catch(function (error) {
          console.log('Request failed', error);
      });
    }

    render() {

      let { loading, content, error } = this.state;
      let url_slug = this.props.match.params.url_segment;
      let url_token = this.props.match.params.url_token;
      let isPrint = (typeof this.props.isPrint != "undefined" && this.props.isPrint === true) ? true : false;
    
      // Handle document delivery reports
      if (typeof content.type != "undefined" && content.type === "document_delivery") {

        return (
          <Fragment>
            <HeaderExternal isPrint={false} />
            <section className={"content"}>
              {loading ? (
                <div className='width-6 centred' style={{ marginTop: 72 }}>
                  <Loader />
                </div>
              ) : (
                <Fragment>
                  <div className='width-6 centred'>
                      <h1 className="report-title">{moment(content.report_date).format('DD/MM/YYYY')}: Toolbox talk</h1>
                      <h2 className="report-title half">Training document: {content.document.title}</h2>
                      <h2 className="report-title half">Leader: {content.leader.first_name} {content.leader.last_name}</h2>

                      <ul className="proper">
                        { content.attendees.map((attendee, index) =>
                          <li key={index}>{attendee.name}</li>
                        )}
                      </ul>
                  </div>
                </Fragment>
              )}

            </section>
            <Footer />
          </Fragment>
        )

      }

      return (
        <Fragment>
          <HeaderExternal isPrint={ isPrint } />
          <section className={ isPrint ? "print" : "content"}>
            { loading ? (
              <div className='width-9 centred' style={{ marginTop: 72 }}>
                <Loader />
              </div>
            ) : (
              <Fragment>
                { error ? (
                    <div className='text-center'>
                      <div className='error-box'>
                        <h1>Report not found</h1>
                        <p>{ error }</p>
                      </div>
                    </div>
                ) : (
                  <Fragment>
                    <div className='width-9 centred'>
                      { !isPrint && (content.report_type.ref === "safe-start" || content.report_type.ref === "weekly-diary") ? (
                        <a 
                          href={ process.env.REACT_APP_API_BASE + '/reports/public_pdf?s=' + url_slug + "&t=" + url_token } 
                          target="_blank" 
                          className="right btn" 
                          rel="noopener noreferrer"
                        >Download PDF</a>
                      ) : (
                        <Fragment></Fragment>
                      )}
                      {!isPrint ? (
                        <Fragment>
                          <Link to={"/print_report/" + url_slug + '/' + url_token } target="_blank" className="right btn" rel="noopener noreferrer">Print</Link>
                        </Fragment>
                      ) : ""}
                      <h1 className="report-title">{ moment(content.report_date).format('DD/MM/YYYY') }: { content.report_type.title }</h1>
                      <h2 className="report-title">{ content.creator.first_name } { content.creator.last_name }</h2>
                    </div>
                    <div className='width-9 centred report-content'>
                      <ReportContent 
                        report={ content }
                        isPrint={ isPrint }
                      />
                    </div>
                  </Fragment>
                )}
              </Fragment>
            )}
            
          </section>
          { isPrint ? "" : (
            <Footer />
          )}
        </Fragment>
      )
    }


}

export default ReportExternalLink;