

import React, { Component, Fragment } from 'react';
import moment from 'moment';
import Loader from '../common/loader';
import Header from '../core/header';
import MainTitle from '../common/titles/main-title';
import Footer from '../core/footer';
import DateInput from '../common/fields/date-input';
import SubmitButton from '../common/fields/submit-button';
import Fetching from '../utilities/fetching';
import { withCookies } from 'react-cookie';




class Attendees extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            attendees: [],
            startDate: '',
            endDate: '',
            loading: true,
            docName: false
        }

        this.updateDate = this.updateDate.bind(this);
        this.updateList = this.updateList.bind(this);
        this.downloadTooltalkPdf = this.downloadTooltalkPdf.bind(this);
    }

    // On load, set the default dates for the filter (this month) + trigger the filter
    componentDidMount() {

        this.getDetail();

        let startDate = new Date(moment().startOf('month').format('YYYY-MM-DD'));
        let endDate = new Date(moment().endOf('month').format('YYYY-MM-DD'));
        this.setState({ startDate: startDate, endDate: endDate }, () => {
            this.updateList();
        });

    }

    // Update the filter start 
    updateDate(id, value) {
        if (id === 'startDate') {
            this.setState({ startDate: value });
        } else {
            this.setState({ endDate: value });
        }
    }

    // Get the toolbox talk detaiuls
    getDetail() {

        let usrAuth = this.props.cookies.get('ldt-auth');

        // Get the users
        var thus = this;
        fetch(process.env.REACT_APP_API_BASE + '/documents/' + this.props.match.params.ttId, {
            method: 'get',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                "apiToken": usrAuth.apiToken,
                "apiKey": usrAuth.apiKey,
            }
        })
            .then(Fetching.statusCheck)
            .then(Fetching.jsonExtract)
            .then(function (data) {
                thus.setState({ docName: data.title });
            })
            .catch(function (error) {
                console.log('Request failed', error);
                thus.setState({ loading: false, attendees: [] })
            });

    }

    // Get the toolbox talk attendeed list
    updateList() {
        
        // If it is this type of query display the loader 
        if (typeof displayLoader != 'undefined') {
            this.setState({ loading: true });
        }

        let usrAuth = this.props.cookies.get('ldt-auth');

        // Handle the filter dates
        let { startDate, endDate } = this.state;
        var urlString = "?tt=" + this.props.match.params.ttId;
        if (startDate !== "") {
            startDate = moment(startDate).format('YYYY-MM-DD');
            urlString += "&start=" + startDate;
        }
        if (endDate !== "") {
            endDate = moment(endDate).format('YYYY-MM-DD');
            urlString += "&end=" + endDate;
        }
 
        // Get the users
        var thus = this;
        fetch(process.env.REACT_APP_API_BASE + '/document_deliveries/attendees' + urlString, {
            method: 'get',
            headers: { 
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                "apiToken": usrAuth.apiToken,
                "apiKey": usrAuth.apiKey,
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            thus.setState({attendees: data, loading: false});
        })
        .catch(function (error) {
            console.log('Request failed', error);
            thus.setState({ loading: false, attendees: [] })
        });

    }

    downloadTooltalkPdf(id,date){

        let usrAuth = this.props.cookies.get('ldt-auth');

        let url =  process.env.REACT_APP_API_BASE + '/documents/tooltalk/download/'+id+'/'+moment(date).format('YYYY-MM-DD')+'?apiToken='+usrAuth.apiToken+'&apiKey='+usrAuth.apiKey;

        window.location.href=  url;
    
    }

    render() {

        let {loading,attendees,endDate,startDate, docName} = this.state;

        return (
            <Fragment>
                <Header />
                <section className="content">
                    <div className="width-6 centred">

                        <MainTitle text={ docName ? '"' + docName + '" Attendees' : 'Toolbox Talk Attendees'} />

                        <div className="filter">
                            <DateInput label="Start date" onChange={ this.updateDate } id="startDate" value={ startDate } />
                            <DateInput label="End date" onChange={this.updateDate} id="endDate" value={endDate} />
                            <SubmitButton text="Filter" handleSubmit={this.updateList} />
                        </div>

                        <table className='attendees-table'>
                            <thead>
                                <tr>
                                    <th>Talk date</th>
                                    <th>Leader</th>
                                    <th className='rx-800-hidden'>Attendees</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                { !loading ? (
                                    <Fragment>
                                        {  attendees.length > 0 ? (
                                            <Fragment>
                                                { attendees.map((talk, i) => 
                                                    <tr key={"att-" + i}>
                                                        <td>{ moment(talk.created_at).format('DD/MM/YYYY') }</td>
                                                        <td>{talk.leader.first_name} { talk.leader.last_name }</td>
                                                        <td>{ 
                                                            talk.attendees.length > 0 ? (
                                                                <ul>
                                                                    { talk.attendees.map((attendee, i) =>
                                                                        <Fragment key={"attendee-" + i}>
                                                                            { attendee.name !== "" &&
                                                                                <li>{attendee.name}</li>
                                                                            }
                                                                        </Fragment>
                                                                    )}
                                                                </ul>
                                                            ) : (
                                                                <span className="empty-span">No attendees</span>
                                                            )
                                                        }</td>
                                                        <td style={{textAlign:'right'}}><button onClick={() => this.downloadTooltalkPdf(talk.id,talk.created_at)}>Download</button></td>
                                                    </tr>
                                                )}
                                            </Fragment>
                                        ) : (
                                            <tr>
                                                <td colSpan="4" className="empty-row">There were no talks to provide data on in this period.</td>
                                            </tr>
                                        )}
                                    </Fragment>
                                ) : (
                                    <tr>
                                        <td colSpan="4"><Loader /></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        
                    </div>

                </section>
                <Footer />
            </Fragment>    
        );
    }
}

export default withCookies(Attendees);