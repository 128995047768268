

import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import moment from'moment';
import Loader from './../common/loader';
import PaginatorLinks from '../common/paginator/paginator-links';

class DatasheetTable extends Component {

  constructor(props) {
    super(props);

    this.state = {

    }

  }

  


  render() {

    let {loading,pages,current,total} = this.props;

    let datasheetData = (<tr className="holding">
                            <td colSpan="6">
                                <Loader />
                            </td>
                        </tr>);

    if(!loading){
        datasheetData = this.props.datasheets.map((datasheet,i) => {

            let manufacturer = 'unknown';
    
            for(let j = 0; j< this.props.manufacturers.length;j++){
    
                if(parseInt(this.props.manufacturers[j].value) === parseInt(datasheet.manufacturer_id)){
                    manufacturer = this.props.manufacturers[j].label;
                }
            }
    
            return (
                <tr key={i}>
                    <td>{datasheet.title}</td>
                    <td className='rx-800-hidden'>{ datasheet.document_type !== null ? datasheet.document_type.charAt(0).toUpperCase() + datasheet.document_type.slice(1) : ''}</td>
                    <td className='rx-1100-hidden'>{manufacturer}</td>
                    <td className='rx-1100-hidden'>{datasheet.description}</td>
                    <td className='rx-1100-hidden'>{moment(datasheet.updated_at).format('DD/MM/YYYY') }</td>
                    <td className='rx-800-hidden'>{datasheet.published === 1 ? 'Published' : 'Unpublished'}</td>
                    <td className='project-edit-td align-right'>
                        <Link to='#' className='btn ' onClick={(e) => this.props.toggleEditDatasheetLightBoxOpen(e,datasheet.id)}>Edit</Link>
                        <Link to='#' className='btn project-edit-publish-toggle ' onClick={(e) => this.props.togglePublishStatus(e,datasheet.id,datasheet.published)}>{datasheet.published === 1 ? 'Unpublish' : 'Publish'}</Link>
                        <Link to='#' className='btn btn-dark' onClick={(e) => this.props.deleteDatasheet(e,datasheet.id)}>Delete</Link>
                    </td>
                </tr>
            );
        } );
    }

   

    return (
        <Fragment>
            <div className='width-9 centred'>
                <table className='datasheets-table'>
                    <thead>
                        <tr>
                            <th>Title</th>
                            <th className='rx-800-hidden'>Category</th>
                            <th className='rx-1100-hidden'>Manufacturer</th>
                            <th className='rx-1100-hidden'>Description</th>
                            <th className='rx-1100-hidden'>Last modified</th>
                            <th className='rx-800-hidden'>Status</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {datasheetData}
                    </tbody>
                </table>

                { !loading && pages > 1 ? (
                <PaginatorLinks 
                    actionFunc={ this.props.loadDatasheets }
                    total={ total }
                    current={ current }
                    pages={pages }
                    baseUrl={ "/admin/datasheets" }
                    delay={ 10 }
                />
                ) :"" }
            </div>
            
                    
        </Fragment>
    
    );
  }
}

export default DatasheetTable;