import React, { Component, Fragment } from 'react';
import moment from 'moment'
import Loader from './loader';
import Fetching from '../utilities/fetching';
import { withCookies } from 'react-cookie';

class ForemanLightBox extends Component {

    constructor(props) {
        super(props);
        this.state = {
            details: false,
            loading: false,
            overdueReports:[]
        }
        this.clearOverdueReports = this.clearOverdueReports.bind(this)

    }

    componentDidMount(){
        
        const overdueReports = this.props.foremanLightboxData;
        this.setState({overdueReports:overdueReports})
    }

    clearOverdueReports(e){
        e.preventDefault();

        if(window.confirm('Are you sure you want to clear all overdue reports for this foreman?')){
            // Get the user details
            var thus = this;
            let auth = thus.props.cookies.get('ldt-auth');

            fetch(process.env.REACT_APP_API_BASE + '/reports/clear_outstanding_foreman_reports/' + this.props.foreman.public_id, {
                method: 'get',
                headers: {
                    "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                    "apiToken": auth.apiToken,
                    "apiKey": auth.apiKey
                },
            })
            .then(Fetching.statusCheck)
            .then(Fetching.jsonExtract)
            .then(function (data) {
                if(data === 1){
                    thus.setState({overdueReports:[]});
                    thus.props.loadForemanReports();
                    this.props.closeFunc();
                }
            })
            .catch(function (error) {
                console.log('Request failed', error);
            });

        }
    }

  render() {

    let {loading,overdueReports } = this.state;

    let reportData = overdueReports.map((reportGroup) => {
        var report = reportGroup.map((report, j) => <div className='lightbox-section lightbox-border-bottom' key={ 'rp-' + j }>
                        <p className='lightbox-info' style={{float:'right'}}>{report.type === 'daily' ? 'Daily Report Needed' : report.project }</p>
                        <p className='lightbox-info'>{report.report_type}</p>
                        <p className='lightbox-date'>Date: {moment(new Date(report.date)).format("DD/MM/YYYY")}</p>
                    </div>); 
        return report;
    })

    return (
        <Fragment>
            <div className='lightbox'>
                <div className='lightbox-content'>
                    <a href='#open' className='clear-btn btn' onClick={(e) => this.clearOverdueReports(e) }>Clear</a>
                    <div className='close' onClick={(e) => this.props.closeFunc(e) }>&times;</div>
                    <div className='lightbox-header'>
                        <h4>Outstanding Reports</h4>
                    </div>

                    { loading ? (
                        <Loader bg="white" mrgn="mrgn-lrg" />
                    ) : (
                        <Fragment>
                            {reportData}
                        </Fragment>
                    )}
                </div>
            </div>
        </Fragment>
      
    );
  }
}


export default withCookies(ForemanLightBox);

