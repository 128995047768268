import React, { Component, Fragment } from 'react';

class YesNo extends Component {

	render() {
		let { label, fieldEntries, fieldStyleClass, isImage, placeholder } = this.props;

		if(typeof fieldStyleClass == "undefined") fieldStyleClass = "";
        if(typeof isImage == "undefined") isImage = false;
        
        var fieldEntry = false;
        for (var i in fieldEntries) {
            if (!fieldEntry) {
                fieldEntry = fieldEntries[i];
                break;
            }
        }

        let valueClass = (typeof fieldEntry != "undefined" && fieldEntry.field_value === "Yes") ? "yes" : "no";
		let containerClass = isImage ? "yes-no-img-entry-container" : "yes-no-entry-container";

		let imgSources = {
            loto: 'loto.jpg',
            emergency: 'emergency-control.jpg',
            signage: 'signage.jpg',
            permit: 'permit.jpg',
            sk: 'spill-kit.jpg',
            scaff: 'scaffold.jpg',
            mewp: 'mewp.jpg',
            peco: 'peco.jpg',
            mt: 'mobile-tower.jpg',
            steps: 'stepladder.jpg',
            fall: 'fall-rest.jpg',
            suit: 'suitability.jpg',
            date: 'in-date.jpg',
            order: 'good-order.jpg',
            guard: 'guards.jpg',
            eye: 'eye-protection.jpg',
            hear: 'hearing.jpg',
            risk: 'risk.jpg',
            mech: 'mech-aids.jpg',
            train: 'training.jpg',
            route: 'clear-route.jpg',
            lift: 'team-lift.jpg',
            bins: 'bin-present.jpg',
            cable: 'cables-up.jpg',
            area: 'clear-areas.jpg',
            sign2: 'signage-2.jpg',
            barrier: 'barriers.jpg',
            dust: 'dust.jpg',
            coshh: 'coshh.jpg',
            ppe: 'correct-ppe.jpg',
            seg: 'segregation.jpg'
        };

        return (
			<Fragment>
				<div className={fieldStyleClass + " " + containerClass}>
					{isImage ? (
						<Fragment>
                            <img className="yn-img" src={ "/ss-imgs/" + imgSources[placeholder] } alt="Icon" />
							<div className="yes-no-img-image" style={{backgroundImage: "url('../../../ss-imgs/" + imgSources[placeholder] + "')"}}></div>
						</Fragment>
					) : ("")}
					<div className="yes-no-entry-label">
						{ label }
					</div>
					<div className={ valueClass + " yes-no-entry" }>
						<span className="yes">Yes</span>
						<span className="no">No</span>
					</div>
					<div className="clear">
					</div>
				</div>
			</Fragment>
		)
	}

}

export default YesNo;