import React, { Component } from 'react';

import CheckedBox from './../../../assets/img/cbox-icon-checked.png';
import CheckedBoxGrey from './../../../assets/img/cbox-icon-checked-grey.png';
import CheckBox from './../../../assets/img/cbox-icon.png';
import CheckBoxGrey from './../../../assets/img/cbox-icon-grey.png';

class CbGroup extends Component {

	render() {

		let { label, outputLabel, fieldEntries, fieldStyleClass, fieldType } = this.props;
		if (typeof outputLabel == "undefined") outputLabel = true;

		// Get the entry from the entries object / array - as we cannot rely on zero indexing
		var fieldEntry = false;
		for (var i in fieldEntries) {
			if (!fieldEntry) {
				fieldEntry = fieldEntries[i];
				break;
			}
		}

		let entries = JSON.parse(fieldEntry.field_value);

		return (
			<div className={fieldStyleClass + " " + fieldType + " cb-field-entry-container"}>
				<div className="cb-field-entry-label">
					{ outputLabel ? label : "" }
				</div>
				<div className="cb-entries">
					{ entries.map (  (entry, i) => 
						<div key={ 'cb-' + i } className="option">
							{ entry.isChecked ? (
								<img src={ this.props.isPrint ? CheckedBoxGrey : CheckedBox } alt="Checked" />
							) : (
								<img src={this.props.isPrint ? CheckBoxGrey : CheckBox} alt="Checked" />
							)}
							<p>{ entry.value }</p>
						</div>
					)}
				</div>

			</div>
		)
	}

}

export default CbGroup;
