import React, { Component } from 'react';

class FlashMsg extends Component {

	render() {

        if (!this.props.error) {
            return "";
        }

		return (
            <div className={ "flash " + this.props.error.style }>
                { this.props.error.msg }
            </div>
		)
	}

}

export default FlashMsg;