import React, { Component, Fragment } from 'react';



class LightBoxExternalLink extends Component {

    constructor(props) {
        super(props);


        this.state = {
            msg:false,
        }

    }

 

  render() {

    let {msg} = this.state ;

    
    return (
        <Fragment>
            <div className='lightbox lightbox-create-booking'>
                <div className='lightbox-content external-report-content'>
                <div className='close' onClick={(e) => this.props.toggleExternalLinkLightBoxOpen(e) }>&times;</div>
                    <div className='lightbox-header'>
                    
                        <h4>External Report Link Created</h4>
                        <p>Note this will expire in 1 month from now</p>
                        {msg ? (
                            <div className={msg.className}>
                                {msg.content}
                            </div>
                        ) : ""}
                        
                    </div>                

                    <div className='lightbox-section'>
                        <p>{ this.props.externalUrlLink }</p>
                    </div>

                </div>
            </div>
        </Fragment>
      
    );
  }
}


export default LightBoxExternalLink;

