import React, { Component, Fragment } from 'react';

class Footer extends Component {

  constructor(props){
    super(props);
    this.state = {
      intervalId:-1,
      hasScrollbar:false
    }
    this.checkFooterOffPage = this.checkFooterOffPage.bind(this);
  }

  componentDidMount(){
    var intervalId = setInterval(this.checkFooterOffPage, 3000);
    // store intervalId in the state so it can be accessed later:
    this.setState({intervalId: intervalId});
  }

  componentWillUnmount() {
    // use intervalId from the state to clear the interval
    clearInterval(this.state.intervalId);
 }

 checkFooterOffPage(){
  let  hasScrollbar = false;

  if(window.innerHeight){
    hasScrollbar = document.body.offsetHeight > (window.innerHeight - 50);
  } else {
    hasScrollbar = (document.documentElement.scrollHeight + 50) > 
          document.documentElement.offsetHeight ||
          document.body.scrollHeight>document.body.offsetHeight;
  }

  this.setState({hasScrollbar});
 }

  render() {
    let style = null;

    if(this.state.hasScrollbar){
      style={ position:'relative', marginTop: 32}
    }

    return (
        <Fragment>
            <footer style={style}>
                <p>All content and design &copy; 2020 Hardgate Painting Ltd</p>
            </footer>
                       
        </Fragment>
      
    );
  }
}


export default Footer;
