import React, { Component, Fragment } from 'react';

import './../../assets/css/login.css';

import logo from './../../assets/img/hardgate-logo.png';

class Header extends Component {

  render() {

    return (
        <Fragment>
            <header>
              <div className="logo-circle">
                <img src={ logo } className="logo-login" alt="Hardgate logo"/>
              </div>
            </header>
        </Fragment>
      
    );
  }
}



export default Header;
