import React, { Component, Fragment } from 'react';

import Header from '../core/header.js'
import Footer from '../core/footer.js'

import MainTitle from '../common/titles/main-title.js';

import DatasheetTable from './manfacturer-table.js'


import LightBoxCreateManufacturer from './lightbox-create-manufacturer.js';
import Fetching from '../utilities/fetching.js';

import { withCookies } from 'react-cookie';

class Manufacturer extends Component {

  constructor(props) {
    super(props);

    this.state = {
        isCreateManufacturerLightBoxOpen:false,
        isEditProjectLightBoxOpen:false,
        msg:false,
        manufacturers:[],
        loading:true,
        auth:{},
        total:-1,
        page:1,
        document_id:-1,
        isEdit:false
    }

    this.toggleCreateNewManufacturerLightBoxOpen = this.toggleCreateNewManufacturerLightBoxOpen.bind(this);
    this.updateMsg = this.updateMsg.bind(this);
    this.deleteManufacturer = this.deleteManufacturer.bind(this);
    this.loadManufacturers = this.loadManufacturers.bind(this);
  }

  componentDidMount(){
    this.loadManufacturers();
  }

  loadManufacturers(usrAuth){
      // If it is this type of query display the loader 
      if (typeof displayLoader != 'undefined') {
        this.setState({ loading: true });
    }

    if(typeof usrAuth === 'undefined'){
      usrAuth = this.props.cookies.get('ldt-auth');
    }
    

    // Get the users
    var thus = this;
    fetch(process.env.REACT_APP_API_BASE + '/manufacturers', {
        method: 'get',
        headers: { 
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
            "apiToken": usrAuth.apiToken,
            "apiKey": usrAuth.apiKey,
        },
    })
    .then(Fetching.statusCheck)
    .then(Fetching.jsonExtract)
    .then(function (data) {

        thus.setState({ manufacturers: data, loading: false });
    })
    .catch(function (error) {
        console.log('Request failed', error);
    });
  }

deleteManufacturer(e,manufacturer_id){

  var doDelete = window.confirm('This cannot be undone.  Do you wish to contiune?');

  if(!doDelete){
    return false;
  }

  e.preventDefault();
  // If it is this type of query display the loader 
  if (typeof displayLoader != 'undefined') {
    this.setState({ loading: true });
  }


  let usrAuth = this.props.cookies.get('ldt-auth');
 

  // Get the users
  var thus = this;
  fetch(process.env.REACT_APP_API_BASE + '/manufacturers/'+manufacturer_id, {
      method: 'delete',
      headers: { 
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          "apiToken": usrAuth.apiToken,
          "apiKey": usrAuth.apiKey,
      }
  })
  .then(Fetching.statusCheck)
  .then(Fetching.jsonExtract)
  .then(function (data) {
       thus.loadManufacturers();
       thus.setState({msg:false})
  })
  .catch(function (error) {
      console.log('Request failed', error);
  });
}

  updateMsg ( value ) {
    this.setState({ msg: value });
}

toggleCreateNewManufacturerLightBoxOpen(e,manufacturer_id){
  if(typeof e !== 'undefined'){
    e.preventDefault();
  }
  let msg= this.state.msg;

    if(!this.state.isEditProjectLightBoxOpen){
      msg = false
    }
  
  this.setState(prevState => ({
    isCreateManufacturerLightBoxOpen: !prevState.isCreateManufacturerLightBoxOpen,
    manufacturer_id:manufacturer_id,
      isEdit:true,
      msg:msg
  }));
}

  render() {

    let {msg,manufacturers} = this.state;

    let lightBoxCreateManufacturer = null;

    if(this.state.isCreateManufacturerLightBoxOpen){
      lightBoxCreateManufacturer = <LightBoxCreateManufacturer triggerMsg={ this.updateMsg } uid={this.state.lightbox_uid} toggleCreateNewManufacturerLightBoxOpen={this.toggleCreateNewManufacturerLightBoxOpen} manufacturer_id={this.state.manufacturer_id} closeFunc={this.toggleCreateNewManufacturerLightBoxOpen} loadManufacturers={this.loadManufacturers} />
    }

  
    return (

        <Fragment>
            {lightBoxCreateManufacturer}
            <Header/>
            <section className="content">
                <div className="width-9 centred">
                  
                  <button className="right"  onClick={(e) => this.toggleCreateNewManufacturerLightBoxOpen(e)}>New Manufacturer</button>
                     
                  < MainTitle text='Manage Manufacturers'/>

                  {msg ? (
                        <div className={msg.className}>
                            {msg.content}
                        </div>
                    ) : ""}
                </div>

                <DatasheetTable deleteManufacturer={this.deleteManufacturer} loadManufacturers={this.loadManufacturers} togglePublishStatus={this.togglePublishStatus} manufacturers={manufacturers} toggleCreateNewManufacturerLightBoxOpen={this.toggleCreateNewManufacturerLightBoxOpen} {...this.state}/>
                
            </section>
            <Footer/>
        </Fragment>
    );
  }
}

export default withCookies(Manufacturer);