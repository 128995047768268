import React, { Component, Fragment } from 'react';

import Text from '../common/fields/text.js';


import SubmitButton from '../common/fields/submit-button.js';

import Fetching from './../utilities/fetching';
import { withCookies } from 'react-cookie';


class LightBoxCreateManufacturer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            document_title:'',
            short_description:'',
            manufacturer:'',
            category:'',
            errors:false,
            saving:'Create Manufacturer',
            doRedirect:false
    
        }

        this.updateField = this.updateField.bind(this);
        this.createManufacturer = this.createManufacturer.bind(this);
    }

    updateField(fieldName, value) {
        this.setState({ [fieldName]: value });
    }

    

    createManufacturer(e){

  
        e.preventDefault();

        this.setState({ saving: "Creating Manufacturer..." });

        let auth = this.props.cookies.get('ldt-auth');

        // Set up the save data 
        let {manufacturer} = this.state ;


        var thus = this;
        fetch(process.env.REACT_APP_API_BASE + '/manufacturers', {
            method: 'post',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                "apiToken": auth.apiToken,
                "apiKey": auth.apiKey
            },
            body: 'name='+manufacturer 
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (rslt) {

            console.log(rslt);
            if(typeof rslt.id !== 'undefined'){
                thus.setState({saving: "Create Manufacturer"} );
                thus.props.triggerMsg({ content: "The manufacturer has been successfully created.", className: "message success mbtm" });
                thus.props.loadManufacturers(auth);
                thus.props.toggleCreateNewManufacturerLightBoxOpen();
            } else {
                for(var i in rslt){
                    let key = i.replace('-','_');
                    rslt[key] = rslt[i];
                    if (key !== i) {
                        delete rslt[i];
                    }
                }
                console.log(rslt);
                thus.setState({ saving: "Create Manufacturer", errors: rslt, msg: { content: "Sorry but there was an issue creating the manufacturer. Please check the errors below and try again.", className: "message" } });
            }
        })
        .catch(function (error) {
            thus.setState({ saving: "Create Booking",  msg: { content: "Sorry, but there was an error on the server.", className: "message" } });
            console.log('Request failed', error);
        });
    }

  render() {

    let {msg,errors,manufacturer,saving } = this.state ;

    
    return (
        <Fragment>
            <div className='lightbox lightbox-create-booking'>
                <div className='lightbox-content'>
                <div className='close' onClick={(e) => this.props.toggleCreateNewManufacturerLightBoxOpen(e) }>&times;</div>
                    <div className='lightbox-header'>
                    
                        <h4>Create Manufacturer</h4>
                        {msg ? (
                            <div className={msg.className}>
                                {msg.content}
                            </div>
                        ) : ""}
                        
                    </div>                

   
                    <div className='lightbox-section'>
                        <Text label='Manufacturer' id='manufacturer' value={ manufacturer } onChange={this.updateField} errors={errors}/>
                    </div>

                    <div className='lightbox-submit'>
                        <SubmitButton handleSubmit={this.createManufacturer} text={ saving }/>
                    </div>
                </div>
            </div>
        </Fragment>
      
    );
  }
}


export default withCookies(LightBoxCreateManufacturer) ;

