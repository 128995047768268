import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom'

import Logout from '../users/logout';
import Error404Component from './Error404Component';
import Dashboard from '../dashboard/index';

import ReportNavigator from '../report-navigator/index.js';
import Report from '../report-navigator/report.js';

import Projects from '../projects/index.js'
import Toolbox from '../toolbox-talks/index.js'

import Loader from '../common/loader.js'
import Datasheet from '../datasheets';
import ManageUsers from '../users/manage-users';
import Settings from '../settings';

import Fetching from '../utilities/fetching.js';
import { withCookies } from 'react-cookie';
import ManageWorkers from '../workers/manage-workers';
import Manufacturers from '../manufacturers';
import Attendees from '../toolbox-talks/toolbox-talk-attendees';
import Email from '../emails';

class AdminTemplate extends Component {

  constructor(props) {
    super(props);

    this.state = {
      auth: true,
      adminSuccess: "",
      adminError: "",
      pendingAuth: true
    }

    this.checkCookies = this.checkCookies.bind(this);
    this.checkStatus = this.checkStatus.bind(this);
  }

  checkCookies(){
    const { cookies } = this.props;
    var authCookie = cookies.get('ldt-auth');

    // If we don't have an auth cookie stored then check whether token and key were passed in the string
    if (typeof authCookie === 'undefined') {
      var qs = this.props.location.search;
      if ( typeof qs != "undefined" && qs !== "" ) {
        // Process the query string to get the relevant elements
        qs = qs.substring(1);
        qs = qs.split('&');
        // Set up a testCookie to assess whether we have what we need
        var testCookie = {};
        for ( var i in qs ) {
          if ( qs[i].indexOf('apikey') > -1) {
            testCookie.apiKey = qs[i].replace('apikey=', "");
          }
          if (qs[i].indexOf('apitoken') > -1) {
            testCookie.apiToken = qs[i].replace('apitoken=', "");
          }
        }
        // Check wehter we have a token and key from the string
        if ( typeof testCookie.apiKey != "undefined" && typeof testCookie.apiToken != "undefined" ) {
          authCookie = testCookie;
        }
      }
    }

    if(typeof authCookie === 'undefined'){
        this.setState({auth:false})
    } else {
        this.checkStatus(authCookie);
    }
  }

  checkStatus (authCookie) {
    
    const { cookies } = this.props;

    let {apiToken,apiKey} = authCookie;
    var thus = this;
    fetch(process.env.REACT_APP_API_BASE + '/users/check_auth', { 
        method: 'get',
        headers: { 
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        "apiToken": apiToken,
        "apiKey": apiKey
        }
     })
      .then(Fetching.adminStatusCheck)
      .then(Fetching.jsonExtract)
      .then(function (data) {
        if (typeof data.public_id !== 'undefined' &&  data.public_id.length > 0) {
          thus.setState({ auth: true,pendingAuth:false });
        } else {
            thus.setState({ auth: false });
        }
      })
      .catch(function (error) {

          if(error === 'unauthorised'){
            cookies.remove('ldt-auth');      
            thus.setState({ auth: false });
          }
        
      
      });

  }

  componentDidMount() {
    this.checkCookies();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
        this.checkCookies();
    }


  }

  render() {

    if (this.state.auth === false) {

      return <Redirect to='/' />
    }

    if (this.state.pendingAuth === true) {
      return (
        <Loader bg='center-page' />
      );
    }

    return (
        
        <Switch>
          <Route exact path='/admin/settings' component={ Settings }/>
            <Route exact path='/admin/manage-users' component={ ManageUsers }/>
            <Route exact path='/admin/manage-workers' component={ ManageWorkers }/>
            <Route exact path='/admin/datasheets' component={ Datasheet }/>
            <Route exact path='/admin/manufacturers' component={ Manufacturers }/>
            <Route exact path='/admin/projects' component={ Projects }/>
            <Route exact path='/admin/toolbox-talks' component={ Toolbox }/>
            <Route exact path='/admin/toolbox-talks/attendees/:ttId' component={ Attendees }/>
            <Route exact path='/admin/report-navigator' component={ ReportNavigator }/>   
            <Route exact path='/admin/report/:reportId/:hideLayout' component={ Report }/>   
            <Route exact path='/admin/report/:reportId' component={ Report }/>
            <Route exact path='/admin/emails/:userType/:id' component={ Email }/> 
            <Route exact path='/admin/emails' component={ Email }/>   
            <Route exact path='/admin/dashboard' component={ Dashboard }/>   
            <Route exact path='/admin/logout' component={ Logout }/>
            <Route component={Error404Component} />
        </Switch>

    );
  }
}



export default withCookies(AdminTemplate);
