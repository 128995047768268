import React, { Component, Fragment } from 'react';
import Fetching from './../utilities/fetching';
import { withCookies } from 'react-cookie';
import Loader from './../common/loader';

class ReportReviewList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      reportsForReview:[],
      loading: true
    }
    this.loadReportsForReview = this.loadReportsForReview.bind(this);
  }

  componentDidMount(){
    this.loadReportsForReview();
  }

  // Get the user details 
  loadReportsForReview() {

    // Get the user details
    var thus = this;
    let auth = this.props.cookies.get('ldt-auth');

    fetch(process.env.REACT_APP_API_BASE + '/reports/reports_for_review', {
        method: 'get',
        headers: {
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
            "apiToken": auth.apiToken,
            "apiKey": auth.apiKey
        },
    })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
          thus.setState({reportsForReview:data, loading: false});
        })
        .catch(function (error) {
            console.log('Request failed', error);
        });
      
  }

  // Trigger the opening of the report list lightbox
  openReportsList(e, approver){
    this.props.toggleReviewLightboxOpen(e, approver);
  }

  // Close the report list lightbox
  closeReportsList(){
    this.setState({reports: false});
  }

  render() {

    let { loading, reportsForReview } = this.state;

    return (

        <div className='width-3'>
          <div className='dashboard-panel'>
          <h2 className='dashboard-panel-title'>Reports for review</h2>
          
          { loading ? (
              <div style={{ marginTop: 44 }}>
                <Loader />
              </div>
            ) : (
              <Fragment>
                {typeof reportsForReview == "undefined" || reportsForReview.length === 0 ? (
                  <p className="no-reports">No reports for review</p>
                ): (
                  <Fragment>
                      {reportsForReview.map((approver, index) => 
                        <div className="panel-list-item inc-lnk" key={index}>
                          <button className="full-lnk" onClick={ (e) => this.openReportsList(e, approver) }></button>
                          <div className="status">{ approver.reportTotal }</div>
                          <h3>{ approver.approver !== null ? approver.approver.first_name + " " + approver.approver.last_name : "No admin specified" }</h3>
                        </div>
                      )}
                  </Fragment>
                )}
              </Fragment>
            )}
          </div>
        </div>


    );
  }
}

export default withCookies(ReportReviewList);

