import React, { Component, Fragment } from 'react';

class AuthenticateSuccessMessage extends Component {

  render() {

    let {message} = this.props;

    return (
        <Fragment>
            <div className="message success">
                {message}
            </div>
                       
        </Fragment>
      
    );
  }
}


export default AuthenticateSuccessMessage;
