import React, { Component, Fragment } from 'react';

import Header from '../core/header.js'
import Footer from '../core/footer.js'

import Fetching from '../utilities/fetching.js';

import { withCookies } from 'react-cookie';
import moment from 'moment';
import Select from '../common/fields/select.js';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.js';
import Loader from '../common/loader.js';
import PaginatorLinks from '../common/paginator/paginator-links.js';

class Email extends Component {

  constructor(props) {
    super(props);

    this.state = {
        msg:false,
        emails:[],
        loading:true,
        auth:{},
        total:-1,
        page:1,
        current:-1,
        isEdit:false,
        nameOptions :[{name:'Filter by User:',value:''} ],
        rdr:false,
        filterValue:false
    }
    this.handleNameSelect = this.handleNameSelect.bind(this)
    this.loadEmails = this.loadEmails.bind(this);
    this.loadEmailsPaginator = this.loadEmailsPaginator.bind(this)
    this.getPageFromQueryString = this.getPageFromQueryString.bind(this)
  }

  componentDidMount(){
    let {userType,id} = this.props.match.params;

    if(typeof userType !== 'undefined' && typeof id !== 'undefined'){
        let filterValue = userType.charAt(0)+'_'+id
        this.setState({filterValue:filterValue})
    }
    
    this.setState({rdr:false})
    this.loadEmails(userType,id);
  }

  componentDidUpdate(prevProps){

    if (this.props.match.params.userType !== prevProps.match.params.userType ||
        this.props.match.params.id !== prevProps.match.params.id ) {

        let {userType,id} = this.props.match.params;
        if(typeof userType !== 'undefined' && typeof id !== 'undefined'){
            let filterValue = userType.charAt(0)+'_'+id
            this.setState({filterValue:filterValue})
        }
        
        this.setState({rdr:false})
        this.loadEmails(userType,id);
        
    }
  }

  handleNameSelect(fieldName, selected){

    let rdrUrl = '/admin/emails';

    if(selected.length > 0){
        let idArray = selected.split('_');
        if(idArray[0] === 'u'){
            rdrUrl += '/user/'+idArray[1]
        } else if(idArray[0] === 'c'){
            rdrUrl += '/contact/'+idArray[1]
        }
    }
    

    this.setState({rdr:rdrUrl,filterValue:selected})
  }

  loadEmailsPaginator(){

    this.setState({ loading: true });

    let usrAuth = this.props.cookies.get('ldt-auth');
    let urlString = '/emails';
    let idArray = this.state.filterValue.split('_');
    let id, userType;

    if(idArray.length){
        id = idArray[1]
        if(idArray[0]  === 'c'){
            userType = 'contact'
        } else if (idArray[0] === 'u'){
            userType = 'user'
        }
    }

    if(typeof userType !== 'undefined' && typeof id !== 'undefined'){
        urlString += '/' + userType + '/'+id
    }

    let page = this.getPageFromQueryString();
    
    urlString += '?page=' + page

    // Get the users
    var thus = this;
    fetch(process.env.REACT_APP_API_BASE + urlString, {
        method: 'get',
        headers: { 
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
            "apiToken": usrAuth.apiToken,
            "apiKey": usrAuth.apiKey,
        },
    })
    .then(Fetching.statusCheck)
    .then(Fetching.jsonExtract)
    .then(function (data) {
        let nameOptions = [{name:'Filter by User:',value:''} ];
        nameOptions = nameOptions.concat(data.search_options);
        thus.setState({ emails: data.rslts,nameOptions:nameOptions,pages: data.pages, total: data.total, current: data.current, loading: false });
    })
    .catch(function (error) {
        console.log('Request failed', error);
    });
  }

  getPageFromQueryString(){
    var qsParams = this.props.location.search.replace("?", "");
    qsParams = qsParams.split("&");
    var page = false;
    for (var j in qsParams) {
        if (qsParams[j].indexOf("page=") > -1) {
            page = qsParams[j].replace("page=", "");
        }
    }

    if ( !page ) page = 1;
    page = Number(page);

    return page
  }

  loadEmails(userType,id){
      // If it is this type of query display the loader 

    this.setState({ loading: true });
    let usrAuth = this.props.cookies.get('ldt-auth');
    
    let urlString = '/emails';

    if(typeof userType !== 'undefined' && typeof id !== 'undefined'){
        urlString += '/' + userType + '/'+id
    }

    let page = this.getPageFromQueryString();
    
    urlString += '?page=' + page

    // Get the users
    var thus = this;
    fetch(process.env.REACT_APP_API_BASE + urlString, {
        method: 'get',
        headers: { 
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
            "apiToken": usrAuth.apiToken,
            "apiKey": usrAuth.apiKey,
        },
    })
    .then(Fetching.statusCheck)
    .then(Fetching.jsonExtract)
    .then(function (data) {
        let nameOptions = [{name:'Filter by User:',value:''} ];
        nameOptions = nameOptions.concat(data.search_options);
        thus.setState({ emails: data.rslts,nameOptions:nameOptions,pages: data.pages, total: data.total, current: data.current, loading: false });
    })
    .catch(function (error) {
        console.log('Request failed', error);
    });
  }


  updateMsg ( value ) {
    this.setState({ msg: value });
}



  render() {

    let {emails,nameOptions,rdr,filterValue,loading,pages,total,current} = this.state;

    if (rdr !== false) {
        return <Redirect to={rdr} />
      }

   let emailTableView = null;

   if (loading){
        emailTableView = (<tr className="holding">
            <td colSpan="4">
                <Loader />
            </td>
        </tr>)
   } else {
        if(emails.length === 0){
            emailTableView = <tr key={'email_row_empty'}>
                <td colSpan={4} style={{textAlign:'center',fontStyle:'italic'}}>No Emails for this Account</td>
            </tr>
        } else {
            emailTableView = emails.map((email, i) => {
                let emailAddress = '-';
                let user = '-';
        
                if (email.to_user_type === 'user' && email.to_user !== null && email.to_user.email_address !== null) {
                    emailAddress = email.to_user.email_address;
                    user = email.to_user.first_name + ' ' + email.to_user.last_name;
                } else if (email.to_user_type === 'contact' && email.to_contact !== null && email.to_contact.email_address !== null) {
                    emailAddress = email.to_contact.email;
                    user = email.to_contact.name;
                }
        
                if (email.email_to !== null) {
                    emailAddress = email.email_to;
                }
        
                return (
                    <tr key={'email_row_' + i}>
                        <td>{user}</td>
                        <td>{emailAddress}</td>
                        <td>{email.subject}</td>
                        <td>{moment(email.created_at).format("MMM D YYYY, h:mm A")}</td>
                    </tr>
                );
            })
        }
   } 
    
   const emailPageUrl = this.props.location.pathname;
  
    return (
        
        <Fragment>
            <Header/>
            <section className="content">
                  
                <div className='width-12 centred email-search'>
                    <Select 
                        options={nameOptions} 
                        
                        id='search-filter' 
                        handleSelect={this.handleNameSelect} 
                        value={filterValue} 
                        />
                    <h1>Emails</h1>
                <table className='datasheets-table'>
                    <thead>
                        <tr>
                            <th className='rx-800-hidden'>User</th>
                            <th className='rx-1100-hidden'>Email</th>
                            <th className='rx-1100-hidden'>Subject</th>
                            <th className='rx-800-hidden'>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                       {emailTableView}
                    </tbody>
                </table>
                { !loading && pages > 1 ? (
                <PaginatorLinks 
                    actionFunc={ this.loadEmailsPaginator }
                    total={ total }
                    current={ current }
                    pages={pages }
                    baseUrl={ emailPageUrl }
                    delay={ 10 }
                />
                ) :"" }
                </div>
            </section>
            <Footer/>
        </Fragment>
    );
  }
}

export default withCookies(Email);