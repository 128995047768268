import React, { Component, Fragment } from 'react';

// import Fetching from './../../utilities/fetching';
import Loader from './loader';

class LightBox extends Component {

    constructor(props) {
        super(props);
        this.state = {
            details: false,
            loading: true
        }

    }

    componentDidMount () {
        this.loadDetail(this.props.uid);
        console.log(this.props.uid);
    }

    loadDetail ( uid ) {

        // // Get the user details
        // var thus = this;
        // fetch(process.env.REACT_APP_API_BASE + '/users/' + uid, {
        //     method: 'get',
        //     headers: {
        //         "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        //         "apiToken": this.props.auth.apiToken,
        //         "apiKey": this.props.auth.apiKey
        //     },
        // })
        // .then(Fetching.statusCheck)
        // .then(Fetching.jsonExtract)
        // .then(function (data) {
        //     // Decode the address 
        //     if (typeof data.address != "undefined") {
        //         data.address = JSON.parse(data.address);
        //     }
        //     // Set it ot the state
        //     thus.setState({ details: data, loading: false });
        // })
        // .catch(function (error) {
        //     console.log('Request failed', error);
        // });

    }

  render() {

    let { details, loading } = this.state;
console.log(details);
    return (
        <Fragment>
            <div className='lightbox'>
                <div className='lightbox-content'>
                <div className='close' onClick={(e) => this.props.closeFunc(e) }>&times;</div>
                    <div className='lightbox-header'>
                        <h4>User Details</h4>
                    </div>

                    { loading ? (
                        <Loader bg="white" mrgn="mrgn-lrg" />
                    ) : (
                        <Fragment>
                            <div className='lightbox-section'>
                                <p>First Name</p>
                                <p className='lightbox-info'>{ details.first_name }</p>
                            </div>
                            <div className='lightbox-section'>
                                <p>Last Name</p>
                                <p className='lightbox-info'>{details.last_name}</p>
                            </div>
                            <div className='lightbox-section'>
                                <p>Email Address</p>
                                <p className='lightbox-info'>{ details.email_address }</p>
                            </div>
                            <div className='lightbox-section'>
                                <p>Telephone</p>
                                <p className='lightbox-info'>{details.telephone}</p>
                            </div>
                            {/* { details.address !== null && typeof details.address != "undefined" && typeof details.address.structured != "undefined" && typeof details.address.structured.main != "undefined" ? (
                                <div className='lightbox-section'>
                                    <p>Address</p>
                                    <p className='lightbox-info'>
                                        { details.address.structured.main }<br />
                                        { details.address.structured.secondary }
                                    </p>
                                </div>
                            ) : null } */}
                        </Fragment>
                    )}
                </div>
            </div>
        </Fragment>
      
    );
  }
}


export default LightBox;

