import React, { Component, Fragment } from 'react';

import BasicFieldEntry from '../field-entry-types/basic-field-entry.js'

class Row extends Component {

	// constructor(props) {
	// 	super(props);
		// this.state = {
		// 	showComments: false
		// };

		// this.slideNxt = this.slideNxt.bind(this);
		// this.slidePrev = this.slidePrev.bind(this);
		// this.toggleComments = this.toggleComments.bind(this);
	// }

	// slideNxt() {
	// 	if(typeof this.props.slideAction == "function") {
	// 		let newSlide = this.props.slideNo + 1;
	// 		this.props.slideAction(newSlide);
	// 	}
	// }

	// slidePrev() {
	// 	if(typeof this.props.slideAction == "function") {
	// 		let newSlide = this.props.slideNo - 1;
	// 		this.props.slideAction(newSlide);
	// 	}
	// }

	// toggleComments() {
	// 	let { showComments } = this.state;
	// 	let newComments = (showComments) ? false : true;
	// 	this.setState({ showComments: newComments });
	// }

    render() {
		// console.log("REPORT FIELDS", this.props.grp);
		let includeComments = false;
		let columnCount = 0;

		// loop through fields
			// if field type == 'TextAreaOpen'
				// loop through field entries
					// if value != "", set includeComments = 1
		
		for(var i in this.props.grp.report_fields) {
			if(this.props.grp.report_fields[i].field_type === "TextAreaOpen") {
				for(var j in this.props.grp.report_fields[i].report_field_entries) {
					if(this.props.grp.report_fields[i].report_field_entries[j].field_value !== "") {
						includeComments = true;
					}
				}
			}
			columnCount++;
		}

        return (
			<Fragment>
				<table className="temp">
					<tbody>
						{ this.props.itemNo === 0 ? (
							<tr className="report-table-header-labels">
								<td></td>
								{this.props.grp.report_fields.map((field, i) =>
									<Fragment key={i}>
										{field.field_type !== "TextAreaOpen" ? (
											<td className="report-table-grey-cell report-table-label">{ field.label }</td>
										) :  ( <Fragment></Fragment> )}
									</Fragment>
								)}
							</tr>
						) : ( <Fragment></Fragment> )}
						<tr className="report-table-row">
							<td className="report-table-grey-cell report-table-label">{this.props.grp.title}</td>
							{this.props.grp.report_fields.map((field, i) =>
								<Fragment key={i}>
									{ field.field_type !== "TextAreaOpen" ? (
										<td className="report-table-grey-cell" key={i}>
											<Fragment>
												{field.field_type !== "TextAreaOpen" ? (
													<BasicFieldEntry fieldEntries={ field.report_field_entries } label={ "" } outputLabel={ false } post={ field.field_type === "Temperature" ? "°C" : "%" } />
												) : ( 
													<Fragment>
													</Fragment>
												)}
											</Fragment>
										</td>
									) : (<Fragment></Fragment>)}
								</Fragment>
							)}
						</tr>
						{ includeComments ? (
							<Fragment>
								{this.props.grp.report_fields.map((field, i) =>
									<Fragment key={i}>
										{field.report_field_entries.map((field_entry, k) =>
											<Fragment key={k}>
												{field.field_type === "TextAreaOpen" && field_entry.field_value !== null && field_entry.field_value !== "" ? (
													<tr>
														<td colSpan={ columnCount }>
															<div className="basic-field-entry comment">
																{field_entry.field_value}
															</div>	
														</td>
													</tr>
												) : ( 
													<Fragment>
													</Fragment>
												)}
											</Fragment>
										)}
									</Fragment>
								)}
							</Fragment>
						) : (
							<Fragment>
							</Fragment>
						)}
					</tbody>
				</table>
			</Fragment>
        );
    }

}

export default Row;