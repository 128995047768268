import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import Loader from '../common/loader.js';
import Fetching from '../utilities/fetching.js';

class ReportTypeSelector extends Component {

  constructor(props) {
    super(props);

    this.state = {
      reportTypes: false,
      loading: true
    }

  }

  componentDidMount () {
    this.loadReportTypes();
  }

  loadReportTypes () {
    let auth = this.props.cookies.get('ldt-auth');

    var thus = this;
    fetch(process.env.REACT_APP_API_BASE + '/report_types/min', {
      method: 'get',
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        "apiToken": auth.apiToken,
        "apiKey": auth.apiKey
      },
    })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(function (repTypes) {
        thus.setState({ reportTypes: repTypes, loading: false });
      })
      .catch(function (error) {
        console.log('Request failed', error);
      });
  }


  render() {

    let {reportTypes, loading} = this.state;

    return (

        <div className='width-3'>
          <div className='dashboard-panel report-type-panel report-type-selector-panel'>
            <h2 className='dashboard-panel-title'>Select a Report Type</h2>

            { loading ? (
              <div style={{ marginTop: 100 }}>
                <Loader />
              </div>
            ) : (
              <Fragment>
                <h4>Daily Reports</h4>
                { reportTypes.map ( (repType, j) =>
                  <Fragment key={"opto-" + j}>
                    { repType.regularity === "daily" ? (
                      <li key={'opt-' + repType.id + '-' + j}>
                        <Link to='#' onClick={(e) => this.props.selectType(e, repType.id)} className={ this.props.selectedType === repType.id ? "selected-lnk" : "" }>{repType.title}</Link>
                      </li>
                    ) : ""}
                  </Fragment>
                )}

                <h4 className="mtop">Weekly Reports</h4>
                {reportTypes.map((repType, j) =>
                  <Fragment key={"opto-" + j}>
                    {repType.regularity === "weekly" ? (
                      <li key={'opt-' + repType.id + '-' + j}>
                        <Link to='#' onClick={(e) => this.props.selectType(e, repType.id)} className={this.props.selectedType === repType.id ? "selected-lnk" : ""}>{repType.title}</Link>
                      </li>
                    ) : ""}
                  </Fragment>
                )}

                <h4 className="mtop">Fortnightly Reports</h4>
                {reportTypes.map((repType, j) =>
                  <Fragment key={"opto-" + j}>
                    {repType.regularity === "fortnightly" ? (
                      <li key={'opt-' + repType.id + '-' + j}>
                        <Link to='#' onClick={(e) => this.props.selectType(e, repType.id)} className={this.props.selectedType === repType.id ? "selected-lnk" : ""}>{repType.title}</Link>
                      </li>
                    ) : ""}
                  </Fragment>
                )}

                <h4 className="mtop">Other</h4>
                <li>
                  <Link to='#' onClick={(e) => this.props.selectType(e, "tt-attendees")} className={this.props.selectedType === "tt-attendees" ? "selected-lnk" : ""}>Toolbox talk attendees</Link>
                </li>
              </Fragment>
            )}

          </div>
        </div>


    );
  }
}

export default withCookies(ReportTypeSelector);