import React, { Component, Fragment } from 'react';
import ReportItem from './report-item.js'
import { Link } from 'react-router-dom';
import Fetching from './../utilities/fetching';
import { withCookies } from 'react-cookie';
import Loader from './../common/loader';

class ReportList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      recentReports:[],
      loading: true
    }

    this.loadRecentReports = this.loadRecentReports.bind(this);

  }

  componentDidMount(){
    this.loadRecentReports();
  }


  // Get the user details 
  loadRecentReports() {

    // Get the user details
    var thus = this;
    let auth = this.props.cookies.get('ldt-auth');

    fetch(process.env.REACT_APP_API_BASE + '/reports/my_reports', {
        method: 'get',
        headers: {
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
            "apiToken": auth.apiToken,
            "apiKey": auth.apiKey
        },
    })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
          thus.setState({recentReports:data, loading: false});
        })
        .catch(function (error) {
            console.log('Request failed', error);
        });

}



  render() {

    let recentReportData = this.state.recentReports;
    let { loading } = this.state;

    let recentReportItems =  recentReportData.map( (reportItem,i) => {
        return (<ReportItem key={i} {...reportItem} />)
    });

    return (


        <div className='width-3'>
          <div className='dashboard-panel'>
            <h2 className='dashboard-panel-title'>Recent reports</h2>
            { loading ? (
              <div style={{ marginTop: 44 }}>
                <Loader />
              </div>
            ) : (
              <Fragment>
                { recentReportItems }
                <div className='text-center'>
                  <Link to={'/admin/report-navigator'} className='btn-dark'>View All</Link>
                </div>
              </Fragment>
            )}
            
          </div>

        </div>


    );
  }
}

export default withCookies(ReportList);

