import React, { Component, Fragment } from 'react';

import Header from '../core/header.js'
import Footer from '../core/footer.js'

import ProjectSelector from './project-selector.js'
import ReportTypeSelector from './report-type-selector.js'
import ReportTable from './report-table.js';

import MainTitle from '../common/titles/main-title.js';
import Loader from '../common/loader.js';
import Fetching from '../utilities/fetching.js';

import { withCookies } from 'react-cookie';
import LightBoxExternalLink from './external_link_lightbox.js';


class ReportNavigator extends Component {

    constructor(props){
        super(props);
        this.state = {
            externalReportUrl:'',
            isExternalLinkBoxOpen:false,

            projectList: [],
            showProjects: true,
            selectedProjectId: false,
            selectedReportType: false
        }

        this.selectProject = this.selectProject.bind(this);
        this.selectReportType = this.selectReportType.bind(this);
        this.generatePublicReportLink = this.generatePublicReportLink.bind(this);
        this.toggleExternalLinkLightBoxOpen = this.toggleExternalLinkLightBoxOpen.bind(this);

    }

    componentDidMount() {
        this.loadProjectList();
    }

    // Get the full list of projects
    loadProjectList() {

        let auth = this.props.cookies.get('ldt-auth');

		var thus = this;
		fetch(process.env.REACT_APP_API_BASE + '/projects/admin', {
			method: 'get',
			headers: { 
				"Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
				"apiToken": auth.apiToken,
				"apiKey": auth.apiKey
			},
		})
		.then(Fetching.statusCheck)
		.then(Fetching.jsonExtract)
		.then(function (projects) {
            thus.setState({ showProjects: true, projectList: projects });
		})
		.catch(function (error) {
			console.log('Request failed', error);
		});

    }

    // Select the project from the project list 
    selectProject (e, projectId) {
        e.preventDefault();
        this.setState({ selectedProjectId: projectId, selectedReportType: false });
    }

    // Select the report type from the list of potential report types
    selectReportType (e, typeId) {
        e.preventDefault();
        console.log(typeId);
        this.setState({ selectedReportType: typeId });
    }

    // Display the link for sending reports
    toggleExternalLinkLightBoxOpen(e){
        e.preventDefault();
        this.setState(prevState => ({
            isExternalLinkBoxOpen: !prevState.isExternalLinkBoxOpen,
        }));
    }

    // Generate the link to display the report publically
    generatePublicReportLink(e, reportId, isAdhoc = false){
        e.preventDefault();

        let usrAuth = this.props.cookies.get('ldt-auth');

        var type = 'reports';
        if (isAdhoc) type = 'document_deliveries';
     
        var thus = this;
        fetch(process.env.REACT_APP_API_BASE + '/reports/generate_link', {
            method: 'post',
            headers: { 
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                "apiToken": usrAuth.apiToken,
                "apiKey": usrAuth.apiKey,
            },
            body: 'id=' + reportId + '&model=' + type
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
               let externalReportUrl = process.env.REACT_APP_SITE_BASE +'/external_report/'+data.url_slug+'/'+data.url_token;
               thus.setState({
                isExternalLinkBoxOpen:true,
                externalReportUrl:externalReportUrl
               });
        })
        .catch(function (error) {
            console.log('Request failed', error);
            alert("Sorry, but there was an error generating the link. Please refresh the page and try again.");
        });
        

    }

  render() {

    let { showProjects, selectedProjectId, projectList, selectedReportType } = this.state;

    let externalLinkLightBox = (this.state.isExternalLinkBoxOpen) ? <LightBoxExternalLink externalUrlLink={this.state.externalReportUrl} toggleExternalLinkLightBoxOpen={this.toggleExternalLinkLightBoxOpen}/> : null
  
    return (
        <Fragment>
            {externalLinkLightBox}
            <Header/>
            
            <section className="content">
                <div className='width-12'>
                    < MainTitle text='View Reports'/>
                </div>

                { showProjects ? (
                    <Fragment>
                        <ProjectSelector 
                            projects={ projectList } 
                            selectProject={ this.selectProject } 
                            selectedProjectId={ selectedProjectId }
                        />

                        { selectedProjectId ? (
                            <Fragment>
                                <ReportTypeSelector 
                                    selectType={ this.selectReportType } 
                                    selectedType={ selectedReportType }
                                />
                            </Fragment>
                        ) : ""}

                        { selectedReportType ? (
                            <Fragment>
                                <ReportTable 
                                    projectId={ selectedProjectId } 
                                    repTypeId={ selectedReportType } 
                                    generatePublicReportLink={ this.generatePublicReportLink }
                                />
                            </Fragment>
                        ) : ""}

                    </Fragment>
                ) : (
                    <div style={{ marginTop: 100 }}>
                        <Loader />
                    </div>
                )}

            </section>

            <Footer/>
            
        </Fragment>
    );
  }
}



export default withCookies(ReportNavigator);
