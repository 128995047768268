import React, { Component, Fragment } from 'react';


class AuthenticateErrorMessage extends Component {

  render() {

    let {message} = this.props;

    return (
        <Fragment>
            <div className="message">
                {message}
            </div>
                       
        </Fragment>
      
    );
  }
}


export default AuthenticateErrorMessage;