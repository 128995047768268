import React, { Component, Fragment } from 'react';
import { withCookies } from 'react-cookie';

import Fetching from './../utilities/fetching';

import Text from './fields/text.js';
import Email from './fields/email.js';
import Select from './fields/select.js';

import SubmitButton from './fields/submit-button.js';

class LightBoxCreateUser extends Component {


    constructor(props){
        super(props);
        this.state = {
            email_address: false,
            first_name: false,
            last_name: true,
            role: "admin",
            saving: "Create user",
            msg: false,
            errors: false
        }

        this.createUsr = this.createUsr.bind(this);
        this.updateField = this.updateField.bind(this);
    }

    updateField ( fieldName, value ) {
        this.setState({ [fieldName]: value });
    }

    createUsr (e) {

        e.preventDefault();        
        this.setState({ saving: "Creating user..." });

        // Set up the save data 
        let { email_address, first_name, last_name, role} = this.state;

        var thus = this;
        fetch(process.env.REACT_APP_API_BASE + '/users', {
            method: 'post',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                "apiToken": this.props.auth.apiToken,
                "apiKey": this.props.auth.apiKey
            },
            body: 'email_address=' + encodeURIComponent(email_address) + '&first_name=' + first_name + '&last_name=' + last_name + '&role=' + role
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (rslt) {
            if (typeof rslt.rslt != 'undefined' && rslt.rslt === "success") {
                thus.props.closeFunc(null, { content: "The user account has been successfuly created and an invitation has been sent to them.", className: "message success" },true);
            } else {
                thus.setState({ saving: "Create user", errors: rslt, msg: { content: "Sorry but there was an issue creating this user. Please check the errors below and try again.", className: "message" } });
            }
        })
        .catch(function (error) {
            thus.setState({ saving: "Create user", msg: { content: "Sorry but there was an issue creating this user. Please try again.", className: "message" } });
            console.log('Request failed', error);
        });

    }

    render() {

        let { saving, errors, msg,role} = this.state;

        const options = [
            { label: 'Admin', value: 'admin' },
            { label: 'Manager', value: 'manager' },
            {label:'Foreman',value : 'foreman'}
        ];

        return (
            <Fragment>
                <div className='lightbox lightbox-create-user'>
                    <div className='lightbox-content'>
                    <div className='close' onClick={(e) => this.props.closeFunc(e) }>&times;</div>
                        <div className='lightbox-header'>
                            <h4>New User</h4>
                        </div>

                        { msg ? (
                            <div className={ msg.className }>
                                { msg.content }
                            </div>
                        ) :""}

                        <div className='lightbox-section clear'>
                            <Text label='First name' id='first_name' onChange={ this.updateField } errors={ errors } />
                        </div>
                        <div className='lightbox-section'>
                            <Text label='Last name' id='last_name' onChange={this.updateField} errors={errors} />
                        </div>
                        <div className='lightbox-section clear'>
                            <Email label='Email' id='email_address' onChange={this.updateField} errors={errors} />
                        </div>
                        <h4 className='subtitle'>Access Permissions</h4>
                        <div className='lightbox-section'>
                            <Select label='User role' id='role' emptyMsg="Select a role..." value={role} options={options} handleSelect={this.updateField} errors={errors} />
                        </div>             
                        <div className='lightbox-submit'>
                            <SubmitButton text={ saving } handleSubmit={ this.createUsr } />
                        </div>
                    </div>
                </div>
            </Fragment>
        
        );
    }
}


export default withCookies(LightBoxCreateUser);

