import React, { Component, Fragment } from 'react';

class Password extends Component {

  handleInputChange(e, fieldName) {
    if (typeof this.props.onChange != 'undefined') {
      let value = e.target.value;
      this.props.onChange(fieldName, value);
    }
  }

  handleInputBlur(e, fieldName) {
    if (typeof this.props.onBlur != 'undefined') {
      let value = e.target.value;
      this.props.onBlur(fieldName, value);
    }
  }

  render() {

    let {id,label,value} = this.props;
    var fieldName = id;
    if (typeof this.props.fieldName != "undefined") {
      fieldName = this.props.fieldName;
    }

    return (
        <Fragment>
            <div className="input">
              <label htmlFor={id}>{label}</label>
              <input 
                type="password" 
                id={id} 
                name={fieldName } 
                defaultValue={value} 
                onChange={(e) => this.handleInputChange(e, fieldName)} 
                onBlur={ (e) => this.handleInputBlur(e, fieldName) }
              />
              {typeof this.props.errors != 'undefined' && typeof this.props.errors[fieldName] != "undefined" ? (
                <div className="val-error">
                  {this.props.errors[fieldName].map((err, key) =>
                    <p key={'err-' + key}>{err}</p>
                  )}
                </div>
              ) : ""}
            </div>
                       
        </Fragment>
      
    );
  }
}


export default Password;
