import React, { Component, Fragment } from 'react';

import Text from '../common/fields/text.js';


import SubmitButton from '../common/fields/submit-button.js';

import Fetching from './../utilities/fetching';
import { withCookies } from 'react-cookie';


class LightBoxCreateManufacturer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name:'',
            errors:false,
            saving:'Create Manufacturer',
            doRedirect:false
    
        }

        this.updateField = this.updateField.bind(this);
        this.createManufacturer = this.createManufacturer.bind(this);
    }

    updateField(fieldName, value) {
        this.setState({ [fieldName]: value });
    }

    componentDidMount(){
        if(typeof this.props.manufacturer_id !== 'undefined' && this.props.manufacturer_id !== -1){
            this.setState({
                saving:'Update Manufacturer'
            })
            this.loadManufacturer(this.props.manufacturer_id);
        }

    }

    loadManufacturer(manufacturer_id){
        let auth = this.props.cookies.get('ldt-auth');

         var thus = this;
         fetch(process.env.REACT_APP_API_BASE + '/manufacturers/'+manufacturer_id, {
             method: 'get',
             headers: {
                 "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                 "apiToken":auth.apiToken,
                 "apiKey": auth.apiKey
             },
         })
             .then(Fetching.statusCheck)
             .then(Fetching.jsonExtract)
             .then(function (data) {

                 let newState= {
                    name:data.name, 
                    isSubmitDisabled:false,
                 }

                 thus.setState(newState);
             })
             .catch(function (error) {
                 console.log('Request failed', error);
             });
    }

    createManufacturer(e){

  
        e.preventDefault();

        if(typeof this.props.manufacturer_id !== 'undefined' && this.props.manufacturer_id !== -1){
            this.setState({ saving: "Updating Manufacturer..." });
        } else {
            this.setState({ saving: "Creating Manufacturer..." });
        }

        let auth = this.props.cookies.get('ldt-auth');

        // Set up the save data 
        let {name} = this.state ;

        let method = 'post';
        let url = process.env.REACT_APP_API_BASE + '/manufacturers';

        if(typeof this.props.manufacturer_id !== 'undefined' && this.props.manufacturer_id !== -1){
            method = 'put';
            url = process.env.REACT_APP_API_BASE + '/manufacturers/'+this.props.manufacturer_id
        }
        var thus = this;
        fetch(url, {
            method: method,
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                "apiToken": auth.apiToken,
                "apiKey": auth.apiKey
            },
            body: 'name='+name 
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (rslt) {

            if(typeof thus.props.manufacturer_id !== 'undefined' && thus.props.manufacturer_id !== -1){
                if(rslt === true){
                    thus.setState({saving: "Update Manufacturer"} );
                    thus.props.triggerMsg({ content: "The manufacturer has been successfully updated.", className: "message success mbtm" });
                    thus.props.loadManufacturers(auth);
                    thus.props.toggleCreateNewManufacturerLightBoxOpen();
                } else {
                    thus.setState({ saving: "Update Manufacturer", errors: rslt, msg: { content: "Sorry but there was an issue updating the manufacturer. Please check the errors below and try again.", className: "message" } });
                }
                
            } else {
                if(typeof rslt.id !== 'undefined'){
                    thus.setState({saving: "Create Manufacturer"} );
                    thus.props.triggerMsg({ content: "The manufacturer has been successfully created.", className: "message success mbtm" });
                    thus.props.loadManufacturers(auth);
                    thus.props.toggleCreateNewManufacturerLightBoxOpen();
                } else {
                    for(var i in rslt){
                        let key = i.replace('-','_');
                        rslt[key] = rslt[i];
                        if (key !== i) {
                            delete rslt[i];
                        }
                    }
                   
                    thus.setState({ saving: "Create Manufacturer", errors: rslt, msg: { content: "Sorry but there was an issue creating the manufacturer. Please check the errors below and try again.", className: "message" } });
                }
            }
        })
        .catch(function (error) {
            thus.setState({ saving: "Create Booking",  msg: { content: "Sorry, but there was an error on the server.", className: "message" } });
            console.log('Request failed', error);
        });
    }

  render() {

    let {msg,errors,name,saving } = this.state ;

    
    return (
        <Fragment>
            <div className='lightbox lightbox-create-booking'>
                <div className='lightbox-content'>
                <div className='close' onClick={(e) => this.props.toggleCreateNewManufacturerLightBoxOpen(e) }>&times;</div>
                    <div className='lightbox-header'>
                    
                        <h4>{saving}</h4>
                        {msg ? (
                            <div className={msg.className}>
                                {msg.content}
                            </div>
                        ) : ""}
                        
                    </div>                

   
                    <div className='lightbox-section'>
                        <Text label='Manufacturer' id='name' value={ name } onChange={this.updateField} errors={errors}/>
                    </div>

                    <div className='lightbox-submit'>
                        <SubmitButton handleSubmit={this.createManufacturer} text={ saving }/>
                    </div>
                </div>
            </div>
        </Fragment>
      
    );
  }
}


export default withCookies(LightBoxCreateManufacturer) ;

