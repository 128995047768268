import React, { Component, Fragment } from 'react';
import { withCookies } from 'react-cookie';

import Fetching from '../utilities/fetching';

import Select from './fields/select.js';
import SubmitButton from './fields/submit-button.js';
import Loader from './loader';

class LightboxConsolidateWorker extends Component {


    constructor(props) {
        super(props);
        this.state = {
            consolidate_list:[],
            saving: "Consolidate worker",
            msg: false,
            errors: false,
            loading: false,
            user_id_to_merge_to:-1
        }

        this.consolidateWorker = this.consolidateWorker.bind(this);
        this.updateField = this.updateField.bind(this);
    }

    componentDidMount () {
        this.loadDetail( this.props.id );
    }

    // Get the user details 
    loadDetail(id) {

        // Get the user details
        var thus = this;
        fetch(process.env.REACT_APP_API_BASE + '/workers/consolidate_list/' + id, {
            method: 'get',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                "apiToken": this.props.auth.apiToken,
                "apiKey": this.props.auth.apiKey
            },
        })
            .then(Fetching.statusCheck)
            .then(Fetching.jsonExtract)
            .then(function (data) {

                let dataSet = [];

                dataSet = data.data.map((rslt) => {
                    let obj = {};
                    obj.value = rslt.id;
                    obj.name = rslt.name;
                    return obj;
                });

                thus.setState({ 
                    loading: false,
                    consolidate_list: dataSet,
                });
            })
            .catch(function (error) {
                console.log('Request failed', error);
            });

    }

    updateField(fieldName, value) {
        this.setState({ [fieldName]: value });
    }

    consolidateWorker(e) {

        e.preventDefault();

        let nameToMergeTo = '';

        this.state.consolidate_list.forEach((worker) => {
            if(worker.value === parseInt(this.state.user_id_to_merge_to)){
                nameToMergeTo = worker.name;
            }
        })

        let doConsolidate = window.confirm('This will merge the selected worker\'s records into '+nameToMergeTo+' and cannot be undone. Do you wish to proceed?' )
        
        if(!doConsolidate){
            return false;
        }
        this.setState({ saving: "Consolidating worker..." });

        let auth = this.props.cookies.get('ldt-auth');

        var thus = this;
        fetch(process.env.REACT_APP_API_BASE + '/workers/consolidate_worker', {
            method: 'post',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                "apiToken": auth.apiToken,
                "apiKey": auth.apiKey
            },
            body: 'correct_worker_id=' + this.state.user_id_to_merge_to + '&worker_to_merge_id='+this.props.id
        })
            .then(Fetching.statusCheck)
            .then(Fetching.jsonExtract)
            .then(function (rslt) {
                console.log(rslt);
                if (typeof rslt.rslt != 'undefined' && rslt.rslt === "success") {
                    thus.props.closeFunc(null, { content: "The worker record has been successfuly updated.", className: "message success" },undefined,true);
                } else {
                    thus.setState({ saving: "Update user", errors: rslt, msg: { content: "Sorry but there was an issue updating this worker record. Please check the errors below and try again.", className: "message" } });
                }
            })
            .catch(function (error) {
                console.log('Request failed', error);
            });

    }

    render() {

        let { saving, msg, loading,consolidate_list,user_id_to_merge_to} = this.state;


        return (
            <Fragment>
                <div className='lightbox lightbox-create-user'>
                    <div className='lightbox-content'>
                        <div className='close' onClick={(e) => this.props.closeFunc(e)}>&times;</div>
                        <div className='lightbox-header'>
                            <h4>Consolidate Worker</h4>
                        </div>

                        {msg ? (
                            <div className={msg.className}>
                                {msg.content}
                            </div>
                        ) : ""}

                        {loading ? (
                            <Loader bg="white" mrgn="mrgn-lrg" />
                        ) : (
                            <Fragment>
                                <div className='lightbox-section clear'>
                                    {consolidate_list.length > 0 ? <Select options={consolidate_list} value={user_id_to_merge_to} id='user_id_to_merge_to' handleSelect={this.updateField} /> : null}
                                    {/* <Text label='Name' id='name' value={name } onChange={this.updateField} errors={errors} /> */}
                                </div>
                                <div className='lightbox-submit'>
                                    <SubmitButton text={saving} handleSubmit={this.consolidateWorker} />
                                </div>
                            </Fragment>
                        )}
                    </div>
                </div>
            </Fragment>

        );
    }
}


export default withCookies(LightboxConsolidateWorker);

