import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import logo from './../../assets/img/hardgate-logo.png';

// import { withCookies } from 'react-cookie';
class Header extends Component {

    constructor(props){
        super(props);
        this.state = {
            isMobileNavigationOpen:false
        }

        this.toggleMobileDropdownOpen = this.toggleMobileDropdownOpen.bind(this);
    }

    toggleMobileDropdownOpen(e){
        if(typeof e !== 'undefined'){
            e.preventDefault();
        }
        
        this.setState(prevState => ({
            isMobileNavigationOpen: !prevState.isMobileNavigationOpen
        }));
    }

  render() {
    
    var displayClass = '';
    var headerAddn = '';

    if(this.state.isMobileNavigationOpen){
        displayClass = 'hamburger-dropdown-visible';
        headerAddn = " mobile-open";
    }
    if (typeof this.props.isPrint != "undefined" && this.props.isPrint) {
        headerAddn = " print";
    }

    return (
        <Fragment>
            <header className={ "main" + headerAddn }>
                
                <div className="content" >
                    <div className="width-2 logo-container">
                        <div className="logo">
                            <img src={logo} className="centred" alt="Hardgate logo" />
                        </div>
                        { typeof this.props.isPrint != "undefined" && this.props.isPrint ? "" : (
                            <div className="mobile-navigation" onClick={(e) => this.toggleMobileDropdownOpen(e)}>
                                <div className='line'></div>
                                <div className='line'></div>
                                <div className='line'></div>
                            </div>
                        )}
                    </div>

                    {typeof this.props.isPrint != "undefined" && this.props.isPrint ? "" : (
                        <nav className="width-10 desktop-navigation" >
                            <ul>
                                <li><Link className="dashboard" to="/admin/dashboard">Dashboard</Link></li>
                                <li><Link className="projects" to="/admin/projects">Projects</Link></li>
                                <li><Link className="datasheet" to="/admin/report-navigator">Reports</Link></li>
                                <li><Link className="toolbox" to="/admin/toolbox-talks">Toolbox Talks</Link></li>
                                <li><Link className="datasheet" to="/admin/datasheets">Data sheets</Link></li>
                                <li><Link className="datasheet" to="/admin/manufacturers">Manufacturers</Link></li>
                                <li><Link className="users" to="/admin/manage-users">Users</Link></li>
                                <li><Link className="users" to="/admin/manage-workers">Workers</Link></li>
                                <li><Link className="settings" to="/admin/settings">Settings</Link></li>
                                <li className="logout"><Link to="/admin/logout">Sign out</Link></li>
                            </ul>
                        </nav>
                    )}
                </div>

            </header> 

            {typeof this.props.isPrint != "undefined" && this.props.isPrint ? "" : (
                <div className={"hamburger-content "+  displayClass} >
                    <nav className="hamburger-content-nav">
                        <ul>
                            <li><Link className="bookings" to="/admin/dashboard">Dashboard</Link></li>
                            <li><Link className="projects" to="/admin/projects">Projects</Link></li>
                            <li><Link  className="toolbox-talks" to="/admin/toolbox-talks">Toolbox Talks</Link></li>
                            <li><Link  className="datasheets" to="/admin/datasheets">Data sheets</Link></li>
                            <li><Link  className="users" to="/admin/manage-users">Users</Link></li>
                            <li><Link className="users" to="/admin/manage-workers">Workers</Link></li>
                            <li><Link  className="settings" to="/admin/settings">Settings</Link></li>
                            <li className="logout"><Link to="/admin/logout">Sign out</Link></li>
                        </ul>
                    </nav>
                </div>
            )}
        </Fragment>
      
    );
  }
}

export default Header;

// export default withCookies(Header);
