import React, { Component } from 'react';

class Loader extends Component {

    render () {

        return (
            <span className={ "loader " + this.props.bg + " " + this.props.mrgn }>
                <div className="folding-cube">
                    <div className="cube1 cube"></div>
                    <div className="cube2 cube"></div>
                    <div className="cube4 cube"></div>
                    <div className="cube3 cube"></div>
                </div>
            </span>
        );

    }
}

export default Loader;