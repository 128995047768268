import React, { Component } from 'react';
import moment from 'moment'
import { Link } from 'react-router-dom';

class ReportItem extends Component {


  render() {

    if(typeof this.props.creator === 'undefined'){
      return null;
    }

    let viewBtn = null;
    if(typeof this.props.forReview != 'undefined'  && this.props.forReview === true ){
      viewBtn = <Link className='btn right' to={'/admin/report/'+this.props.id}>View</Link>
    }

    return (

        <div className='panel-list-item'>
            {viewBtn}
            <h3>{ this.props.project === null ? "" : this.props.project.title} - {this.props.report_type.title}</h3>
            <p>Foreman: {this.props.creator.first_name + ' '+ this.props.creator.last_name}</p>
            <p className="detail">Date: { moment(new Date(this.props.submission_date)).format("DD/MM/YYYY")}</p>
          
        </div>


    );
  }
}

export default ReportItem;
