import React, { Component, Fragment } from 'react';

import BasicFieldEntry from '../field-entry-types/basic-field-entry.js'
import YesNoCommentEntry from '../field-entry-types/yes-no-comment-entry.js';
import NoYesCommentEntry from '../field-entry-types/no-yes-comment-entry.js';
import YesNoEntry from '../field-entry-types/yes-no-entry.js';
import CbGroup from '../field-entry-types/cb-group'

class Standard extends Component {

	render() {

		return (
			<div>
				{this.props.grp.title != null && this.props.grp.title !== "" ? (
					<p className="section-group-title">{this.props.grp.title}</p>
				) : ("")}
				<div>
					{this.props.grp.report_fields.map((field, i) =>
						<Fragment key={i}>
							{
								{
									SelectComment: 	<BasicFieldEntry fieldType={ field.field_type } label={ field.label } fieldEntries={ field.report_field_entries } field={ field } />,
									Select: <BasicFieldEntry fieldType={field.field_type} label={field.label} fieldEntries={field.report_field_entries} field={ field } />,
									CbGroup: 		<CbGroup fieldType={ field.field_type } label={ field.label } fieldEntries={ field.report_field_entries } isPrint={ this.props.isPrint } />,
									NoYesComment: 	<NoYesCommentEntry fieldType={field.field_type} label={field.label} fieldEntries={field.report_field_entries} />,
									YesNoComment: 	<YesNoCommentEntry fieldType={ field.field_type } label={ field.label } fieldEntries={ field.report_field_entries } />,
									YesNoNa: 		<BasicFieldEntry fieldType={ field.field_type } label={ field.label } fieldEntries={ field.report_field_entries } field={ field } />,
									YesNo: 			<YesNoEntry fieldType={ field.field_type } label={ field.label } fieldEntries={ field.report_field_entries } />,
									YesNoImg: 		<YesNoEntry fieldType={ field.field_type } placeholder={ field.placeholder } isImage={ true } label={ field.label } fieldEntries={ field.report_field_entries } />,
									Number: 		<BasicFieldEntry fieldType={ field.field_type } label={ field.label } fieldEntries={ field.report_field_entries } />,
									Temperature: 	<BasicFieldEntry fieldType={ field.field_type } label={ field.label } fieldEntries={ field.report_field_entries } post={ "°" } />,
									Auto: 			<BasicFieldEntry fieldType={ field.field_type } label={ field.label } fieldEntries={ field.report_field_entries } />,
									Text: 			<BasicFieldEntry fieldType={ field.field_type } label={ field.label } fieldEntries={ field.report_field_entries } />,
									TextArea: 		<BasicFieldEntry fieldType={ field.field_type } label={ field.label } fieldEntries={ field.report_field_entries } />,
									TextAreaOpen: 	<BasicFieldEntry fieldType={ field.field_type } label={ field.label } fieldEntries={ field.report_field_entries } />,
									Users: 			<BasicFieldEntry fieldType={ field.field_type } label={ field.label } fieldEntries={ field.report_field_entries } />,
									Time: 			<BasicFieldEntry fieldType={ field.field_type } label={ field.label } fieldEntries={ field.report_field_entries } />,
									Date: 			<BasicFieldEntry fieldType={ field.field_type } label={ field.label } fieldEntries={ field.report_field_entries } field={ field } />,
									Worker: 		<BasicFieldEntry fieldType={ field.field_type } label={ field.label } fieldEntries={ field.report_field_entries } />,
								}[field.field_type]
							}
						</Fragment>
					)}
				</div>
			</div>
		);
	}

}

export default Standard;

