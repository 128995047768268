import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Email from '../common/fields/email.js';
import SubmitButton from '../common/fields/submit-button.js'
import Footer from '../core/footer.js'
import LoginHeader from './login-header.js'

import './../../assets/css/login.css';

import AuthenticateErrorMessage from '../common/flash-messages/authenticate-error-message.js';
import AuthenticateSuccessMessage from '../common/flash-messages/authenticate-success-message.js';
import Fetching from '../utilities/fetching.js';


class ForgottenPassword extends Component {

    constructor(props){
        super(props);
        this.state = {
            email:'',
            flashMessage: '',
            flashMessageState:null,
            btnTxt: "Reset Password"
        }

        this.handleForgottenPasswordRequest = this.handleForgottenPasswordRequest.bind(this);
        this.handleDataEntry  = this.handleDataEntry.bind(this);

    }

    componentDidMount(props){
        if(typeof this.props.location !== 'undefined' && typeof this.props.location.state !== 'undefined'){
            if(typeof this.props.location.state.redirectState !== 'undefined' && this.props.location.state.redirectState.length > 0){
                this.setState({flashMessage:this.props.location.state.message,flashMessageState:this.props.location.state.redirectState});
            }
        }
    }

    handleForgottenPasswordRequest(e){
        e.preventDefault();

        this.setState({ btnTxt: "Resetting password..." });

		let { email } = this.state;
        let thus = this;


		// Encode the email and password for submission
        email = encodeURIComponent(email);

        fetch(process.env.REACT_APP_API_BASE+'/pw/forgotten', {
            method: 'post',
            headers: { "Content-type": "application/x-www-form-urlencoded; charset=UTF-8" },
            body: 'email_address='+ email 
          })
          .then(Fetching.statusCheck)
          .then(Fetching.jsonExtract)
          .then(function (data) {
                if(data === true){
                    thus.setState({flashMessage:'An email has been sent with information to change the password to the below address.  Please check your inbox ',flashMessageState:'success' });
                } 
                thus.setState({ btnTxt: "Reset password" });
          })
          .catch(function (error) {
            console.log('Request failed', error);
              thus.setState({ flashMessage: 'That email address could not be found.', flashMessageState: 'error', btnTxt: "Reset password" });
          });

    }

    // Store entered data to state, ready for login
	handleDataEntry ( fieldName, value ) {
		this.setState({ [ fieldName ]: value });
	}

  render() {

    let { btnTxt } = this.state;

    let flashMessage = null;

    if( this.state.flashMessageState === 'error'){
        flashMessage = <AuthenticateErrorMessage message={this.state.flashMessage}/>
    } else if (this.state.flashMessageState === 'success'){
        flashMessage = <AuthenticateSuccessMessage message={this.state.flashMessage}/>
    }

    return (
        <Fragment>
            <div className='content'>
                <div className='width-4 centred white-bg login-form'>
                    <LoginHeader/>
                    {flashMessage}
                    <form>
                        <Email id='email' label='Email Address' value={this.state.email} onChange={ this.handleDataEntry } />
                        <Link to="/" className="right lnk sml">Return to login</Link>
                        <SubmitButton handleSubmit={(e) => this.handleForgottenPasswordRequest(e)} text={ btnTxt } />
                    </form>

                </div>
            </div>

            <Footer/>
        </Fragment>
      
    );
  }
}

// var styles = {
  
// };


export default ForgottenPassword;
