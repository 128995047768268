import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

class PaginatorLinks extends Component {

  deliverFunc (e, newPageNo) {
    if (typeof this.props.actionFunc == "function") {
      var thus = this;
      setTimeout(function(){
        thus.props.actionFunc(true);
      }, this.props.delay);
    } else if (typeof this.props.switchFunc == "function") {
      e.preventDefault();
      this.props.switchFunc(e, newPageNo);
    }
  }

  render() {

    var pages = [];
    for ( var i = 1; i <= this.props.pages; i++ ) {
      pages.push(i);
    }

    return (
        <Fragment>
             <ul className="paging right">
                { this.props.current > 1 ? (
                  <Fragment>
                    <li><Link to={this.props.baseUrl} onClick={ (e) => this.deliverFunc(e, 1) }>&lt;&lt;</Link></li>
                    <li><Link to={this.props.baseUrl + "?page=" + (this.props.current - 1)} onClick={(e) => this.deliverFunc(e, this.props.current - 1)}>&lt;</Link></li>
                  </Fragment>
                ) : "" }
                { pages.map ( pageNo => 
                  <Fragment key={ "page-" + pageNo }>
                    { pageNo === this.props.current ? (
                      <li>{pageNo}</li>
                    ) : (
                        <li><Link to={this.props.baseUrl + "?page=" + pageNo} onClick={(e) => this.deliverFunc(e, pageNo)}>{pageNo}</Link></li>
                    )}
                  </Fragment>
                )}
                {this.props.current < this.props.pages ? (
                  <Fragment>
                    <li><Link to={this.props.baseUrl + "?page=" + (this.props.current + 1)} onClick={(e) => this.deliverFunc(e, this.props.current + 1)}>&gt;</Link></li>
                    <li><Link to={this.props.baseUrl + "?page=" + this.props.pages} onClick={(e) => this.deliverFunc(e, this.props.pages)}>&gt;&gt;</Link></li>
                  </Fragment>
                ) : ""}
            </ul>
            <p className="paging">Page { this.props.current } of { this.props.pages }</p>
                       
        </Fragment>
      
    );
  }
}


export default PaginatorLinks;
