import React, { Component, Fragment } from 'react';

import Header from '../core/header.js'
import Footer from '../core/footer.js'

import MainTitle from '../common/titles/main-title.js';

import ProjectTable from './project-table.js'


import LightBoxEditProject from './lightbox-edit-project.js'
import Fetching from '../utilities/fetching.js';

import { withCookies } from 'react-cookie';

class Project extends Component {

  constructor(props) {
    super(props);

    this.state = {
        isEditProjectLightBoxOpen:false,
        msg:false,
        loading:true,
        auth:{},
        projects:[],
        total:-1,
        page:1,
        project_id:-1,
        isEdit:false,
        managerOptionValues:[],
        foremanOptionValues:[]
    }

    this.toggleEditProjectLightBoxOpen = this.toggleEditProjectLightBoxOpen.bind(this);
    this.updateMsg = this.updateMsg.bind(this);
    this.loadProjects = this.loadProjects.bind(this);
    this.togglePublishStatus = this.togglePublishStatus.bind(this);
    this.deleteProject = this.deleteProject.bind(this);
  }

  componentDidMount(){
    this.loadUsers();
    this.loadProjects();
  }

  loadUsers ( displayLoader, usrAuth ) {

    // If it is this type of query display the loader 
    if (typeof displayLoader != 'undefined') {
        this.setState({ loading: true });
    }

    // Get the auth details of the user
    if ( typeof usrAuth == "undefined" ) {
        let { auth } = this.state;
        usrAuth = auth;
    }

    let auth = this.props.cookies.get('ldt-auth');

    // Get the users
    var thus = this;
    fetch(process.env.REACT_APP_API_BASE + '/users', {
        method: 'get',
        headers: { 
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
            "apiToken": auth.apiToken,
            "apiKey": auth.apiKey
        },
    })
    .then(Fetching.statusCheck)
    .then(Fetching.jsonExtract)
    .then(function (data) {

        let managerOptionValues = [];
        let foremanOptionValues = [];

        if(data.rslts.length > 0){

            for(let i =0 ; i < data.rslts.length;i++){

                let user = data.rslts[i];

                let userOption = {
                    label:user.first_name +' ' + user.last_name,
                    value:user.public_id
                }

                if(user.role === 'manager'){
                    managerOptionValues.push(userOption);
                }

                if(user.role === 'foreman' || user.role === 'manager'){
                    foremanOptionValues.push(userOption);
                }
            }

        }


        thus.setState({ managerOptionValues,foremanOptionValues,});
    })
    .catch(function (error) {
        console.log('Request failed', error);
    });
  }

  loadProjects(usrAuth){
    // If it is this type of query display the loader 
    if (typeof displayLoader != 'undefined') {
      this.setState({ loading: true });
  }

  // Work out whether a page is mentioned 
  var qsParams = this.props.location.search.replace("?", "");
  qsParams = qsParams.split("&");
  var page = false;
  for (var j in qsParams) {
      if (qsParams[j].indexOf("page=") > -1) {
          page = qsParams[j].replace("page=", "");
      }
  }

  if ( !page ) page = 1;
  page = Number(page);


  usrAuth = this.props.cookies.get('ldt-auth');

  // Get the users
  var thus = this;
  fetch(process.env.REACT_APP_API_BASE + '/projects?page=' + page, {
      method: 'get',
      headers: { 
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          "apiToken": usrAuth.apiToken,
          "apiKey": usrAuth.apiKey,
      },
  })
  .then(Fetching.statusCheck)
  .then(Fetching.jsonExtract)
  .then(function (data) {
    if(data.length === 0){
      thus.setState({  loading: false });

    } else {
      thus.setState({ projects: data.rslts,pages: data.pages, total: data.total, current: data.current, loading: false });

    }
  })   
  .catch(function (error) {
      thus.setState({loading: false });
      console.log('Request failed', error);
  });
}

togglePublishStatus(e,project_id,published){

  e.preventDefault();
  // If it is this type of query display the loader 
  if (typeof displayLoader != 'undefined') {
    this.setState({ loading: true });
  }


  let usrAuth = this.props.cookies.get('ldt-auth');
  
  let new_published = (published === 1) ? 0 : 1 ;

  // Get the users
  var thus = this;
  fetch(process.env.REACT_APP_API_BASE + '/documents/toggle_published', {
      method: 'post',
      headers: { 
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          "apiToken": usrAuth.apiToken,
          "apiKey": usrAuth.apiKey,
      },
      body:'id='+project_id+'&published='+new_published
  })
  .then(Fetching.statusCheck)
  .then(Fetching.jsonExtract)
  .then(function (data) {
       thus.loadProjects();
       thus.setState({msg:false})
  })
  .catch(function (error) {
      console.log('Request failed', error);
  });
}

deleteProject(e,project_id){

   let doDelete = window.confirm('Do you wish to delete this project?  This cannot be undone');

   if(!doDelete){
     return false;
   }

  e.preventDefault();
  // If it is this type of query display the loader 
  if (typeof displayLoader != 'undefined') {
    this.setState({ loading: true });
  }


  let usrAuth = this.props.cookies.get('ldt-auth');
 

  // Get the users
  var thus = this;
  fetch(process.env.REACT_APP_API_BASE + '/projects/'+project_id, {
      method: 'delete',
      headers: { 
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          "apiToken": usrAuth.apiToken,
          "apiKey": usrAuth.apiKey,
      },
  })
  .then(Fetching.statusCheck)
  .then(Fetching.jsonExtract)
  .then(function (data) {
       thus.loadProjects();
       thus.setState({msg:false})
  })
  .catch(function (error) {
      console.log('Request failed', error);
  });
}

  updateMsg ( value ) {
    console.log(value);
    this.setState({ msg: value });
}


toggleEditProjectLightBoxOpen(e,project_id){

  e.preventDefault();

  let new_project_id = -1;

    if(typeof project_id !== 'undefined'){
      new_project_id = project_id
    }

    let msg= this.state.msg;

    if(!this.state.isEditProjectLightBoxOpen){
      msg = false
    }

  this.setState(prevState => ({
    isEditProjectLightBoxOpen: !prevState.isEditProjectLightBoxOpen,
      project_id:new_project_id,
      isEdit:true,
      msg:msg
  }));
}


toggleCreateNewProjectLightBoxOpen(e){

  e.preventDefault();


  this.setState(prevState => ({
    isEditProjectLightBoxOpen: !prevState.isEditProjectLightBoxOpen,
      msg: false,
      isEdit:false
  }));
}

  render() {

    let {msg,projects,isEdit,foremanOptionValues} = this.state;

    let statusMap = [
      {
        name:'Active',
        value:'active'
      },
      {
        name:'Complete',
        value:'complete'
      },

      {
        name:'Cancelled',
        value:'cancelled'
      },
    ]

    let lightBoxEditProject = null;

    if(this.state.isEditProjectLightBoxOpen){
        lightBoxEditProject = <LightBoxEditProject statusMap={statusMap} triggerMsg={this.updateMsg} isEdit={isEdit} loadProjects={this.loadProjects}  project_id={this.state.project_id} toggleEditProjectLightBoxOpen={this.toggleEditProjectLightBoxOpen} closeFunc={this.toggleEditProjectLightBoxOpen} />
    }
  
  
    return (

        <Fragment>
            {lightBoxEditProject}
            <Header/>
            <section className="content">
                <div className="width-12">
                  
                  <button className="right" style={{marginRight:'3px'}} onClick={(e) => this.toggleCreateNewProjectLightBoxOpen(e)}>New Project</button>
                     
                  < MainTitle text='Manage Projects'/>

                  {msg ? (
                        <div className={msg.className}>
                            {msg.content}
                        </div>
                    ) : ""}
                </div>

                <ProjectTable foremanOptionValues={foremanOptionValues} deleteProject={this.deleteProject} loadProjects={this.loadProjects} togglePublishStatus={this.togglePublishStatus}  projects={projects} toggleEditProjectLightBoxOpen={this.toggleEditProjectLightBoxOpen} {...this.state}/>
                
            </section>
            <Footer/>
        </Fragment>
    );
  }
}

export default withCookies(Project);