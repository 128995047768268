import React, { Component, Fragment } from 'react';

import Text from '../common/fields/text.js';
import TextArea from '../common/fields/textarea.js';

import SubmitButton from '../common/fields/submit-button.js';
import Upload from '../common/fields/upload.js';
import Fetching from '../utilities/fetching';

import { withCookies } from 'react-cookie';


class LightBoxEditToolbox extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title:'',
            short_description:'',
            errors:false,
            saving:'Create Toolbox Talk',
            doRedirect:false,
            isSubmitDisabled:true,
            document_id:-1,
    
        }

        this.updateField = this.updateField.bind(this);
        this.createToolbox = this.createToolbox.bind(this);
        this.enableSubmitButton = this.enableSubmitButton.bind(this);
        this.loadToolbox = this.loadToolbox.bind(this);
    }

    componentDidMount(){
        if(typeof this.props.document_id !== 'undefined' && this.props.document_id !== -1){
            this.setState({
                saving:'Update Toolbox Talk'
            })
            this.loadToolbox(this.props.document_id);
        }
    }

    updateField(fieldName, value) {
        this.setState({ [fieldName]: value });
    }

    enableSubmitButton(document_id){
        this.setState({
            isSubmitDisabled:false,
            document_id:document_id
        })
    }

    loadToolbox(document_id){
        let auth = this.props.cookies.get('ldt-auth');

         var thus = this;
         fetch(process.env.REACT_APP_API_BASE + '/documents/'+document_id, {
             method: 'get',
             headers: {
                 "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                 "apiToken":auth.apiToken,
                 "apiKey": auth.apiKey
             },
         })
             .then(Fetching.statusCheck)
             .then(Fetching.jsonExtract)
             .then(function (data) {
                 let newState= {
                    short_description:data.description, 
                    title:data.title,
                    displayFilename:data.filename,
                    isSubmitDisabled:false,
                    document_id:data.id
                 }

                 thus.setState(newState);
             })
             .catch(function (error) {
                 console.log('Request failed', error);
             });
    }

    createToolbox(e){

  
        e.preventDefault();

        let savingMessage = "Creating Toolbox Talk";

        if(this.props.isEdit){
            savingMessage = "Updating Toolbox Talk";
        } 

        this.setState({ saving: savingMessage });

        let auth = this.props.cookies.get('ldt-auth');

        // Set up the save data 
        let {short_description, title,document_id } = this.state ;

        let bodyString = 'id='+document_id+'&file_type=talk&title='+title+'&description='+short_description

        var thus = this;
        fetch(process.env.REACT_APP_API_BASE + '/documents/save_details', {
            method: 'post',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                "apiToken": auth.apiToken,
                "apiKey": auth.apiKey
            },
            body: bodyString
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (rslt) {
            if(rslt === true){
                let savingMessage = "Create Toolbox Talk";
                let contentMessage = "The Toolbox Talk has been successfully created.";

                if(thus.props.isEdit){
                    savingMessage = "Update Toolbox";
                    contentMessage = "The Toolbox Talk has been successfully updated.";
                } 
                thus.setState({saving:  savingMessage} );

                thus.props.loadToolboxes();
                thus.props.triggerMsg({ content: contentMessage, className: "message success mbtm" });
                thus.props.toggleEditToolboxLightBoxOpen(e);

            } else {
                for(var i in rslt){
                    let key = i.replace('-','_');
                    rslt[key] = rslt[i];
                    if (key !== i) {
                        delete rslt[i];
                    }
                }
                let savingMessage = "Create Toolbox Talk";

                if(thus.props.isEdit){
                    savingMessage = "Update Toolbox Talk";
                } 
                thus.setState({ saving: savingMessage, errors: rslt, msg: { content: "Sorry but there was an issue updating this Toolbox Talk. Please check the errors below and try again.", className: "message" } });
            }
        })
        .catch(function (error) {

            let savingMessage = "Create Toolbox Talk";

            if(thus.props.isEdit){
                savingMessage = "Update Toolbox Talk";
            } 
            thus.setState({ saving: savingMessage,  msg: { content: "Sorry, but there was an error on the server.", className: "message" } });
            console.log('Request failed', error);
        });
    }

  render() {

    let {msg,errors,short_description, title,saving,displayFilename } = this.state ;

    
    return (
        <Fragment>
            <div className='lightbox lightbox-create-toolbox'>
                <div className='lightbox-content'>
                <div className='close' onClick={(e) => this.props.toggleEditToolboxLightBoxOpen(e) }>&times;</div>
                    <div className='lightbox-header'>
                    
                        <h4>{this.props.isEdit ? 'Edit Toolbox Talk' : 'Create Toolbox Talk'}</h4>
                        {msg ? (
                            <div className={msg.className}>
                                {msg.content}
                            </div>
                        ) : ""}
                        
                    </div>                

                    <div className='lightbox-section'>
                        <Text label='Document Title' id='title' value={ title } onChange={this.updateField} errors={errors}/>
                    </div>

                    <div className='lightbox-section'>
                        <TextArea label='Short Description' id='short_description' value={ short_description } onChange={this.updateField} errors={errors}/>
                    </div>

                    <div className='lightbox-section'>
                        <Upload fileName={displayFilename} document_id={this.props.document_id} file_type='datasheet' callbackFunc={this.enableSubmitButton}/>
                    </div>

                    <div className='lightbox-submit'>
                        <SubmitButton isDisabled={this.state.isSubmitDisabled} handleSubmit={this.createToolbox} text={ saving }/>
                    </div>
                </div>
            </div>
        </Fragment>
      
    );
  }
}


export default withCookies(LightBoxEditToolbox);

