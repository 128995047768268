import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';


// import { connect } from 'react-redux';
// import { authenticate } from "./../../actions/authActions";

import Email from '../common/fields/email.js';
import Password from '../common/fields/password.js'
import SubmitButton from '../common/fields/submit-button.js'
import Footer from '../core/footer.js'
import LoginHeader from './login-header.js';
import Fetching from './../utilities/fetching';

import RedirectWithFlashMessage from '../common/flash-messages/redirect-with-flash-message.js'
import AuthenticateErrorMessage from '../common/flash-messages/authenticate-error-message.js';
import AuthenticateSuccessMessage from '../common/flash-messages/authenticate-success-message.js';

import { withCookies } from 'react-cookie';

import './../../assets/css/login.css';


class Login extends Component {


    constructor(props){
        super(props);

        const { cookies } = props;

        this.state = {
            doRedirect:false,
            redirectUrl:'/admin/dashboard',
            redirectMessage:'',
            redirectState:false,
            email:'',
            password:'',
            auth: cookies.get('auth'),
            flashMessage: '',
            flashMessageState:null,
            btnTxt: "Sign in"

        }

        this.handleLogin = this.handleLogin.bind(this);
        this.handleDataEntry = this.handleDataEntry.bind(this);
    }

    componentDidMount(props){
        if(typeof this.props.location !== 'undefined' && typeof this.props.location.state !== 'undefined'){
            if(typeof this.props.location.state.redirectState !== 'undefined' && this.props.location.state.redirectState.length > 0){
                this.setState({flashMessage:this.props.location.state.message,flashMessageState:this.props.location.state.redirectState});
            }
        }
    }

    handleLogin(e){
        e.preventDefault();

        // Set the button text to something informative
        this.setState({ btnTxt: 'Signing in...' });

		let { email, password } = this.state;
        let thus = this;
        let {cookies} =this.props;

        //clear existing messages
        thus.setState({flashMessage:'',flashMessageState:''});

		// Encode the email and password for submission
        email = encodeURIComponent(email);
        password = encodeURIComponent(password);

        fetch(process.env.REACT_APP_API_BASE+'/login', {
            method: 'post',
            headers: { "Content-type": "application/x-www-form-urlencoded; charset=UTF-8" },
            body: 'email_address='+ email +'&password='+ password 
          })
          .then(Fetching.statusCheck)
          .then(Fetching.jsonExtract)
          .then(function (data) {
            if(typeof data.apiKey != 'undefined' && data.apiKey.length > 0 && typeof data.apiToken != 'undefined' && data.apiToken.length > 0){
                // thus.props.dispatch(authenticate(data));
                cookies.set('ldt-auth', data, { path: '/' });
                thus.setState({doRedirect:true,redirectUrl: thus.state.redirectUrl });
            } else {
                thus.setState({flashMessage:data.error,flashMessageState:'error' });
            }
            thus.setState({ btnTxt: 'Sign in' });
          })
          .catch(function (error) {
            console.log('Request failed', error);
            thus.setState({doRedirect:true,redirectUrl: '/', });
            thus.setState({ btnTxt: 'Sign in' });
          });

    }

    // Store entered data to state, ready for login
	handleDataEntry ( fieldName, value ) {
		// let field = fieldName.replace('login-', '');
        this.setState({ [fieldName ]: value });
	}

  render() {

    let { email, password, btnTxt } = this.state;

    if(this.state.doRedirect){
        return  <RedirectWithFlashMessage to={this.state.redirectUrl} redirectState ={this.state.redirectState} message={this.state.redirectMessage}  />
    }

    let flashMessage = null;

    if( this.state.flashMessageState === 'error'){
        flashMessage = <AuthenticateErrorMessage message={this.state.flashMessage}/>
    } else if (this.state.flashMessageState === 'success'){
        flashMessage = <AuthenticateSuccessMessage message={this.state.flashMessage}/>
    }

    return (
        <Fragment>
            <div className='content'>
                <div className='width-4 centred white-bg login-form'>
                    <LoginHeader/>
                    {flashMessage}
                    <form>
                        <Email id='email' label='Email Address' value={ email } onChange={this.handleDataEntry } />
                        <Password id='password' label='Password' value={password} onChange={ this.handleDataEntry } />
                        <SubmitButton to="/listing" handleSubmit={(e) => this.handleLogin(e)} text={ btnTxt } />
                        <Link to="/forgotten-password" className="right lnk sml">Forgotten your password</Link>
                    </form>

                </div>
            </div>

            <Footer/>
        </Fragment>
      
    );
  }
}

// export default Login;

// var styles = {
  
// };

  
  export default withCookies(Login);
