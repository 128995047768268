import React, { Component, Fragment } from 'react';
import logo from './../../assets/img/hardgate-logo.png';

class HeaderExternal extends Component {

    constructor(props){
        super(props);
        this.state = {
            isMobileNavigationOpen:false
        }

        this.toggleMobileDropdownOpen = this.toggleMobileDropdownOpen.bind(this);
    }

    toggleMobileDropdownOpen(e){
        if(typeof e !== 'undefined'){
            e.preventDefault();
        }
        
        this.setState(prevState => ({
            isMobileNavigationOpen: !prevState.isMobileNavigationOpen
        }));
    }

  render() {
    


    return (
        <Fragment>
            <header className={ this.props.isPrint ? "main print" : "main" }>
                
                <div className="content" >
                    <div className="width-2 logo-container">
                        <div className="logo">
                            <img src={logo} className="centred" alt="Hardgate logo" />
                        </div>
                       
                    </div>
                    
                   
                </div>

            </header> 

        </Fragment>
      
    );
  }
}

export default HeaderExternal;

// export default withCookies(Header);
