import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { withCookies } from 'react-cookie';
// import useQueryString from './../../utilities/use-query-string';

import Fetching from '../utilities/fetching';

import Header from '../core/header.js'
import Footer from '../core/footer.js'
import Loader from '../common/loader';
import PaginatorLinks from '../common/paginator/paginator-links.js';

import MainTitle from '../common/titles/main-title.js';

import LightboxUpdateWorker from '../common/lightbox-update-worker';
import LightboxConsolidateWorker from '../common/lightbox-consolidate-worker';


class ManageWorkers extends Component {

    constructor(props){
        super(props);
        this.state = {
            openUpdate : false,
            openCreate : false,
            openConsolidate:false,
            loading: false,
            workers: [],
            pages: 0,
            total: 0,
            current: 0,
            msg: false,
            displayUsr: false,
            editUsr: false,
            auth: false
        }

        this.loadWorkers = this.loadWorkers.bind(this);
        this.closeDetails = this.closeDetails.bind(this);
        this.toggleUpdateLightbox = this.toggleUpdateLightbox.bind(this);
        this.toggleConsolidateLightbox = this.toggleConsolidateLightbox.bind(this);
    }

    toggleUpdateLightbox(e, msgUpdate, id,reloadData){
        if (e !== null) {
            e.preventDefault();
        }
        if (typeof msgUpdate == "undefined") msgUpdate = false;

        if(reloadData){
            this.loadWorkers(true, this.state.auth);
        }

        this.setState(prevState => ({
            openUpdate: !prevState.openUpdate,
            msg: msgUpdate,
            editUsr: id
        }));
    }

    toggleConsolidateLightbox(e, msgUpdate,id,reloadData){
        if (e !== null){
            e.preventDefault();
        }

        if(reloadData){
            this.loadWorkers(true, this.state.auth);
        }
        
        if (typeof msgUpdate == "undefined") msgUpdate = false;
        this.setState(prevState => ({
            openConsolidate: !prevState.openConsolidate,
            msg: msgUpdate,
            editUsr: id
        }));
    }

    componentDidMount () {
        // Get the auth cookie data 
        let auth = this.props.cookies.get('ldt-auth');
        this.setState({ auth: auth });
        this.loadWorkers(true, auth);
    }

    // Load up the users into the component 
    loadWorkers ( displayLoader, workerAuth ) {

        // If it is this type of query display the loader 
        if (typeof displayLoader != 'undefined') {
            this.setState({ loading: true });
        }

        // Work out whether a page is mentioned 
        var qsParams = this.props.location.search.replace("?", "");
        qsParams = qsParams.split("&");
        var page = false;
        for (var j in qsParams) {
            if (qsParams[j].indexOf("page=") > -1) {
                page = qsParams[j].replace("page=", "");
            }
        }

        if ( !page ) page = 1;
        page = Number(page);

        // Get the auth details of the user
        if ( typeof workerAuth == "undefined" ) {
            let { auth } = this.state;
            workerAuth = auth;
        }

        // Get the users
        var thus = this;
        fetch(process.env.REACT_APP_API_BASE + '/workers/list?page=' + page, {
            method: 'get',
            headers: { 
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                "apiToken": workerAuth.apiToken,
                "apiKey": workerAuth.apiKey
            },
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            thus.setState({ workers: data.rslts, pages: data.pages, total: data.total, current: data.current, loading: false });
        })
        .catch(function (error) {
            console.log('Request failed', error);
        });
    }

    viewDetails ( e, uRef ) {
        e.preventDefault();
        this.setState({ displayUsr: uRef });
    }

    closeDetails ( e ) {
        e.preventDefault();
        this.setState({ displayUsr: false });
    }

    closeConsolidate ( e ) {
        e.preventDefault();
        this.setState({ displayUsr: false });
    }


    deleteWorker (e, id) {

        e.preventDefault();
        this.setState({ msg: false });

        let { auth } = this.state;

        // Get the users
        var thus = this;

        let doDelete = window.confirm('Do you wish to delete this worker?');

        if(!doDelete){
            return;
        }

        fetch(process.env.REACT_APP_API_BASE + '/workers/' + id, {
            method: 'delete',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                "apiToken": auth.apiToken,
                "apiKey": auth.apiKey
            },
        })
            .then(Fetching.statusCheck)
            .then(Fetching.jsonExtract)
            .then(function (data) {
                if (data) {
                    thus.loadWorkers();
                    thus.setState({ msg: { content: "The worker record has been successfully deleted.", className: "message success" } });
                } else {
                    thus.setState({ msg: { content: "Sorry but there was an issue deleting this worker record. Please try again.", className: "message" } });
                }
            })
            .catch(function (error) {
                console.log('Request failed', error);
            });

    }


  render() {

    let { loading, workers, total, pages, current, msg, openUpdate, openConsolidate,auth, editUsr } = this.state;


    return (
        <Fragment>


            {openUpdate ? (
                <LightboxUpdateWorker
                    id={editUsr}
                    auth={auth}
                    closeFunc={this.toggleUpdateLightbox}
                />
            ) : ""}

            { openConsolidate ? (
                <LightboxConsolidateWorker
                    id={ editUsr }
                    auth={auth}
                    closeFunc={ this.toggleConsolidateLightbox }
                />
            ) : "" }

            <Header/>
            
            <section className="content">
                <div className="width-9 centred">
                    {/* <button className="right" onClick={(e) => this.toggleCreateLightbox(e)}>New User</button> */}
                    <MainTitle text='Manage Workers'/>

                    { msg ? (
                        <div className={ msg.className }>
                            { msg.content }
                        </div>
                    ) : ""}
                </div>
                
                <div className="width-9 centred">
                    <table className='manage-users-table'>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            { loading ? (
                                <tr className="holding">
                                    <td colSpan="6">
                                        <Loader />
                                    </td>
                                </tr>
                            ) : (
                                <Fragment>
                                    { workers.map( worker => 
                                        <tr className={'normal' } key={ 'worker-' + worker.id }>
                                            <td>{ worker.name }</td>
                                            <td style={{ textAlign: 'right' }}>
                                                <Link to="/" onClick={(e) => this.toggleUpdateLightbox(e, false, worker.id)} className="btn">Edit</Link>
                                                
                                                <Link to="/" className="btn btn-dark" onClick={ (e) => this.deleteWorker(e, worker.id) }>Delete</Link>
                                                <Link to="/" className="btn " onClick={(e) => this.toggleConsolidateLightbox(e, false,worker.id)}>Consolidate</Link>
                                            </td>
                                        </tr>
                                    )}
                                </Fragment>
                            )}
                        </tbody>
                    </table>

                    { !loading && pages > 1 ? (
                        <PaginatorLinks 
                            actionFunc={ this.loadWorkers }
                            total={ total }
                            current={ current }
                            pages={ pages }
                            baseUrl={ "/admin/manage-workers" }
                            delay={ 10 }
                        />
                     ) :"" }
                </div>

            </section>
            
            <Footer/>
        </Fragment>
    );
  }
}



export default withCookies(ManageWorkers);
