import React, { Component, Fragment } from 'react';
import { withCookies } from 'react-cookie';

import Fetching from '../utilities/fetching';

import Text from './fields/text.js';
// import Address from './fields/address.js';
import SubmitButton from './fields/submit-button.js';
import Loader from './loader';

class LightboxUpdateUser extends Component {


    constructor(props) {
        super(props);
        this.state = {
            name: '',
            saving: "Save worker",
            msg: false,
            errors: false,
            loading: false
        }

        this.updateUsr = this.updateUsr.bind(this);
        this.updateField = this.updateField.bind(this);
    }

    componentDidMount () {
        this.loadDetail( this.props.id );
    }

    // Get the user details 
    loadDetail(id) {

        // Get the user details
        var thus = this;
        fetch(process.env.REACT_APP_API_BASE + '/workers/' + id, {
            method: 'get',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                "apiToken": this.props.auth.apiToken,
                "apiKey": this.props.auth.apiKey
            },
        })
            .then(Fetching.statusCheck)
            .then(Fetching.jsonExtract)
            .then(function (data) {
                thus.setState({ 
                    loading: false,
                    name: data.name,
                });
            })
            .catch(function (error) {
                console.log('Request failed', error);
            });

    }

    updateField(fieldName, value) {
        this.setState({ [fieldName]: value });
    }

    updateUsr(e) {

        e.preventDefault();
        this.setState({ saving: "Saving worker..." });

        // Set up the save data 
        let { name } = this.state;

        let auth = this.props.cookies.get('ldt-auth');

        var thus = this;
        fetch(process.env.REACT_APP_API_BASE + '/workers/' + this.props.id, {
            method: 'put',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                "apiToken": auth.apiToken,
                "apiKey": auth.apiKey
            },
            body: 'name=' + name.trim()
        })
            .then(Fetching.statusCheck)
            .then(Fetching.jsonExtract)
            .then(function (rslt) {
                if (typeof rslt.rslt != 'undefined' && rslt.rslt === "success") {
                    thus.props.closeFunc(null, { content: "The worker record has been successfuly updated.", className: "message success" },undefined,true);
                } else {
                    thus.setState({ saving: "Update user", errors: rslt, msg: { content: "Sorry but there was an issue updating this worker record. Please check the errors below and try again.", className: "message" } });
                }
            })
            .catch(function (error) {
                console.log('Request failed', error);
            });

    }

    render() {

        let { saving, errors, msg, loading, name,} = this.state;


        return (
            <Fragment>
                <div className='lightbox lightbox-create-user'>
                    <div className='lightbox-content'>
                        <div className='close' onClick={(e) => this.props.closeFunc(e)}>&times;</div>
                        <div className='lightbox-header'>
                            <h4>Update Worker</h4>
                        </div>

                        {msg ? (
                            <div className={msg.className}>
                                {msg.content}
                            </div>
                        ) : ""}

                        {loading ? (
                            <Loader bg="white" mrgn="mrgn-lrg" />
                        ) : (
                            <Fragment>
                                <div className='lightbox-section clear'>
                                    <Text label='Name' id='name' value={name } onChange={this.updateField} errors={errors} />
                                </div>
                                <div className='lightbox-submit'>
                                    <SubmitButton text={saving} handleSubmit={this.updateUsr} />
                                </div>
                            </Fragment>
                        )}
                    </div>
                </div>
            </Fragment>

        );
    }
}


export default withCookies(LightboxUpdateUser);

