import React, { Component, Fragment } from 'react';
import ForemanReportItem from './foreman-report-item.js'
import { withCookies } from 'react-cookie';
import Loader from './../common/loader';

class ForemanList extends Component {

  constructor(props) {
    super(props);

    this.state = {
     
      loading: true
    }

  }

  componentDidMount(){
    this.props.loadForemanReports();
  }

  render() {

    let foremanReportData = this.props.foremanReports;
    let { loading } = this.props.foremanDataLoading;

    let foremanReportItems =  foremanReportData.map( (reportItem,i) => {
      return (<ForemanReportItem toggleForemanLightboxOpen={this.props.toggleForemanLightboxOpen} index={i} key={i} clearOverdueForemanReports={this.props.clearOverdueForemanReports } {...reportItem} />)
    });

    return (


        <div className='width-3'>
          <div className='dashboard-panel'>
            <h2 className='dashboard-panel-title'>Overdue Reports</h2>
            
            { loading ? (
              <div style={{ marginTop: 44 }}>
                <Loader />
              </div>
            ) : (
              <Fragment>
                {foremanReportItems}
              </Fragment>
            )}
          </div>
        </div>


    );
  }
}

export default withCookies(ForemanList);