import React, { Component } from 'react';
import ReportSection from './report-section.js'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

class ReportContent extends Component {

	sliderNext() {
		this.slider.slickNext();
	}

	sliderPrev() {
		this.slider.slickPrev();
	}

	render() {

		var settings = {
			dots: false,
			infinite: false,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false
		}

		let useSlider = this.props.report.report_sections.length > 1 ? true : false;

		// Figure out the number of slides 
		var totalSlides = this.props.report.report_sections.length;

		// If this is a print view then make sure we do not use the slider (or the buttons)
		if ( typeof this.props.isPrint != "undefined" && this.props.isPrint ) {
			return (
				<div className='report-content'>
					{this.props.report.report_sections.map((report_section, i) =>
						<ReportSection
							key={i}
							repKey={i}
							sxn={report_section}
							isPrint={ true }
							includeButtons={ false }
						/>
					)}
				</div>
			)
		}

		return (
			<div className='width-9 centred report-content'>
				{ useSlider ? (
					<Slider ref={slider => (this.slider = slider)} {...settings}>
						{this.props.report.report_sections.map((report_section, i) =>
							<ReportSection
								includeButtons={true}
								key={i}
								repKey={ i }
								sxn={report_section}
								sliderNext={e => this.sliderNext()}
								sliderPrev={e => this.sliderPrev()}
								totalSlides={ totalSlides }
							/>
						)}
					</Slider>
				) : (
					<ReportSection sxn={this.props.report.report_sections[0]} includeButtons={false} totalSlides={ totalSlides }/>
				)}
			</div>
		)
	}


}

export default ReportContent;