import React, { Component, Fragment } from 'react';

class YesNoCommentEntry extends Component {

	render() {
		let { label, pre, post, fieldEntries, fieldStyleClass } = this.props;
		if(typeof pre == "undefined") pre = "";
		if(typeof post == "undefined") post = "";

		var fieldEntry = false;
		for (var i in fieldEntries) {
			if (!fieldEntry) {
				fieldEntry = fieldEntries[i];
				break;
			}
		}

		let valueClass = (typeof fieldEntry != "undefined" && fieldEntry.field_value === "Yes") ? "yes" : "no";
		let containerClass = "yes-no-entry-container";

		return (
			<Fragment>
				<div className={fieldStyleClass + " " + containerClass}>
					<div className="yes-no-entry-label">
						{label}
					</div>
					<div className={valueClass + " yes-no-entry"}>
						<span className="yes">Yes</span>
						<span className="no">No</span>
					</div>
					<div className="clear">
					</div>
				</div>
				{typeof fieldEntry == 'object' && typeof fieldEntry.comment != "undefined" && fieldEntry.comment !== null && fieldEntry.comment !== "" ? (
					<div className="basic-field-entry comment">
						{fieldEntry.comment}
					</div>
				) : ""}
			</Fragment>
		)
	}

}

export default YesNoCommentEntry;