import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import './assets/css/structure.css';
import './assets/css/core.css';

import Login from './components/users/login';
import ForgottenPassword from './components/users/forgotten-password';
import ResetPassword from './components/users/reset-password';
import Error404Component from './components/core/Error404Component.js'
import AdminTemplate from './components/core/admin-template.js'
import ReportExternalLink from './components/report-navigator/report_external_link';
import Report from './components/report-navigator/report';
import PrivacyPolicy from './components/policies/privacy';


function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/' component={ Login }/>
        <Route exact path='/forgotten-password' component={ ForgottenPassword }/>
        <Route exact path='/reset-password/:token/:date_key' component={ResetPassword} />
        <Route exact path='/reset-password/:token/:date_key/:create' component={ ResetPassword }/>
        <Route path='/admin/:actionId' render={(props) => <AdminTemplate  {...props} />}/>
        <Route exact path='/external_report/:url_segment/:url_token' component={ReportExternalLink} />
        <Route exact path='/print_report/:url_segment/:url_token' render={(props) => <ReportExternalLink {...props} isPrint={ true } />} />
        <Route exact path='/app_web_view' component={Report} />
        <Route exact path='/privacy' component={PrivacyPolicy} />
        <Route component={Error404Component} />
      </Switch>
    </BrowserRouter>

  );
}

export default App;
