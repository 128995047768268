import React, { Component, Fragment } from 'react';

import Standard from './../groups/standard';
import Repeater from './../groups/repeater';
import Row from './../groups/row';

class Table extends Component {    

    render() {

        return (
            <Fragment>
				<h4>{ this.props.sxn.title }</h4>
				<hr className='sxn-divider' />
                {typeof this.props.sxn.report_question_groups != "undefined" ? (
					<div className={ this.props.sxn.sxn_style }>
						<Fragment>
							{this.props.sxn.report_question_groups.map((grp, i) =>
								<div key={"grp-" + i} className={ 'report-question-group ' + grp.style }>
									{
										{
											standard: <Standard key={i} grp={grp} {...this.props} />,
											repeater: <Repeater key={i} grp={grp} {...this.props} />,
											row: <Row key={i} grp={grp} itemNo={i} {...this.props} />,
										}[grp.group_type]
									}
								</div>
							)}
						</Fragment>
					</div>
                ) : (
                    <Fragment></Fragment>
                )}

            </Fragment>
        );

    }

}

export default Table;

