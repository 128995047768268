import React, { Component, Fragment } from 'react';

import BasicFieldEntry from '../field-entry-types/basic-field-entry.js'
import CbGroup from '../field-entry-types/cb-group'
import YesNoEntry from '../field-entry-types/yes-no-entry.js';
import YesNoCommentEntry from '../field-entry-types/yes-no-comment-entry.js';
import NoYesCommentEntry from '../field-entry-types/no-yes-comment-entry.js';

class Repeater extends Component {

	sortRepeaterFieldEntries(fields) {
		// console.log("EXPORT", fields);
		let sortedRepeaterFields = [];
		for(var field in fields) {
			for(var fieldEntry in fields[field].report_field_entries) {
				// console.log("fieldEntry", fields[field].report_field_entries[fieldEntry]);
				if(typeof(sortedRepeaterFields[fields[field].report_field_entries[fieldEntry]['repeat_no']]) === 'undefined') {
					sortedRepeaterFields[fields[field].report_field_entries[fieldEntry]['repeat_no']] = [];
				}
				// console.log(sortedRepeaterFields[fields[field].report_field_entries[fieldEntry]['repeat_no']][fields[field]]);
				let entry = {'type': fields[field]['field_type'], 'label': fields[field]['label'], 'comment': fields[field].report_field_entries[fieldEntry]['comment'], 'value': fields[field].report_field_entries[fieldEntry]['field_value'], 'style': fields[field]['style'], 'field_type': fields[field]['field_type']};
				sortedRepeaterFields[fields[field].report_field_entries[fieldEntry]['repeat_no']].push(entry);
			}
		}
		return sortedRepeaterFields;
	}

    render() {
		let repeaterData = this.sortRepeaterFieldEntries(this.props.grp.report_fields);
		let nonBasic = ['YesNoComment', 'YesNo', 'YesNoImg'];
        return (
			<div className="repeater-wrap">
				{this.props.grp.title}
				{ repeaterData.length > 0 ? (
					<Fragment>
						{repeaterData.map((repeaterFieldEntries, i) =>
							<div key={i} className="repeater-section">
								{repeaterFieldEntries.map((fieldEntry, k) =>
									<Fragment key={ 'grp-' + k }>
										{nonBasic.indexOf(fieldEntry.type) === -1 ? (
											<BasicFieldEntry key={k} fieldType={fieldEntry.type} fieldStyleClass={fieldEntry.style} fieldEntries={new Array({ 'field_value': fieldEntry['value'] })} label={fieldEntry.label} outputLabel={true} post={fieldEntry.field_type === "Temperature" ? "°C" : ""} />
										) : (
											<Fragment>
												{ fieldEntry.type === "YesNo" || fieldEntry.type === "YesNoImg" ? (
													<YesNoEntry key={k} fieldType={ fieldEntry.type } label={ fieldEntry.label } fieldEntries={ [{ 'field_value': fieldEntry['value'] }] } />
												) : ""}
												{ fieldEntry.type === "YesNoComment" ? (
													<YesNoCommentEntry key={k} fieldType={ fieldEntry.type } label={ fieldEntry.label } fieldEntries={ { 'field_value': fieldEntry['value'], 'comment': fieldEntry['comment'] } } />
												) : ""}
												{ fieldEntry.type === "NoYesComment" ? (
													<NoYesCommentEntry key={k} fieldType={ fieldEntry.type } label={ fieldEntry.label } fieldEntries={ { 'field_value': fieldEntry['value'], 'comment': fieldEntry['comment'] } } />
												) : ""}
												{ fieldEntry.type === "CbGroup" ? (
													<CbGroup key={k} fieldType={ fieldEntry.type } label={ fieldEntry.label } fieldEntries={ { 'field_value': fieldEntry['value'] } } />
												) : ""}
											</Fragment>
										)}
									</Fragment>
								)}
							</div>
						)}
					</Fragment>
				) : (
					<p className="empty-data">Nothing to report in this section.</p>
				)}
				
				
			</div>
        );
    }

}

export default Repeater;