import React, { Component, Fragment } from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment'

class ReviewLightBox extends Component {

  render() {

    return (
        <Fragment>
            <div className='lightbox'>
                <div className='lightbox-content'>
                    <div className='close' onClick={(e) => this.props.closeFunc(e) }>&times;</div>
                    <div className='lightbox-header'>
                        <h4>Reports</h4>
                    </div>

                    { this.props.reports.reports.map((report, index) =>
                        <div className='panel-list-item' key={"rep-" + index}>
                            <Link className='btn right' to={'/admin/report/' + report.id}>View</Link>
                            <h3>{report.project.title === null ? "" : report.project.title} - {report.report_type.title}</h3>
                            <p>Foreman: {report.creator.first_name + ' ' + report.creator.last_name}</p>
                            <p className="detail">Date: {moment(new Date(report.submission_date)).format("DD/MM/YYYY")}</p>
                        </div>
                    )}
                </div>
            </div>
        </Fragment>
      
    );
  }
}


export default ReviewLightBox;

