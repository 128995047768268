import React, { Component, Fragment } from 'react';
// import Fetching from '../utilities/fetching.js';

import Table from './sxns/table';
import Plain from './sxns/plain';
import SliderButtons from './slider-buttons.js';

import { withCookies } from 'react-cookie';

class ReportSection extends Component {

	render() {
		return (
			<Fragment>
				{
					{
						plain: <Plain sxn={this.props.sxn} { ...this.props } />,
						table: <Table sxn={this.props.sxn} {...this.props}  />,
						null: <Plain sxn={this.props.sxn} {...this.props}  />,
					}[this.props.sxn.sxn_type]
				}
				{this.props.includeButtons ? (
					<SliderButtons { ...this.props } />
				) : ""}
			</Fragment>
		)
	}


}

export default withCookies(ReportSection);