import React, { Component } from 'react';



class ForemanReportItem extends Component {


  render() {

    let additionalClassName = '';

    if(this.props.status === 'complete' || this.props.report_count === 0){
       additionalClassName = 'complete'
    }

    return (


        <div className='panel-list-item'>
          <div className={'status '+ additionalClassName} onClick={(e)=> this.props.toggleForemanLightboxOpen(e,this.props.outstanding_reports, this.props.foreman)}>
            {this.props.report_count === 0 ? '✔' : this.props.report_count }
          </div>
          { this.props.report_count > 0 &&
            <button className="sml right clr" onClick={(e)=> this.props.clearOverdueForemanReports(e, this.props.foreman)}>Clear</button>
          }
          <h3>{this.props.foreman.first_name+' '+this.props.foreman.last_name}</h3>
        </div>


    );
  }
}

export default ForemanReportItem;