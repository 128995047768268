import React, { Component, Fragment } from 'react';

import Header from '../core/header.js'
import Footer from '../core/footer.js'

import MainTitle from '../common/titles/main-title.js';

import ToolboxTable from './toolbox-talk-table.js'


import LightBoxEditToolbox from './lightbox-edit-toolbox-talk.js'
import Fetching from '../utilities/fetching.js';

import { withCookies } from 'react-cookie';

class Toolbox extends Component {

  constructor(props) {
    super(props);

    this.state = {
        isCreateManufacturerLightBoxOpen:false,
        isEditProjectLightBoxOpen:false,
        msg:false,
        manufacturers:[],
        loading:true,
        auth:{},
        toolboxTalks:[],
        total:-1,
        page:1,
        document_id:-1,
        isEdit:false
    }

    this.toggleEditToolboxLightBoxOpen = this.toggleEditToolboxLightBoxOpen.bind(this);
    this.updateMsg = this.updateMsg.bind(this);
    this.loadToolboxes = this.loadToolboxes.bind(this);
    this.togglePublishStatus = this.togglePublishStatus.bind(this);
    this.deleteToolbox = this.deleteToolbox.bind(this);
  }

  componentDidMount(){
    this.loadToolboxes();
  }



  loadToolboxes(usrAuth){
    // If it is this type of query display the loader 
    if (typeof displayLoader != 'undefined') {
      this.setState({ loading: true });
  }

  // Work out whether a page is mentioned 
  var qsParams = this.props.location.search.replace("?", "");
  qsParams = qsParams.split("&");
  var page = false;
  for (var j in qsParams) {
      if (qsParams[j].indexOf("page=") > -1) {
          page = qsParams[j].replace("page=", "");
      }
  }

  if ( !page ) page = 1;
  page = Number(page);


  usrAuth = this.props.cookies.get('ldt-auth');

  // Get the users
  var thus = this;
  fetch(process.env.REACT_APP_API_BASE + '/documents?file_type=talk&page=' + page, {
      method: 'get',
      headers: { 
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          "apiToken": usrAuth.apiToken,
          "apiKey": usrAuth.apiKey,
      },
  })
  .then(Fetching.statusCheck)
  .then(Fetching.jsonExtract)
  .then(function (data) {
        thus.setState({ toolboxTalks: data.rslts,pages: data.pages, total: data.total, current: data.current, loading: false });
  })
  .catch(function (error) {
      thus.setState({loading: false });
      console.log('Request failed', error);
  });
}

togglePublishStatus(e,document_id,published){

  e.preventDefault();
  // If it is this type of query display the loader 
  if (typeof displayLoader != 'undefined') {
    this.setState({ loading: true });
  }


  let usrAuth = this.props.cookies.get('ldt-auth');
  
  let new_published = (published === 1) ? 0 : 1 ;

  // Get the users
  var thus = this;
  fetch(process.env.REACT_APP_API_BASE + '/documents/toggle_published', {
      method: 'post',
      headers: { 
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          "apiToken": usrAuth.apiToken,
          "apiKey": usrAuth.apiKey,
      },
      body:'id='+document_id+'&published='+new_published
  })
  .then(Fetching.statusCheck)
  .then(Fetching.jsonExtract)
  .then(function (data) {
       thus.loadToolboxes();
       thus.setState({msg:false})
  })
  .catch(function (error) {
      console.log('Request failed', error);
  });
}

deleteToolbox(e,document_id){

  let doDelete = window.confirm('This cannot be undone, do you wish to continue?');

  if(!doDelete){
    return false;
  }

  e.preventDefault();
  // If it is this type of query display the loader 
  if (typeof displayLoader != 'undefined') {
    this.setState({ loading: true });
  }


  let usrAuth = this.props.cookies.get('ldt-auth');
 

  // Get the users
  var thus = this;
  fetch(process.env.REACT_APP_API_BASE + '/documents', {
      method: 'delete',
      headers: { 
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          "apiToken": usrAuth.apiToken,
          "apiKey": usrAuth.apiKey,
      },
      body:'id='+document_id
  })
  .then(Fetching.statusCheck)
  .then(Fetching.jsonExtract)
  .then(function (data) {
       thus.loadToolboxes();
       thus.setState({msg:false})
  })
  .catch(function (error) {
      console.log('Request failed', error);
  });
}

  updateMsg ( value ) {
    console.log(value);
    this.setState({ msg: value });
}


toggleEditToolboxLightBoxOpen(e,document_id){

  e.preventDefault();

  let new_document_id = -1;

    if(typeof document_id !== 'undefined'){
      new_document_id = document_id
    }

    let msg= this.state.msg;

    if(!this.state.isEditProjectLightBoxOpen){
      msg = false
    }

  this.setState(prevState => ({
    isEditProjectLightBoxOpen: !prevState.isEditProjectLightBoxOpen,
      document_id:new_document_id,
      isEdit:true,
      msg:msg
  }));
}


toggleCreateNewToolboxLightBoxOpen(e){

  e.preventDefault();


  this.setState(prevState => ({
    isEditProjectLightBoxOpen: !prevState.isEditProjectLightBoxOpen,
      msg: false,
      isEdit:false
  }));
}

  render() {

    let {msg,toolboxTalks,isEdit} = this.state;

    let lightBoxEditToolbox = null;

    if(this.state.isEditProjectLightBoxOpen){
        lightBoxEditToolbox = <LightBoxEditToolbox triggerMsg={this.updateMsg} isEdit={isEdit} loadToolboxes={this.loadToolboxes}  document_id={this.state.document_id} toggleEditToolboxLightBoxOpen={this.toggleEditToolboxLightBoxOpen} closeFunc={this.toggleEditToolboxLightBoxOpen} />
    }
  
  
    return (

        <Fragment>
            {lightBoxEditToolbox}
            <Header/>
            <section className="content">
                <div className="width-9 centred">
                  
                  <button className="right" style={{marginRight:'3px'}} onClick={(e) => this.toggleCreateNewToolboxLightBoxOpen(e)}>New Toolbox</button>
                     
                  < MainTitle text='Manage Toolbox Talks'/>

                  {msg ? (
                        <div className={msg.className}>
                            {msg.content}
                        </div>
                    ) : ""}
                </div>

                <ToolboxTable deleteToolbox={this.deleteToolbox} loadToolboxes={this.loadToolboxes} togglePublishStatus={this.togglePublishStatus}  toolboxTalks={toolboxTalks} toggleEditToolboxLightBoxOpen={this.toggleEditToolboxLightBoxOpen} {...this.state}/>
                
            </section>
            <Footer/>
        </Fragment>
    );
  }
}

export default withCookies(Toolbox);