import React, { Component } from 'react';

import { withCookies } from 'react-cookie';

class SliderButtons extends Component {

	render() {

		return (
			<div className="report-slider-buttons">
				{ this.props.repKey !== 0 ? (
					<div className="report-slider-button prev" onClick={ e => this.props.sliderPrev() }>
						PREVIOUS
					</div>
				) : "" }
				{this.props.repKey < (this.props.totalSlides - 1) ? (
					<div className="report-slider-button" onClick={ e => this.props.sliderNext() }>
						NEXT
					</div>
				) : ""}
			</div>
		)
	}


}

export default withCookies(SliderButtons);