

import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Loader from '../common/loader';
import PaginatorLinks from '../common/paginator/paginator-links';

class ProjectTable extends Component {

  constructor(props) {
    super(props);

    this.state = {

    }

  }

  


  render() {


    let {loading,pages,current,total} = this.props;

    let projectData = (<tr className="holding">
                            <td colSpan="6">
                                <Loader />
                            </td>
                        </tr>);

    if(!loading){

        

        projectData = this.props.projects.map((project,i) => {

            return (
                <tr key={i}>
                    <td>{project.title}</td>
                    <td className='rx-800-hidden'>{ project.foreman_user !== null ? project.foreman_user.first_name+' '+project.foreman_user.last_name : '' }</td>
                    <td className='rx-800-hidden'>{project.contact.name}</td>
                    <td className='rx-1100-hidden'>{project.contact.email}</td>
                    <td className='rx-800-hidden'>{typeof project.status !== 'undefined' ? project.status.charAt(0).toUpperCase() + project.status.slice(1) : ''}</td>
                    <td className='project-edit-td align-right'>
                        <Link to='#' className='btn ' onClick={(e) => this.props.toggleEditProjectLightBoxOpen(e,project.id)}>Edit</Link>
                        <Link to='#' className='btn btn-dark' onClick={(e) => this.props.deleteProject(e,project.id)}>Delete</Link>
                    </td>
                </tr>
            );
        } );
    }

   

    return (
        <Fragment>
            <div className='width-12'>
                <table className='projects-table'>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th className='rx-800-hidden'>Foreman</th>
                            <th className='rx-800-hidden'>Client</th>
                            <th className='rx-1100-hidden'>Client Email</th>
                            <th className='rx-800-hidden'>Status</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {projectData}
                    </tbody>
                </table>

                { !loading && pages > 1 ? (
                <PaginatorLinks 
                    actionFunc={ this.props.loadProjects }
                    total={ total }
                    current={ current }
                    pages={pages }
                    baseUrl={ "/admin/projects" }
                    delay={ 10 }
                />
                ) :"" }
            </div>
            
                    
        </Fragment>
    
    );
  }
}

export default ProjectTable;