import React, { Component, Fragment } from 'react';

import Fetching from './../../utilities/fetching';
import Text from '../../common/fields/text.js';
import Email from '../../common/fields/email.js';
import Password from '../../common/fields/password.js';
// import Address from '../../common/fields/address.js';
import SubmitButton from '../../common/fields/submit-button.js';
import { withCookies } from 'react-cookie';

class UpdateDetailsForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: false,
            saving: "Update my details",
            first_name: "",
            last_name: "",
            email: "",
            current: "",
            msg: false
        }

        this.updateField = this.updateField.bind(this);
        this.updateUsr = this.updateUsr.bind(this);
    }

    // Upon load get the current user details and set them to the array
    componentDidMount() {
        this.loadDetail();
    }

    // Get the user details 
    loadDetail() {

        // Get the user details
        var thus = this;
        fetch(process.env.REACT_APP_API_BASE + '/users/get', {
            method: 'get',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                "apiToken": this.props.auth.apiToken,
                "apiKey": this.props.auth.apiKey
            },
        })
            .then(Fetching.statusCheck)
            .then(Fetching.jsonExtract)
            .then(function (data) {
                thus.setState({
                    loading: false,
                    email_address: data.email_address,
                    first_name: data.first_name,
                    last_name: data.last_name,
                });
            })
            .catch(function (error) {
                console.log('Request failed', error);
            });

    }

    updateField(fieldName, value) {
        this.setState({ [fieldName]: value });
        console.log(value);
    }

    updateUsr(e) {

        e.preventDefault();
        this.setState({ saving: "Updating your details..." });

        let auth = this.props.cookies.get('ldt-auth');

        // Set up the save data 
        let { first_name, last_name, email_address, current } = this.state;

        var thus = this;
        fetch(process.env.REACT_APP_API_BASE + '/users/update', {
            method: 'put',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                "apiToken": auth.apiToken,
                "apiKey":auth.apiKey
            },
            body: 'first_name=' + encodeURIComponent(first_name) + '&last_name=' + encodeURIComponent(last_name) + '&email_address=' + encodeURIComponent(email_address)  + '&password=' + encodeURIComponent(current)
        })
            .then(Fetching.statusCheck)
            .then(Fetching.jsonExtract)
            .then(function (rslt) {
                if (rslt === true) {
                    thus.setState({ saving: "Update my details", errors: false, msg: { content: "Your details have been successfully updated.", className: "message success" } });
                } else {
                    thus.setState({ saving: "Update my details", errors: rslt, msg: { content: "Sorry but there was an issue updating your details. Please check the errors below and try again.", className: "message" } });
                }
            })
            .catch(function (error) {
                console.log('Request failed', error);
            });

    }

    render() {

        let { errors, saving, first_name, last_name, email_address, msg } = this.state;

        return (
            <Fragment>
                <div className='form-header'>
                    <h4>Update my details</h4>                    
                </div>

                {msg ? (
                    <div className={msg.className}>
                        {msg.content}
                    </div>
                ) : ""}

                <form>
                    <div className='form-section'>
                        <Text label='First name' id='first_name' errors={errors} value={first_name} onChange={this.updateField} />
                    </div>
                    <div className='form-section'>
                        <Text label='Last name' id='last_name' errors={errors} value={last_name} onChange={this.updateField} />
                    </div>
                    <div className='form-section'>
                        <Email label='Email' id='email_address' errors={errors} value={email_address} onChange={this.updateField} />
                    </div>
                    <div className='form-section confirm'>
                        <Password label='Enter Current Password' id='current-pw' fieldName="current" errors={errors} onChange={this.updateField} />
                    </div>
                    <div className='form-section'>
                        <SubmitButton text={saving} handleSubmit={this.updateUsr} />
                    </div>
                </form>
                        
            </Fragment>
        
        );
  }
}


export default withCookies(UpdateDetailsForm);
