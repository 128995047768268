import React, { Component, Fragment } from 'react';

class SubmitButton extends Component {

  render() {

    let {to,text} = this.props;

    let isDisabled = (typeof this.props.isDisabled !== 'undefined') ? this.props.isDisabled : undefined;
    let submitHandler = typeof this.props.handleSubmit !== 'undefined' ? (e) => this.props.handleSubmit(e) : null;

    let classNames = '';
    if(isDisabled){
      classNames = ' submit-disabled ';
    }

    return (
        <Fragment>
            <button to={to} disabled={isDisabled} onClick={ submitHandler } className={"submit "+classNames}>{text}</button>
        </Fragment>
      
    );
  }
}


export default SubmitButton;
