import React, { Component } from 'react';
import Fetching from './../../utilities/fetching';
import { withCookies } from 'react-cookie';


class Upload extends Component {

	// Constructor to set up data and set init state
	constructor(props) {
		super(props);

		let initialState = {
			fileId: "",
			formAction: "",
			filename: "",
			acl: "",
			cred: "",
			algo: "",
			lnkExpiry: "",
			policy: "",
			sig: "",
			formDisabled: true,
			fileUploadText:'Drag to Upload File...',
			displayfileName:'',
			document_id:-1
		};

		this.state = initialState;

		this.getFormDetails = this.getFormDetails.bind(this);
		this.confirmUpload = this.confirmUpload.bind(this);
		// this.getTokens = this.getTokens.bind(this);
	}

	componentDidMount(){
		if(typeof this.props.fileName !== 'undefined'){
			this.setState({
				displayfileName:this.props.fileName,
				fileUploadText:this.props.fileName
			});
		}

	}

	componentDidUpdate(prevProps){
		if(prevProps.fileName !== this.props.fileName){
			this.setState({
				displayfileName:this.props.fileName,
				fileUploadText:this.props.fileName
			});
		}
	}

	getFormDetails (e) {

		// Disable the input submit while processing the update
		this.setState({ formDisabled: true, fileUploadText:'Uploading File...'});

		// Get the file name
		var fullPath = e.target.value;
		if (fullPath) {
			var startIndex = (fullPath.indexOf('\\') >= 0 ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/'));
			var filename = fullPath.substring(startIndex);
			if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
				filename = filename.substring(1);
			}
		}

		let { fileId } = this.state;

		 var fileData = { filename: filename, fid: fileId, fileContent: e.target.files[0] };
		var thus = this;
		
		let auth = this.props.cookies.get('ldt-auth');

		console.log(e.target.files);

		let bodyString = 'filename='+filename+'&file_type='+this.props.file_type;


		if(this.props.document_id !== -1){
			bodyString += '&id='+  this.props.document_id
		}

		fetch(process.env.REACT_APP_API_BASE + '/documents/form', {
			method: 'post',
			headers: {
				"Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
				"apiToken": auth.apiToken,
                "apiKey": auth.apiKey
			},
			body: bodyString
		})
		.then(Fetching.statusCheck)
		.then(Fetching.jsonExtract)
		.then(function (data) {
			if ( typeof data.fid != "undefined" ) {
				console.log('Request succeeded with JSON response', data);
				thus.setState({
					fileId: data.fid,
					formAction: data.attributes.action,
					filename: data.subName, // TODO - remove this!!!
					acl: data.inputs.acl,
					cred: data.inputs['X-Amz-Credential'],
					algo: data.inputs['X-Amz-Algorithm'],
					lnkExpiry: data.inputs['X-Amz-Date'],
					policy: data.inputs['Policy'],
					sig: data.inputs['X-Amz-Signature'],
					formDisabled: false,
					displayfileName:filename
				},() => thus.doFetchSubmit(fileData));

				console.log(thus.state);
				// thus.getTokens();
				
			} else {
				alert("There was an error updating your agenda. Please check the content and try again.");
			}
		})
		.catch(function (error) {
			// thus.getTokens();
			console.log('Request failed', error);
		});
	}

	doFetchSubmit ( fileData ) {

		// Assemble the core data for form submission
		let { formAction, filename, acl, cred, algo, lnkExpiry, policy, sig } = this.state;
		var submitData = new FormData();
		submitData.append("key", filename);
		submitData.append("acl", acl);
		
		submitData.append("X-Amz-Credential", cred);
		submitData.append("X-Amz-Algorithm", algo);
		submitData.append("X-Amz-Date", lnkExpiry);
		submitData.append("Policy", policy);
		submitData.append("X-Amz-Signature", sig);
		submitData.append("file", fileData.fileContent);

		var thus = this;

		fetch(formAction, {
			method: 'post',
			body: submitData,
			mode: "no-cors" // TODO - implement CORS on live so we can handle this with a non-opaque response
		})
		.then(Fetching.statusCheck)
		.then(function (data) {
			console.log('Upload succeeded. Handle confirmation.');
			thus.confirmUpload();
		})
		.catch(function (error) {
			// thus.getTokens();
			console.log('Request failed', error);
		});
	}

	confirmUpload () {

		let { fileId } = this.state;

    // If we have the tokens then get the tabs for this
    var fetchUrl = process.env.REACT_APP_API_BASE + '/documents/confirm';
	var thus = this;
	
	let auth = this.props.cookies.get('ldt-auth');
    fetch(fetchUrl, {
		method: 'post',
		headers: {
			"Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
			"apiToken": auth.apiToken,
			"apiKey": auth.apiKey
		},
		body: 'id='+fileId
	})
      .then(response => response.json())
      .then(
        function (data) {

			let {displayfileName} = thus.state;

          if (!data) {
            alert("Sorry but there was an error uploading your file. Please try again.");
          } else {
			thus.setState({
				fileId: "",
				formAction: "",
				filename: "",
				acl: "",
				cred: "",
				algo: "",
				lnkExpiry: "",
				policy: "",
				sig: "",
				formDisabled: true,
				fileUploadText:displayfileName
			});
			if (typeof thus.props.callbackFunc != 'undefined') {
				thus.props.callbackFunc(fileId);
			}
		}


        }
      );

		// Clear the file input
		document.getElementById("fileInput").value = "";

  }


  render() {

		let { formAction,fileUploadText } = this.state;

    return (
		  <form action={ formAction } method="post" encType="multipart/form-data" id="uploadForm">
			<label className='form-upload-label' htmlFor="fileInput">
		    	<input type="file" name="file" id="fileInput" onChange={ (e) => this.getFormDetails(e) } className="file-uploader" />
				{fileUploadText}
			</label>
		  </form>
    );
  }
}

export default withCookies(Upload);
