import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ForemanList extends Component {

  render() {

    return (

        <div className='width-3'>
          <div className='dashboard-panel report-type-panel'>
            <h2 className='dashboard-panel-title'>Select a Project</h2>
            <ul>
                { this.props.projects.map ( (project, i) => 
                  <li key={'pro-' + i} className={project.deleted_at !== null || project.status !== 'active' ? "dashbaord-panel-deleted-project" : ""}>
                    <Link to='#' onClick={(e) => this.props.selectProject(e, project.id)} className={this.props.selectedProjectId === project.id ? "selected-lnk" : ""}>{ project.title }</Link>
                  </li>
                )}
            </ul>
            
          </div>
        </div>


    );
  }
}

export default ForemanList;