import React, { Component, Fragment } from 'react';

import Header from '../core/header.js'
import Footer from '../core/footer.js'

import RecentReportList from './recent-report-list.js'
import ForemanReportList from './foreman-report-list.js'
import ReportList from './report-list.js'
import ItemsOfConcernList from './items-of-concern-list.js'

// import Table from './page-components/table.js'
// import Totals from './page-components/totals.js'
// import MainTitle from '../common/titles/main-title.js';
// import Filter from './page-components/filter.js';
// import LightboxCreateBooking from './page-components/lightbox-create-booking.js';

import ForemanLightBox from '../common/foreman-lightbox.js';
import ReviewLightbox from '../common/review-lightbox.js';
import ItemOfConcernLightBox from '../common/item-of-concern-lightbox.js';
import Fetching from '../utilities/fetching.js';
import { withCookies } from 'react-cookie';
// import Fetching from './../../utilities/fetching';

// import moment from 'moment';

// import { withCookies } from 'react-cookie';


class Listing extends Component {

    constructor(props){
        super(props);
        this.state = {
            isForemanLightboxOpen : false,
            selectedForeman: false,
            isItemOfConcernLightboxOpen : false,
            isReviewLightboxOpen : false,
            issueContent: false,
            foremanLightboxData:[],
            foremanReports:[],
            reviewLightboxData:[],
            foremanDataLoading:true
        }

        this.toggleForemanLightboxOpen = this.toggleForemanLightboxOpen.bind(this);
        this.toggleReviewLightboxOpen = this.toggleReviewLightboxOpen.bind(this);
        this.toggleItemOfConcernLightboxOpen = this.toggleItemOfConcernLightboxOpen.bind(this);
        this.loadForemanReports = this.loadForemanReports.bind(this)
        this.clearOverdueForemanReports = this.clearOverdueForemanReports.bind(this);

        this.updateMsg = this.updateMsg.bind(this);

    }

    componentDidMount(){


        
    }

    updateMsg ( value ) {
        console.log(value);
        this.setState({ msg: value });
    }


     // Get the user details 
  loadForemanReports() {

    // Get the user details
    var thus = this;
    let auth = this.props.cookies.get('ldt-auth');

    fetch(process.env.REACT_APP_API_BASE + '/reports/load_outstanding_foreman_reports', {
        method: 'get',
        headers: {
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
            "apiToken": auth.apiToken,
            "apiKey": auth.apiKey
        },
    })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
          thus.setState({foremanReports:data, loading: false});
        })
        .catch(function (error) {
            console.log('Request failed', error);
        });

}
  

    toggleForemanLightboxOpen(e,outstandingReports, foreman){

        if(typeof e !== 'undefined'){
            e.preventDefault();
        }
        this.setState(prevState => ({
            isForemanLightboxOpen: !prevState.isForemanLightboxOpen,
            foremanLightboxData:outstandingReports,
            selectedForeman: foreman,
            msg: false
        }));
    }

    toggleReviewLightboxOpen(e, reports) {

        if (typeof e !== 'undefined') {
            e.preventDefault();
        }
        this.setState(prevState => ({
            isReviewLightboxOpen: !prevState.isReviewLightboxOpen,
            reviewLightboxData: reports,
            msg: false
        }));
    }

    toggleItemOfConcernLightboxOpen(e, issue){
        if(typeof e !== 'undefined'){
            e.preventDefault();
        }
        
        this.setState(prevState => ({
            isItemOfConcernLightboxOpen: !prevState.isItemOfConcernLightboxOpen,
            issueContent: issue
        }));
    }

    clearOverdueForemanReports(e, foreman) {
        e.preventDefault();

        var thus = this;
        console.log(this.props.cookies);
        if (window.confirm('Are you sure you want to clear all overdue reports for this foreman?')) {
            // Get the user details

            let auth = thus.props.cookies.get('ldt-auth');
            console.log(thus.props.cookies);

            fetch(process.env.REACT_APP_API_BASE + '/reports/clear_outstanding_foreman_reports/' + foreman.public_id, {
                method: 'get',
                headers: {
                    "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                    "apiToken": auth.apiToken,
                    "apiKey": auth.apiKey
                },
            })
                .then(Fetching.statusCheck)
                .then(Fetching.jsonExtract)
                .then(function (data) {
                    if (data === 1) {
                        // thus.setState({ overdueReports: [] });
                        thus.loadForemanReports();
                        // this.props.closeFunc();
                    }
                })
                .catch(function (error) {
                    console.log('Request failed', error);
                });

        }
    }


  render() {

      let { issueContent, foremanReports, foremanDataLoading, reviewLightboxData, selectedForeman } = this.state;

    let foremanLightBox = null;
    let itemOfConcernLightBox = null;
    let reviewLightBox = null;

    if(this.state.isForemanLightboxOpen){
        foremanLightBox = <ForemanLightBox foreman={selectedForeman } toggleLightBoxOpen={this.toggleForemanLightboxOpen} loadForemanReports={this.loadForemanReports} closeFunc={this.toggleForemanLightboxOpen} foremanLightboxData={this.state.foremanLightboxData}/>
    }

    if(this.state.isItemOfConcernLightboxOpen){
        itemOfConcernLightBox = <ItemOfConcernLightBox uid={1} toggleItemOfConcernLightboxOpen={this.toggleItemOfConcernLightboxOpen} closeFunc={this.toggleItemOfConcernLightboxOpen} issue={ issueContent } />
    }

    if (this.state.isReviewLightboxOpen) {
        reviewLightBox = <ReviewLightbox closeFunc={this.toggleReviewLightboxOpen} reports={reviewLightboxData} />
    }
    

    // if(this.state.isCreateBookingLightboxOpen){
    //     createBookingLightBox = <LightboxCreateBooking triggerMsg={ this.updateMsg } loadBookings={this.loadBookings} toggleCreateBookingLightBoxOpen={this.toggleCreateBookingLightBoxOpen} />
    // }

    return (
        <Fragment>
            {foremanLightBox}
            {itemOfConcernLightBox}
            {reviewLightBox}
            <Header/>
            
            <section className="content">
               <RecentReportList/>
               <ForemanReportList foremanReports={foremanReports} foremanDataLoading={foremanDataLoading} clearOverdueForemanReports={ this.clearOverdueForemanReports } loadForemanReports={this.loadForemanReports} toggleForemanLightboxOpen={this.toggleForemanLightboxOpen}/>
               <ReportList toggleReviewLightboxOpen={ this.toggleReviewLightboxOpen } />    
               <ItemsOfConcernList toggleItemOfConcernLightboxOpen={this.toggleItemOfConcernLightboxOpen} />
               <ItemsOfConcernList toggleItemOfConcernLightboxOpen={this.toggleItemOfConcernLightboxOpen} issue_type={'commercial'}/>
            </section>

            <Footer/>
            
        </Fragment>
    );
  }
}



export default withCookies(Listing);
