import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import Fetching from '../utilities/fetching.js';
import { withCookies } from 'react-cookie';

import Loader from '../common/loader.js'

import './../../assets/css/login.css';

class Logout extends Component {


    constructor(props){
        super(props);

        this.state = {
            redirectToLogin:false
        }

        this.doLogout = this.doLogout.bind(this);
    }

    componentDidMount(){
        this.doLogout();
    }

    doLogout () {
    
        const { cookies } = this.props;
        let authCookie = cookies.get('ldt-auth');
    
        let {apiToken,apiKey} =  authCookie;
        var thus = this;
        fetch(process.env.REACT_APP_API_BASE + '/logout', { 
            method: 'get',
            headers: { 
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                "apiToken": apiToken,
                "apiKey": apiKey
            }
         })
          .then(Fetching.adminStatusCheck)
          .then(Fetching.jsonExtract)
          .then(function (data) {
            if(data === true){
                cookies.remove('ldt-auth');
                thus.setState({ redirectToLogin: true });
            }
          })
          .catch(function (error) {
    
              if(error === 'unauthorised'){
                cookies.remove('ldt-auth');      
                thus.setState({ redirectToLogin: true });
              }
            
          
          });
    
      }

    

  render() {

    if(!this.state.redirectToLogin){
         return (
            <Loader bg='center-page'/>
          );
    }

    return (
        <Fragment>
            <Redirect to='/' />
        </Fragment>
      
    );
  }
}

// export default Logout;

export default withCookies(Logout);
