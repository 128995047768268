import React, { Component, Fragment } from 'react';
import ItemsOfConcernItem from './items-of-concern-item.js'
import Fetching from './../utilities/fetching';
import { withCookies } from 'react-cookie';

import Loader from './../common/loader';

class ItemsOfConcernList extends Component {

  constructor(props) {
      super(props);

      this.state = {
        issues: [],
        loading: true
      }

    this.dismissIssue = this.dismissIssue.bind(this);
  }

  componentDidMount () {
    this.loadIssues();
  }

  // Load up the issues for display 
  loadIssues () {

      let auth = this.props.cookies.get('ldt-auth');

      var thus = this;

      let issue_type = 'concern';

      if(typeof this.props.issue_type !== 'undefined' ){
        issue_type = this.props.issue_type
      }
      fetch(process.env.REACT_APP_API_BASE + '/issues?issue_type='+issue_type, {
        method: 'get',
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          "apiToken": auth.apiToken,
          "apiKey": auth.apiKey
        }
      })
      .then(Fetching.statusCheck)
      .then((response) => response.json())
      .then((issues) => {
        if (typeof issues == "object") {
          thus.setState({ loading: false, issues: issues });
        }
      })
      .catch(function (error) {
        console.log('Request failed', error);
        thus.setState({ loading: false });
      });

  }

  // Handle the dismiss button
  dismissIssue ( e, issueId ) {
    e.preventDefault();

    let auth = this.props.cookies.get('ldt-auth');
    // Run the clearance
    var thus = this;
    fetch(process.env.REACT_APP_API_BASE + '/issues/' + issueId, {
      method: 'delete',
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        "apiToken": auth.apiToken,
        "apiKey": auth.apiKey
      }
    })
    .then(Fetching.statusCheck)
    .then((response) => response.json())
    .then((rslt) => {
      if (typeof rslt.rslt != "undefined" && rslt.rslt === true) {
        // Upon success reload the issues list
        thus.loadIssues();
      } else {
        alert('There was an error resolving the issue. Please try again.');
      }
    })
    .catch(function (error) {
      console.log('Request failed', error);
    });

  }

  render() {

    let { issues, loading } = this.state;

    let title = 'Items of Concern';

    if(typeof this.props.issue_type !== 'undefined'  && this.props.issue_type === 'commercial'){
      title = 'Commercial Actions'
    }

    return (

        <div className='width-3'>
          <div className='dashboard-panel'>
            <h2 className='dashboard-panel-title'>{title}</h2>
            { loading ? (
              <div style={{ marginTop: 44 }}>
                <Loader />
              </div>
            ) :(
              <Fragment>
                { issues.map ( (issue, i) => 
                  <Fragment key = { 'iss-' + i}>
                    { issue.issue_content !== null && issue.issue_content !== "" &&
                      <ItemsOfConcernItem 
                        issue={ issue }
                        toggleIssueDetails={ this.props.toggleItemOfConcernLightboxOpen } 
                        dismissIssue={ this.dismissIssue }
                      />
                  }
                  </Fragment>
                )} 
              </Fragment>
            )}
            
 
          </div>

        </div>


    );
  }
}

export default withCookies(ItemsOfConcernList);