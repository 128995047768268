import React, { Component, Fragment } from 'react';

class Text extends Component {

  handleInputChange(e, fieldName) {
    if (typeof this.props.onChange != 'undefined') {
      let value = e.target.value;
      this.props.onChange(fieldName, value);
    }
  }

  handleInputBlur(e, fieldName) {
    if (typeof this.props.onBlur != 'undefined') {
      let value = e.target.value;
      this.props.onBlur(fieldName, value);
    }
  }

  render() {

    let {id,label} = this.props;

    var labelClass = "";
    if (typeof this.props.meetsDesires != "undefined" && this.props.meetsDesires === false) {
      labelClass = "highlight";
    }

    return (
        <Fragment>
            <div className="input">
              { typeof this.props.revert != "undefined" ? (
                <button className="revert-btn" onClick={ (e) => this.props.revert(e, id) }>Undo latest changes</button>
              ) : "" }
              <label htmlFor={id} className={ labelClass }>{label}</label>
              {typeof this.props.revert != "undefined" ? (
                <input type="text" id={id} name={id} value={ this.props.value } onChange={(e) => this.handleInputChange(e, id)} onBlur={ (e) => this.handleInputBlur(e, id) }/>
              ) : (
                <input type="text" id={id} name={id} defaultValue={this.props.value} onChange={(e) => this.handleInputChange(e, id)} onBlur={(e) => this.handleInputBlur(e, id)} />
              )}
              {typeof this.props.errors != 'undefined' && typeof this.props.errors[id] != "undefined" ? (
                <div className="val-error">
                  {this.props.errors[id].map((err, key) =>
                    <p key={'err-' + key}>{err}</p>
                  )}
                </div>
              ) : ""}
            </div>
        </Fragment>
      
    );
  }
}


export default Text;
