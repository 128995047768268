import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

class RedirectWithFlashMessage extends Component {

  render() {

    let redirectData = {};

    if(this.props.message.length > 0){
        redirectData.message = this.props.message;
    }

    if(this.props.redirectState && this.props.redirectState.length > 0){

        redirectData.redirectState =  this.props.redirectState;//values: 'error' - show error box,'success' - show success box
    }

    return  (<Redirect to={{pathname:this.props.to,state:redirectData}}  />);

  }
}


export default RedirectWithFlashMessage;