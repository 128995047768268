import React, { Component, Fragment } from 'react';

import Text from '../common/fields/text.js';
import DateInput from '../common/fields/date-input.js';


import Telephone from '../common/fields/telephone.js';
import Email from '../common/fields/email.js';

import Select from '../common/fields/select.js';
import SubmitButton from '../common/fields/submit-button.js';

import Fetching from '../utilities/fetching';
import moment from 'moment';
import { withCookies } from 'react-cookie';


class LightBoxEditProject extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title:'',
            project_id:-1,
            start_date:'',
            end_date:'',
            status:'active',
            client_name:'',
            client_phone:'',
            client_email:'',
            errors:false,
            saving:'Create Project',
            doRedirect:false,
            foremanOptionValues:[],
            managerOptionValues:[],
            loading:true,
    
        }

        this.updateField = this.updateField.bind(this);
        this.createProject = this.createProject.bind(this);
        this.enableSubmitButton = this.enableSubmitButton.bind(this);
        this.loadProject = this.loadProject.bind(this);
    }

    componentDidMount(){

        this.loadUsers ( true);

        if(typeof this.props.project_id !== 'undefined' && this.props.project_id !== -1){
            this.setState({
                saving:'Update Project'
            })
            this.loadProject(this.props.project_id);
            
        }
    }

    updateField(fieldName, value) {
        this.setState({ [fieldName]: value },() => console.log(this.state));
        
    }

    enableSubmitButton(project_id){
        this.setState({
            isSubmitDisabled:false,
            project_id:project_id
        })
    }

    loadUsers ( displayLoader, usrAuth ) {

        // If it is this type of query display the loader 
        if (typeof displayLoader != 'undefined') {
            this.setState({ loading: true });
        }



        // Get the auth details of the user
        if ( typeof usrAuth == "undefined" ) {
            let { auth } = this.state;
            usrAuth = auth;
        }

        let auth = this.props.cookies.get('ldt-auth');

        // Get the users
        var thus = this;
        fetch(process.env.REACT_APP_API_BASE + '/users', {
            method: 'get',
            headers: { 
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                "apiToken": auth.apiToken,
                "apiKey": auth.apiKey
            },
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            let managerOptionValues = [];
            let foremanOptionValues = [];

            if(data.rslts.length > 0){

                for(let i =0 ; i < data.rslts.length;i++){

                    let user = data.rslts[i];

                    let userOption = {
                        label:user.first_name +' ' + user.last_name,
                        value:user.public_id
                    }

                    if(user.role === 'manager'){
                        managerOptionValues.push(userOption);
                    }

                    if(user.role === 'foreman' || user.role === 'manager'){
                        foremanOptionValues.push(userOption);
                    }
                }

            }


            thus.setState({ managerOptionValues,foremanOptionValues,loading:false,});
        })
        .catch(function (error) {
            console.log('Request failed', error);
        });
    }

    loadProject(project_id){
        let auth = this.props.cookies.get('ldt-auth');

         var thus = this;
         fetch(process.env.REACT_APP_API_BASE + '/projects/'+project_id, {
             method: 'get',
             headers: {
                 "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                 "apiToken":auth.apiToken,
                 "apiKey": auth.apiKey
             },
         })
             .then(Fetching.statusCheck)
             .then(Fetching.jsonExtract)
             .then(function (data) {
                 let newState= {
                    title:data.title,
                    project_id:data.id,
                    client_name:data.contact.name,
                    client_phone:data.contact.phone,
                    client_email:data.contact.email,
                    status:data.status,
                    errors:false,
                    foreman_id: (data.foreman_user === null) ? "" : data.foreman_user.public_id,
                    manager_id: (data.manager_user === null) ? "" : data.manager_user.public_id
                 }
                 
                 if(data.start_date === null){
                    newState.start_date = '';               
                 } else {
                    newState.start_date = data.start_date
                 }

                 if(data.end_date === null){
                    newState.end_date = ''          
                 } else {
                    newState.end_date = data.end_date
                 }

                 thus.setState(newState);
             })
             .catch(function (error) {
                 console.log('Request failed', error);
             });
    }

    createProject(e){

  
        e.preventDefault();

        let savingMessage = "Creating Project";

        if(this.props.isEdit){
            savingMessage = "Updating Project";
        } 

        this.setState({ saving: savingMessage });

        let auth = this.props.cookies.get('ldt-auth');

        // Set up the save data 
        let {client_name,title,client_email,client_phone,project_id,foreman_id,manager_id,start_date,end_date,status } = this.state ;

        let bodyString = 'status='+status+'&title='+title+'&name='+client_name+'&email='+client_email+'&phone='+client_phone

        let endpoint = '/projects';
        if(this.props.isEdit){
            endpoint += '/' + project_id
        }

        if(start_date.length > 0){
            start_date = moment(start_date).format('YYYY-MM-DD');
            bodyString += '&start_date='+start_date;
        }

        if(end_date.length > 0){
            end_date = moment(end_date).format('YYYY-MM-DD');
            bodyString += '&end_date='+end_date;
        }

        if(typeof manager_id !== 'undefined'){
            bodyString += '&manager_public_id='+manager_id;
        }

        if(typeof foreman_id !== 'undefined'){
            bodyString += '&foreman_public_id='+foreman_id ;
        }


        var thus = this;
        let method = 'post';

        if(thus.props.isEdit){
            method = 'put';
        }
        fetch(process.env.REACT_APP_API_BASE +  endpoint, {
            method: method,
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                "apiToken": auth.apiToken,
                "apiKey": auth.apiKey
            },
            body: bodyString
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (rslt) {

            console.log(rslt);
            if(rslt.rslt === 'success' ){
                let savingMessage = "Create Project";
                let contentMessage = "The Project has been successfully created.";

                if(thus.props.isEdit){
                    savingMessage = "Update Project";
                    contentMessage = "The Project has been successfully updated.";
                } 
                thus.setState({saving:  savingMessage} );

                thus.props.loadProjects();
                thus.props.triggerMsg({ content: contentMessage, className: "message success mbtm" });
                thus.props.toggleEditProjectLightBoxOpen(e);

            } else {
                for(var i in rslt){
                    let key = i.replace('-','_');

                    switch(key){
                        case 'email':
                            key = 'client_email';
                            break;

                        case 'name':
                            key = 'client_name';
                            break;

                        case 'phone':
                            key = 'client_phone';
                            break;
                        case 'manager_public_id':
                            key = 'manager_id';
                            break;
                        case 'foreman_public_id':
                            key = 'foreman_id';
                            break;
                        default:
                            break;
                    }
                    rslt[key] = rslt[i];
                    if (key !== i) {
                        delete rslt[i];
                    }
                }
                let savingMessage = "Create Project";

                if(thus.props.isEdit){
                    savingMessage = "Update Project";
                } 
                thus.setState({ saving: savingMessage, errors: rslt, msg: { content: "Sorry but there was an issue updating this Project. Please check the errors below and try again.", className: "message" } });
            }
        })
        .catch(function (error) {

            let savingMessage = "Create Project";

            if(thus.props.isEdit){
                savingMessage = "Update Project";
            } 
            thus.setState({ saving: savingMessage,  msg: { content: "Sorry, but there was an error on the server.", className: "message" } });
            console.log('Request failed', error);
        });
    }

    

  render() {

    let {msg,errors,start_date,end_date,client_name,client_phone, title,client_email,saving, status,foreman_id,manager_id,foremanOptionValues,managerOptionValues } = this.state ;

    console.log(errors);
    
    return (

        
        <Fragment>
            <div className='lightbox lightbox-create-booking'>
                <div className='lightbox-content'>
                <div className='close' onClick={(e) => this.props.toggleEditProjectLightBoxOpen(e) }>&times;</div>
                    <div className='lightbox-header'>
                    
                        <h4>New Project</h4>
                        {msg ? (
                            <div className={msg.className}>
                                {msg.content}
                            </div>
                        ) : ""}
                        
                    </div>

                    <div className='lightbox-section lightbox-section-half'>
                        <Text label='Project Name' id='title' value={ title } onChange={this.updateField} errors={errors}/>
                    </div>

                    <div className='lightbox-section lightbox-section-half'>
                        <Text label='Client Name' id='client_name' value={ client_name } onChange={this.updateField} errors={errors}/>
                    </div>

                    <div className='lightbox-section lightbox-section-half' style={{clear:'both'}}>
                        <Email label='Email' id='client_email' value={ client_email} onChange={this.updateField} errors={errors}/>
                    </div>


                    <div className='lightbox-section lightbox-section-half'>
                        <Telephone label='Telephone' id='client_phone' value={ client_phone } onChange={this.updateField} errors={errors}/>
                    </div>

                    <div className='lightbox-section lightbox-section-half' style={{clear:'both'}}>
                        <Select options={this.props.statusMap} label='Status' id='status' value={ status } handleSelect={this.updateField} errors={errors}/>
                    </div>
                    
                    <h4 className='subtitle'>Dates</h4>
                    <div className='lightbox-section lightbox-section-half'>
                        { start_date === "" ? (
                            <DateInput label='Start Date' id='start_date' value={start_date} onChange={this.updateField} format={"YYYY/MM/DD HH:mm"} errors={errors} />
                        ) : (
                            <DateInput label='Start Date' id='start_date' value={new Date(start_date)} onChange={this.updateField} format={"YYYY/MM/DD HH:mm"} errors={errors} />
                        )}
                    </div>
 
 
                    <div className='lightbox-section lightbox-section-half'>
                        { end_date === "" ? (
                            <DateInput label='End Date' id='end_date' value={end_date} onChange={this.updateField} format={"YYYY/MM/DD HH:mm"} errors={errors} />
                        ) : (
                            <DateInput label='End Date' id='end_date' value={new Date(end_date)} onChange={this.updateField} format={"YYYY/MM/DD HH:mm"} errors={errors} />
                        )}
                    </div>
                    <h4 className='subtitle'>Responsibilities</h4>
                    <div className='lightbox-section lightbox-section-half'>
                        <Select emptyMsg="Select a foreman..." label='Foreman' id='foreman_id' options={foremanOptionValues} value={ foreman_id } handleSelect={this.updateField} errors={errors}/>
                    </div>
                    <div className='lightbox-section lightbox-section-half'>
                        <Select emptyMsg="Select a manager..." label='Manager' id='manager_id' value={ manager_id } options={managerOptionValues} handleSelect={this.updateField} errors={errors}/>
                    </div>

                    <div className='lightbox-submit'>
                        <SubmitButton handleSubmit={this.createProject} text={ saving }/>
                    </div>
 
                </div>
            </div>
        </Fragment>

      
    );
  }
}

export default withCookies(LightBoxEditProject);