import React, { Component, Fragment } from 'react';
import moment from 'moment';
class BasicFieldEntry extends Component {

	render() {
		let { label, outputLabel, pre, post, fieldEntries, fieldStyleClass, fieldType } = this.props;
		if(typeof outputLabel == "undefined") outputLabel = true;
		if(typeof pre == "undefined") pre = "";
		if(typeof post == "undefined") post = "";
		if(typeof fieldStyleClass == "undefined") fieldStyleClass = "";
		if(typeof fieldType == "undefined") fieldType = "";

		// Get the entry from the entries object / array - as we cannot rely on zero indexing
		var fieldEntry = false;
		for (var i in fieldEntries) {
			if (!fieldEntry) {
				fieldEntry = fieldEntries[i];
				break;
			}
		}

		// If it is a date input with future validation
		// Work out what's going on
		var dateError = "";
		if (fieldType === 'Date' && fieldEntry.field_value !== "" && fieldEntry.field_value !== null) {
			var entry = fieldEntry.field_value.split('/');
			entry = entry[2] + entry[1] + entry[0];
			var today = moment().format('YYYYMMDD');
			if (entry < today){
				dateError = <div className="date-issue">
					The date should be in the future
				</div>
			}
		}

		return (
			<div className={fieldStyleClass + " " + fieldType + " basic-field-entry-container"}>
				<div className="basic-field-entry-label">
					{ outputLabel ? label : "" }
				</div>
				<div className="basic-field-entry">
					{ typeof fieldEntry == 'object' && typeof fieldEntry.field_value != "undefined" ? (
						<Fragment key={i}>
							{ pre + (fieldEntry.field_value == null ? "\u00A0" : fieldEntry.field_value) + post }
						</Fragment>
					) : (
						<p>&nbsp;</p>
					)}
				</div>

				{ typeof fieldEntry == 'object' && typeof fieldEntry.comment != "undefined" && fieldEntry.comment !== null && fieldEntry.comment !== "" ? (
					<div className="basic-field-entry comment">
						{ fieldEntry.comment }
					</div>
				): ""}

				{ dateError }
			</div>
		)
	}

}

export default BasicFieldEntry;