import React, { Component, Fragment } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

class DateInput extends Component {

  constructor(props){
    super(props);
    this.handleChange= this.handleChange.bind(this);
  }

  handleChange = date => {
    if (typeof this.props.onChange != 'undefined') {
      let value = date;
      if (typeof this.props.format != "undefined") {
        if (value === "" || value === null) {
          this.props.onChange(this.props.id, "", true);
        } else {
          let formattedValue = moment(value).format("YYYY/MM/DD");
          this.props.onChange(this.props.id, formattedValue, true);
        }
      } else {
        this.props.onChange(this.props.id, value);
      }
    }
  };

  render() {

    let {id,label} = this.props;

    var labelClass = "";
    if (typeof this.props.meetsDesires != "undefined" && this.props.meetsDesires === false) {
      labelClass = "highlight";
    }

    return (
        <Fragment>
            <div className="input">
                {typeof this.props.revert != "undefined" ? (
                  <button className="revert-btn" onClick={(e) => this.props.revert(e, id)}>Undo latest changes</button>
                ) : ""}
                <label htmlFor={id} className={ labelClass }>{label}</label>
                { typeof this.props.value != "undefined" && this.props.value !== "Invalid Date" ? (
                  <DatePicker
                    selected={this.props.value}
                    onChange={this.handleChange}
                    isClearable
                    dateFormat="d/M/yyyy"
                    timeIntervals={15}
                  />
                ) : (
                  <DatePicker
                    onChange={this.handleChange}
                    isClearable
                    dateFormat="d/M/yyyy"
                    timeIntervals={15}
                  />
                )}
                
            </div>

            {typeof this.props.errors != 'undefined' && typeof this.props.errors[id] != "undefined" ? (
						<div className="val-error">
							{this.props.errors[id].map((err, key) =>
								<p key={'err-' + key}>{err}</p>
							)}
						</div>
					) : ""}
                       
        </Fragment>
      
    );
  }
}


export default DateInput;
