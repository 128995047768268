import React, { Component,Fragment } from 'react';

class Select extends Component {

	handleChange (e, fieldName) {
		var selected = e.target.value;
		if (typeof this.props.directSubmit != "undefined") {
			this.props.handleSelect(fieldName, selected, true);
		} else {
			this.props.handleSelect(fieldName, selected);
		}
	}

  render() {

		let {id,label} = this.props;

		var labelClass = "";
		if (typeof this.props.meetsDesires != "undefined" && this.props.meetsDesires === false) {
			labelClass = "highlight";
		}

		var validValue = false;

		this.props.options.forEach(option => {
			if(option.value === this.props.value){
				validValue = true;
			}
		})

		return (
			<Fragment>
				<div className="input">
					{typeof this.props.revert != "undefined" ? (
						<button className="revert-btn" onClick={(e) => this.props.revert(e, id)}>Undo latest changes</button>
					) : ""}
					<label htmlFor={id} className={ labelClass }>{label}</label>
					{typeof this.props.revert != "undefined" ? (
						<select
							name={ this.props.name }
							value={ typeof this.props.value !== 'undefined' && validValue ? this.props.value : '' }
							onChange={  (e) => this.handleChange(e, id) }
							disabled={this.props.defaultDisabled  ? true : false}
						>
							{typeof this.props.emptyMsg != 'undefined' ? (
								<option value="" readOnly={true} disabled={true}>{this.props.emptyMsg}</option>
							) : ""}
							{this.props.options.map((option, i) =>
								<Fragment key={'opt-' + i}>
									{typeof option.value != 'undefined' ? (
										<option key={'opt-' + option.value} value={option.value}>{typeof option.name != 'undefined' ? option.name : option.label}</option>
									) : (
											<option key={'opt-' + option} value={option}>{option}</option>
										)}
								</Fragment>
							)}
						</select>
					) : (
						<select
							name={this.props.name}
							value={typeof this.props.value !== 'undefined' && validValue ? this.props.value : ''}
							onChange={(e) => this.handleChange(e, id)}
							disabled={this.props.defaultDisabled ? true : false}
						>
							{typeof this.props.emptyMsg != 'undefined' ? (
								<option value="" readOnly={true} disabled={true}>{this.props.emptyMsg}</option>
							) : ""}
							{this.props.options.map((option, i) =>
								<Fragment key={'opt-' + i}>
									{typeof option.value != 'undefined' ? (
										<option key={'opt-' + option.value} value={option.value}>{typeof option.name != 'undefined' ? option.name : option.label}</option>
									) : (
										<option key={'opt-' + option} value={option}>{option}</option>
										)}
								</Fragment>
							)}
						</select>
					)}
					{typeof this.props.errors != 'undefined' && typeof this.props.errors[id] != "undefined" ? (
						<div className="val-error">
							{this.props.errors[id].map((err, key) =>
								<p key={'err-' + key}>{err}</p>
							)}
						</div>
					) : ""}
				</div>
			</Fragment>

		);
  }
}

export default Select;
