import React, { Component, Fragment } from 'react';

import Header from '../core/header.js'
import Footer from '../core/footer.js'

import MainTitle from '../common/titles/main-title.js';

import DatasheetTable from './datasheet-table.js'


import LightBoxEditDatasheet from './lightbox-edit-datasheet.js'
import LightBoxCreateManufacturer from './lightbox-create-manufacturer.js';
import Fetching from '../utilities/fetching.js';

import { withCookies } from 'react-cookie';

class Datasheet extends Component {

  constructor(props) {
    super(props);

    this.state = {
        isCreateManufacturerLightBoxOpen:false,
        isEditProjectLightBoxOpen:false,
        msg:false,
        manufacturers:[],
        loading:true,
        auth:{},
        datasheets:[],
        total:-1,
        page:1,
        document_id:-1,
        isEdit:false
    }

    this.toggleCreateNewDatasheetLightBoxOpen = this.toggleCreateNewDatasheetLightBoxOpen.bind(this);
    this.toggleEditDatasheetLightBoxOpen = this.toggleEditDatasheetLightBoxOpen.bind(this);
    this.toggleCreateNewManufacturerLightBoxOpen = this.toggleCreateNewManufacturerLightBoxOpen.bind(this);
    this.updateMsg = this.updateMsg.bind(this);
    this.loadDatasheets = this.loadDatasheets.bind(this);
    this.togglePublishStatus = this.togglePublishStatus.bind(this);
    this.deleteDatasheet = this.deleteDatasheet.bind(this);
    this.loadManufacturers = this.loadManufacturers.bind(this);
  }

  componentDidMount(){
    this.loadManufacturers();
    this.loadDatasheets();
  }

  loadManufacturers(usrAuth){
      // If it is this type of query display the loader 
      if (typeof displayLoader != 'undefined') {
        this.setState({ loading: true });
    }

    if(typeof usrAuth === 'undefined'){
      usrAuth = this.props.cookies.get('ldt-auth');
    }
    

    // Get the users
    var thus = this;
    fetch(process.env.REACT_APP_API_BASE + '/manufacturers', {
        method: 'get',
        headers: { 
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
            "apiToken": usrAuth.apiToken,
            "apiKey": usrAuth.apiKey,
        },
    })
    .then(Fetching.statusCheck)
    .then(Fetching.jsonExtract)
    .then(function (data) {
      let manufacturers = data.map((manufacturer) => {
        return {
          label:manufacturer.name,
          value:manufacturer.id
        }
      });

        thus.setState({ manufacturers: manufacturers, loading: false });
    })
    .catch(function (error) {
        console.log('Request failed', error);
    });
  }

  loadDatasheets(usrAuth){
    // If it is this type of query display the loader 
    if (typeof displayLoader != 'undefined') {
      this.setState({ loading: true });
  }

  // Work out whether a page is mentioned 
  var qsParams = this.props.location.search.replace("?", "");
  qsParams = qsParams.split("&");
  var page = false;
  for (var j in qsParams) {
      if (qsParams[j].indexOf("page=") > -1) {
          page = qsParams[j].replace("page=", "");
      }
  }

  if ( !page ) page = 1;
  page = Number(page);


  usrAuth = this.props.cookies.get('ldt-auth');

  // Get the users
  var thus = this;
  fetch(process.env.REACT_APP_API_BASE + '/documents?file_type=datasheet&page=' + page, {
      method: 'get',
      headers: { 
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          "apiToken": usrAuth.apiToken,
          "apiKey": usrAuth.apiKey,
      },
  })
  .then(Fetching.statusCheck)
  .then(Fetching.jsonExtract)
  .then(function (data) {
        thus.setState({ datasheets: data.rslts,pages: data.pages, total: data.total, current: data.current, loading: false });
  })
  .catch(function (error) {
    thus.setState({loading: false });
      console.log('Request failed', error);
  });
}

togglePublishStatus(e,document_id,published){

  e.preventDefault();
  // If it is this type of query display the loader 
  if (typeof displayLoader != 'undefined') {
    this.setState({ loading: true });
  }


  let usrAuth = this.props.cookies.get('ldt-auth');
  
  let new_published = (published === 1) ? 0 : 1 ;

  // Get the users
  var thus = this;
  fetch(process.env.REACT_APP_API_BASE + '/documents/toggle_published', {
      method: 'post',
      headers: { 
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          "apiToken": usrAuth.apiToken,
          "apiKey": usrAuth.apiKey,
      },
      body:'id='+document_id+'&published='+new_published
  })
  .then(Fetching.statusCheck)
  .then(Fetching.jsonExtract)
  .then(function (data) {
       thus.loadDatasheets();
       thus.setState({msg:false})
  })
  .catch(function (error) {
      console.log('Request failed', error);
  });
}

deleteDatasheet(e,document_id){

  var doDelete = window.confirm('This cannot be undone.  Do you wish to contiune?');

  if(!doDelete){
    return false;
  }

  e.preventDefault();
  // If it is this type of query display the loader 
  if (typeof displayLoader != 'undefined') {
    this.setState({ loading: true });
  }


  let usrAuth = this.props.cookies.get('ldt-auth');
 

  // Get the users
  var thus = this;
  fetch(process.env.REACT_APP_API_BASE + '/documents', {
      method: 'delete',
      headers: { 
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          "apiToken": usrAuth.apiToken,
          "apiKey": usrAuth.apiKey,
      },
      body:'id='+document_id
  })
  .then(Fetching.statusCheck)
  .then(Fetching.jsonExtract)
  .then(function (data) {
       thus.loadDatasheets();
       thus.setState({msg:false})
  })
  .catch(function (error) {
      console.log('Request failed', error);
  });
}

  updateMsg ( value ) {
    console.log(value);
    this.setState({ msg: value });
}


toggleEditDatasheetLightBoxOpen(e,document_id){

  e.preventDefault();

  let new_document_id = -1;

    if(typeof document_id !== 'undefined'){
      new_document_id = document_id
    }

    let msg= this.state.msg;

    if(!this.state.isEditProjectLightBoxOpen){
      msg = false
    }

  this.setState(prevState => ({
    isEditProjectLightBoxOpen: !prevState.isEditProjectLightBoxOpen,
      document_id:new_document_id,
      isEdit:true,
      msg:msg
  }));
}


toggleCreateNewDatasheetLightBoxOpen(e){

  e.preventDefault();


  this.setState(prevState => ({
    isEditProjectLightBoxOpen: !prevState.isEditProjectLightBoxOpen,
      msg: false,
      isEdit:false
  }));
}

toggleCreateNewManufacturerLightBoxOpen(e){
  if(typeof e !== 'undefined'){
    e.preventDefault();
  }
  
  this.setState(prevState => ({
    isCreateManufacturerLightBoxOpen: !prevState.isCreateManufacturerLightBoxOpen,
  }));
}

  render() {

    let {msg,manufacturers,datasheets,isEdit} = this.state;

    let lightBoxEditDatasheet = null;
    let lightBoxCreateManufacturer = null;

    let categories = [{name:'Product',value:'product'},{name:'Safety',value:'safety'}];


    if(this.state.isEditProjectLightBoxOpen){
        lightBoxEditDatasheet = <LightBoxEditDatasheet triggerMsg={this.updateMsg} isEdit={isEdit} loadDatasheets={this.loadDatasheets} manufacturers={manufacturers} categories={categories} document_id={this.state.document_id} toggleEditDatasheetLightBoxOpen={this.toggleEditDatasheetLightBoxOpen} closeFunc={this.toggleEditDatasheetLightBoxOpen} />
    }
  
    if(this.state.isCreateManufacturerLightBoxOpen){

      lightBoxCreateManufacturer = <LightBoxCreateManufacturer triggerMsg={ this.updateMsg } uid={this.state.lightbox_uid} toggleCreateNewManufacturerLightBoxOpen={this.toggleCreateNewManufacturerLightBoxOpen} closeFunc={this.toggleCreateNewManufacturerLightBoxOpen} loadManufacturers={this.loadManufacturers} />
    }

  
    return (

        <Fragment>
            {lightBoxEditDatasheet}
            {lightBoxCreateManufacturer}
            <Header/>
            <section className="content">
                <div className="width-9 centred">
                  
                  <button className="right"  onClick={(e) => this.toggleCreateNewManufacturerLightBoxOpen(e)}>New Manufacturer</button>
                  <button className="right" style={{marginRight:'3px'}} onClick={(e) => this.toggleCreateNewDatasheetLightBoxOpen(e)}>New Datasheet</button>
                     
                  < MainTitle text='Manage Datasheets'/>

                  {msg ? (
                        <div className={msg.className}>
                            {msg.content}
                        </div>
                    ) : ""}
                </div>

                <DatasheetTable deleteDatasheet={this.deleteDatasheet} loadDatasheets={this.loadDatasheets} togglePublishStatus={this.togglePublishStatus} manufacturers={manufacturers} datasheets={datasheets} toggleEditDatasheetLightBoxOpen={this.toggleEditDatasheetLightBoxOpen} {...this.state}/>
                
            </section>
            <Footer/>
        </Fragment>
    );
  }
}

export default withCookies(Datasheet);